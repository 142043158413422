import { ServerError } from '@apollo/client';
import { useAuthContext } from 'components/auth-context';
import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router';
import { oktaAuth } from 'utils/okta';
import { UnauthenticatedLayout } from './layouts/unauthenticated-layout';
import { EmptyState } from './nuspire';
import { P } from './shared-components';

function isServerError(netError: any): netError is ServerError {
  return !!netError?.result;
}

export function LogoutPage() {
  const { error, logout } = useAuthContext();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isSubaruSupportErrorMessage = (errMessage?: string | null): boolean =>
    (!!errMessage && errMessage.includes('<NUSPIRE_SUPPORT_NUMBER>')) ?? false;

  useEffect(() => {
    if (isSubaruSupportErrorMessage((error as any)?.networkError?.result?.message)) {
      return;
    }

    logoutAsync().catch((err) => {
      console.error('Logout failed', err);
    });
  }, []);

  async function logoutAsync() {
    await oktaAuth.signOut();
    logout();
  }

  if (error && isServerError(error.networkError)) {
    const errorResult = error.networkError.result;
    const errMessage: string | undefined = (errorResult as any).message ?? errorResult;

    if (isSubaruSupportErrorMessage(errMessage)) {
      const errorMsg = errMessage!
        .replace('Context creation failed: ', '')
        .replace('<NUSPIRE_SUPPORT_NUMBER>', `<a href="tel:+18774351640">1-877-435-1640</a>`);

      return (
        <UnauthenticatedLayout>
          <EmptyState>
            <span dangerouslySetInnerHTML={{ __html: errorMsg }} />
          </EmptyState>
        </UnauthenticatedLayout>
      );
    }
  }

  const sessionExpired = params.get('sessionExpired') || false;
  if (sessionExpired) {
    return <Navigate to="/login?sessionExpired=true" replace />;
  }

  return (
    <UnauthenticatedLayout>
      <EmptyState>
        <P>Logging you out...</P>
      </EmptyState>
    </UnauthenticatedLayout>
  );
}
