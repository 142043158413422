import { gql, useQuery } from '@apollo/client';
import { Typography } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { useClientContext } from 'components/client-context-provider';
import { EmptyState, Link } from 'components/nuspire';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { Navigate } from 'react-router';
import { Content } from '../layouts/content';

const { Title } = Typography;

export const GET_MANAGED_CLIENT_SCORES = gql`
  query ManagedClientScores($clientId: String) {
    managedClientScores(clientId: $clientId) {
      rows
      parentOverallScore
      incompleteClients
      controlsCompletedPercentage
      averageDomainScores {
        id
        avgScore
      }
      averageControlScores {
        id
        avgScore
      }
    }
  }
`;

interface ClientScoreRow {
  name: string;
  score: number;
  averageOverallScore: string;
  id: string;
  children?: any[];
}

const columns: ColumnsType<ClientScoreRow> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (name: string, record) => <Link to={`/${record.id}/nsp/overview`}>{name}</Link>,
  },
  {
    title: 'Overall NSP Score',
    dataIndex: 'score',
    key: 'score',
  },
  {
    title: 'NSP Roll-Up Score',
    dataIndex: 'avgScore',
    key: 'avgScore',
    render: (avgScore) => (avgScore ? Math.round((avgScore + Number.EPSILON) * 100) / 100 : 0),
  },
];

export function NspManagedClientsPage() {
  const { clientId, client } = useClientContext();
  const { data, loading } = useQuery(GET_MANAGED_CLIENT_SCORES, { variables: { clientId } });

  if (!client?.isMultiTenancyEnabled) {
    return <Navigate to={`/${clientId}/nsp/overview`} replace />;
  }

  // TODO: get typing from backend
  const rows: ClientScoreRow[] = (data?.managedClientScores?.rows as any) ?? [];

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  if (!data) {
    return <EmptyState>Could not get client tree.</EmptyState>;
  }

  return (
    <Content>
      <Title level={1}>Managed Clients</Title>
      <Table
        dataSource={rows}
        columns={columns}
        rowKey="id"
        expandable={{
          defaultExpandAllRows: true,
        }}
        pagination={false}
      />
    </Content>
  );
}
