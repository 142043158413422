import { HolderOutlined } from '@ant-design/icons';
import { Button, List, ListProps } from 'antd';
import { closestCenter, DndContext, DragEndEvent, DragOverlay, DragStartEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { ComponentProps, CSSProperties, Key, Ref, useMemo, useState } from 'react';
import styled from 'styled-components';

type AntListItemProps = ComponentProps<typeof List.Item>;
type SortableArgs = ReturnType<typeof useSortable>;

export type RenderArgs = SortableArgs & {
  style: CSSProperties;
  isOverlay?: boolean;
};

export interface SortableListItemProps<T = any> extends Omit<AntListItemProps, 'id'> {
  render: (args: RenderArgs, ref: Ref<HTMLElement>) => JSX.Element;
  id: Key;
  data?: T;
}

export const DragHandle = styled(Button).attrs({
  icon: <HolderOutlined />,
  type: 'text',
  size: 'small',
  className: 'drag-handle',
})`
  color: ${(p) =>
    ['light', 'pdi-light'].includes(p.theme.mode) ? p.theme.color.slate : p.theme.token.colorTextSecondary};
  &:hover {
    background-color: ${(p) => p.theme.token.colorBgTextHover};
    cursor: grab;
  }
`;

const OverlayItemWrapper = styled.div`
  background-color: ${(p) => p.theme.token.colorPrimaryHover};
  & * {
    cursor: grabbing;
  }
  .drag-handle {
    background-color: ${(p) => p.theme.token.colorBgTextHover};
    color: ${(p) => p.theme.color.nuspireBlue};
  }
`;

function SortableItem(props: SortableListItemProps) {
  const { id, render } = props;
  const sortable = useSortable({ id: `${id}` });
  const { setNodeRef, transform, transition, isDragging } = sortable;

  const style = useMemo(() => {
    if (!transform) return {};
    const { x, y, scaleX, scaleY } = transform;

    return {
      transform: `translate3d(${x}px, ${y}px, 0px) scaleX(${scaleX}) scaleY(${scaleY})`,
      transition,
      opacity: isDragging ? 0.25 : 1.0,
    };
  }, [transform, transition]);

  return render(
    {
      ...sortable,
      style,
    },
    setNodeRef,
  );
}

interface SortableListProps<T = any> extends ListProps<SortableListItemProps> {
  items: SortableListItemProps<T>[];
  /**
   * toggles drag overlay. defaults to true.
   */
  overlay?: boolean;
  onReordered?: (items: SortableListItemProps<T>[]) => void;
}

export function SortableList<T = any>(props: SortableListProps<T>) {
  const { items, overlay = true, onReordered, ...listProps } = props;

  const [activeId, setActiveId] = useState<Key>();

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over) return;
    if (active.id !== over?.id) {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over!.id);

      onReordered?.(arrayMove(items, oldIndex, newIndex));
    }

    setActiveId(undefined);
  };

  if (items.length === 0) {
    return null;
  }

  return (
    <DndContext collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
      <SortableContext items={items.map((item) => `${item.id}`)} strategy={verticalListSortingStrategy}>
        <List dataSource={items} renderItem={(item) => <SortableItem {...item} key={item.id} />} {...listProps} />
      </SortableContext>
      {overlay && (
        <DragOverlay>
          {activeId ? (
            <List>
              <OverlayItemWrapper>
                {items.find((item) => item.id === activeId)!.render({ isOverlay: true } as RenderArgs, null)}
              </OverlayItemWrapper>
            </List>
          ) : null}
        </DragOverlay>
      )}
    </DndContext>
  );
}
