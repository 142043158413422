import { gql, useQuery } from '@apollo/client';
import { Space } from 'antd';
import { useClientContext } from 'components/client-context-provider';
import { NuCard, NuCardContent, NuCardTitle } from 'components/nuspire';
import Breadcrumb from 'components/nuspire/nu-breadcrumb';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { WidgetContents } from 'components/widgets/widget/widget';
import { WIDGET_COMPONENTS_MAP } from 'components/widgets/widget/widget-types';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router';
import styled from 'styled-components';
import { ClientTaskDetailsQuery, Widget } from 'types/graph-codegen/graph-types';
import { mixpanelTrack } from 'utils/mixpanel/mixpanel-track';
import { Content } from '../layouts/content';
import {
  ClientTaskActionsDetailItem,
  ClientTaskActivityDetailItem,
  ClientTaskInfo,
  ClientTaskSubDetailItems,
  ClientTaskSummary,
  ClientTaskTags,
  SnowCaseCollapse,
  SnowIncidentCollapseShow,
  TaskImpactAndRemediation,
  getShortIdLabel,
} from './client-task-modal';
import { ClientTaskPageHeader } from './client-task-page-header';
import { CLIENT_TASK_DETAIL_FIELDS } from './gql';
import { getCanCreateCase } from './utils';

export type ClientTask = NonNullable<ClientTaskDetailsQuery['clientTask']> & { widgets?: Widget[] };

const TaskDetailGrid = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 24px 432px;
  grid-template-areas: 'task-detail-body-col . task-detail-meta-col';
`;
const TaskDetailBodyCol = styled.div`
  grid-area: task-detail-body-col;
`;
const TaskDetailMetaCol = styled.div`
  grid-area: task-detail-meta-col;
`;

const ClientTaskWidgetRoot = styled.div`
  height: 400px;
  margin-bottom: 18px;
`;

function ClientTaskWidget(props: { widget: Widget }) {
  const { widget } = props;
  const { clientId } = useClientContext();

  const widgetType = widget?.widgetDefinition?.type ?? '';
  if (!widgetType) console.log(`Widget does not have a widget type set.`);

  const Component = WIDGET_COMPONENTS_MAP[widgetType];

  if (!clientId || !Component) return null;

  return (
    <ClientTaskWidgetRoot>
      <NuCard fullHeight>
        <NuCardTitle title={widget?.name} />

        <NuCardContent>
          <WidgetContents
            clientId={clientId}
            data={widget?.data}
            configuration={widget?.configuration ?? {}}
            component={Component}
            loading={false}
            title={widget?.name}
            dataTypeKey={widget?.configuration?.dataType}
          />
        </NuCardContent>
      </NuCard>
    </ClientTaskWidgetRoot>
  );
}

export function ClientTaskWidgets(props: { widgets?: Widget[] }) {
  const { widgets } = props;

  if (!widgets || widgets.length === 0) {
    return null;
  }

  return widgets.map((widget) => <ClientTaskWidget widget={widget} />);
}

export function ClientTaskDetailsView(props: { clientTask: ClientTask }) {
  const {
    clientTask,
    clientTask: { clientId, tags },
  } = props;

  const shortLabel = getShortIdLabel(clientTask);

  const [snowCaseExpanded, setSnowCaseExpanded] = useState<boolean>(false);
  const canCreateCase = getCanCreateCase(tags);

  return (
    <Content>
      <Breadcrumb
        items={[
          {
            key: `${clientId}-case-management`,
            title: <Link to={`/${clientId}/case-management`}>Case Management</Link>,
          },
          {
            key: `${clientId}-case-management-tasks`,
            title: <Link to={`/${clientId}/case-management/tasks`}>Tasks</Link>,
          },
          {
            key: `${shortLabel}`,
            title: shortLabel,
          },
        ]}
        target="case-management"
      />

      <ClientTaskPageHeader headerLevel={3} clientTask={clientTask} handleSnowCaseCollapse={setSnowCaseExpanded} />

      <TaskDetailGrid>
        <TaskDetailBodyCol>
          <ClientTaskSubDetailItems clientTask={clientTask} />

          <ClientTaskSummary clientTask={clientTask} />

          <ClientTaskInfo clientTask={clientTask} />

          <ClientTaskActionsDetailItem clientTask={clientTask} />

          <TaskImpactAndRemediation task={clientTask} />

          <ClientTaskWidgets widgets={clientTask?.widgets} />

          <ClientTaskTags task={clientTask} />

          <ClientTaskActivityDetailItem clientTask={clientTask} />
        </TaskDetailBodyCol>

        <TaskDetailMetaCol>
          <Space direction="vertical" style={{ display: 'flex' }}>
            <SnowIncidentCollapseShow snowIncidentNumber={clientTask.snowIncidentNumber} />
            {canCreateCase && (
              <SnowCaseCollapse task={clientTask} expanded={snowCaseExpanded} onCollapseChange={setSnowCaseExpanded} />
            )}
            {/* <SnowCaseCollapseContent task={clientTask} /> */}
          </Space>
        </TaskDetailMetaCol>
      </TaskDetailGrid>
    </Content>
  );
}

const CLIENT_TASK_DETAILS = gql`
  ${CLIENT_TASK_DETAIL_FIELDS}

  query ClientTaskDetails($id: String!, $clientId: String!) {
    clientTask(id: $id) {
      ...ClientTaskDetailFields
    }
  }
`;

export function ClientTaskDetails() {
  const { taskId } = useParams<{ taskId: string }>();
  const { clientId } = useClientContext();

  const { data, loading } = useQuery<ClientTaskDetailsQuery>(CLIENT_TASK_DETAILS, {
    variables: { id: taskId, clientId },
  });

  // Analytics
  useEffect(() => {
    mixpanelTrack('task-details-view');
  }, [taskId]);

  const clientTask = data?.clientTask as ClientTask;

  if (clientTask) {
    return <ClientTaskDetailsView clientTask={clientTask} />;
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  return <div>ClientTaskDetails</div>;
}
