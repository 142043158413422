import { gql, useQuery } from '@apollo/client';
import InfiniteTable from 'components/nuspire/infinite-table';
import { useEffect, useState } from 'react';
import { client } from 'utils/graphql';
import { renderDateTime } from './schedule-tasks';
import type { DataNode } from 'antd/es/tree';
import { Space, Tree } from 'antd';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import baseTheme from 'components/theme';
import { Link, NuButton } from 'components/nuspire';
import PageHeader from 'components/nuspire/nu-page-header';
import { logListPath } from 'components/admin/task-handler/task-handler';

const TASK_RESULT_TREE = gql`
  query TaskResultTree($taskId: String!) {
    taskResult(id: $taskId) {
      id
      taskResultTree
    }
  }
`;

const TASK_LOGS = gql`
  query TaskLogs($taskId: String!) {
    taskResult(id: $taskId) {
      id
      logs {
        next
        count
        items {
          id
          status
          summary
          startedAt
          completedAt
        }
      }
    }
  }
`;

interface TaskLog {
  id: string;
  status: string;
  summary?: string;
}

interface TaskResultTree {
  id: string;
  summary?: string;
  ok?: boolean;
  taskStatus?: string;
  childTaskResults?: TaskResultTree[];
}

function getTaskStatusIcon(taskStatus?: string) {
  if (taskStatus === 'running') {
    return <SyncOutlined style={{ color: baseTheme.color.nuspireBlue }} />;
  }

  if (taskStatus === 'success') {
    return <CheckCircleOutlined style={{ color: baseTheme.color.success }} />;
  }

  if (taskStatus === 'error') {
    return <ExclamationCircleOutlined style={{ color: baseTheme.color.error }} />;
  }

  return <QuestionCircleOutlined />;
}

function buildTaskResultTreeNode(taskResultTree: TaskResultTree): DataNode {
  const { id, taskStatus, summary, ok, childTaskResults } = taskResultTree;

  const children = childTaskResults?.map(buildTaskResultTreeNode);

  const node: DataNode = {
    key: id,
    title: summary ?? 'No Summary',
    children,
    icon: getTaskStatusIcon(taskStatus),
  };

  return node;
}

function buildTaskResultTreeData(taskResultTree: TaskResultTree): DataNode[] {
  const rootNode = buildTaskResultTreeNode(taskResultTree);

  return [rootNode];
}

function TaskResultTree(props: { taskId: string }) {
  const { taskId } = props;
  /**
   * Query Task Result Tree
   */

  const { data, loading } = useQuery(TASK_RESULT_TREE, { variables: { taskId }, pollInterval: 30 * 1000 });

  const taskResultTree = data?.taskResult?.taskResultTree;

  const treeData = taskResultTree ? buildTaskResultTreeData(taskResultTree) : null;

  if (treeData) {
    return (
      <>
        <PageHeader title="Threads" level={4} />
        <Tree treeData={treeData} defaultExpandAll showIcon style={{ marginBottom: '64px' }} />
      </>
    );
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin tip="Loading Task Steps">
          <div className="content" />
        </Spin>
      </SpinContainer>
    );
  }

  return null;
}

export function TaskLogs(props: { taskId: string }) {
  const { taskId } = props;

  /**
   * State
   */
  const [dataSource, setDataSource] = useState<TaskLog[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  // start key to load more items.
  const [startKey, setStartKey] = useState<{ [key: string]: string } | undefined>();

  /**
   * Fetch Data Source
   */
  const fetchData = async (args?: { newSearch?: boolean }) => {
    setLoading(true);

    const isNewSearch = args?.newSearch;

    const variables = {
      taskId,
      startKey: !isNewSearch ? startKey : undefined,
      pageSize: 100,
    };

    // fetch data
    const { data } = await client.query({
      query: TASK_LOGS,
      variables,
      fetchPolicy: 'network-only',
    });
    const items = data?.taskResult?.logs?.items;

    if (items) {
      const newItems = isNewSearch ? [...items] : [...dataSource, ...items];

      setDataSource(newItems);
    }

    const lastEvaluatedKey = data?.schedule?.scheduledTaskResults?.lastEvaluatedKey;
    setStartKey(lastEvaluatedKey);

    setLoading(false);
  };

  useEffect(() => {
    fetchData({ newSearch: true });
  }, [taskId]);

  const handleScroll = async () => {
    if (startKey) {
      await fetchData();
    }
  };

  const handleSync = () => {
    setStartKey(undefined);
    fetchData({ newSearch: true });
  };

  return (
    <>
      <TaskResultTree taskId={taskId} />
      <PageHeader
        level={4}
        title="Task Logs"
        actions={
          <Space>
            <Link to={`${logListPath()}?task_id=${taskId}`}>
              <NuButton type="link">View in Log List</NuButton>
            </Link>
            <NuButton onClick={handleSync}>
              <SyncOutlined />
            </NuButton>
          </Space>
        }
      />
      <InfiniteTable
        loading={loading}
        dataSource={dataSource}
        lastId={dataSource[dataSource.length - 1]?.id}
        debug
        columns={[
          {
            title: 'Started At',
            dataIndex: 'startedAt',
            render: renderDateTime,
          },
          {
            title: 'Time Completed',
            dataIndex: 'completedAt',
            render: renderDateTime,
          },
          {
            title: 'Status',
            dataIndex: 'status',
          },
          {
            title: 'Summary',
            dataIndex: 'summary',
          },
        ]}
        onFetch={handleScroll}
        scroll={{
          x: true as const,
          y: 750,
        }}
        rowKey="id"
      />
    </>
  );
}
