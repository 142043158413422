import { useMutation, useQuery } from '@apollo/client';
import { Layout, Menu, Modal, Typography, notification } from 'antd';
import { useAuthContext } from 'components/auth-context';
import { NuButton } from 'components/nuspire';
import gql from 'graphql-tag';
import { createContext, useContext, useEffect, useState } from 'react';
import { PublishedReleaseNotesQuery, ReleaseNoteDetailsQuery } from 'types/graph-codegen/graph-types';
import { Unpacked } from 'types';
import { useTheme } from 'styled-components';
import 'react-quill/dist/quill.snow.css';
import { Info } from 'components/nuspire/nu-icon';

const PUBLISHED_RELEASE_NOTES_QUERY = gql`
  query PublishedReleaseNotes {
    publishedReleaseNotes {
      items {
        id
        content
        date
      }
    }
  }
`;

const UPDATE_USER_LAST_VIEWED_RELEASE_NOTE = gql`
  mutation UpdateUserLastViewedReleaseNote($userId: String!, $lastViewedReleaseNoteDate: String) {
    updateUser(userId: $userId, lastViewedReleaseNoteDate: $lastViewedReleaseNoteDate) {
      id
      lastViewedReleaseNoteDate
    }
  }
`;

type ReleaseNote = Unpacked<NonNullable<PublishedReleaseNotesQuery['publishedReleaseNotes']['items']>>;

export function ReleaseNotesModal(props: { releaseNotes?: ReleaseNote[]; open: boolean; onClose: () => void }) {
  const { open, releaseNotes, onClose } = props;
  const [selectedReleaseNote, setSelectedReleaseNote] = useState<ReleaseNote | undefined>(releaseNotes?.[0]);

  const theme = useTheme();

  // Default to the latest release note being selected
  useEffect(() => {
    if (!releaseNotes?.length || selectedReleaseNote) return;

    setSelectedReleaseNote(releaseNotes[0]);
  }, [releaseNotes]);

  return (
    <Modal
      title="Release Notes"
      open={open}
      width={900}
      onClose={onClose}
      onCancel={onClose}
      okText="Done"
      onOk={() => onClose()}
      okButtonProps={{
        type: 'default',
      }}
      footer={(_, { OkBtn }) => {
        return (
          <>
            <OkBtn />
          </>
        );
      }}
    >
      <Layout style={{ minHeight: 'initial !important' }}>
        <Layout.Sider style={{ background: 'transparent', height: '50vh' }}>
          <Menu
            style={{ background: 'transparent ' }}
            defaultSelectedKeys={selectedReleaseNote ? [selectedReleaseNote.id] : undefined}
            items={releaseNotes?.map((e) => ({
              key: e.id,
              label: <Typography.Text strong>{formatDate(e.date as string)}</Typography.Text>,
              icon: <Info style={{ color: theme.color.primary }} />,
            }))}
            onSelect={(selected) => {
              const releaseNote = releaseNotes?.find((e) => e.id === selected.key);

              if (releaseNote) setSelectedReleaseNote(releaseNote);
            }}
          />
        </Layout.Sider>
        <Layout.Content style={{ padding: '0 24px' }}>
          {selectedReleaseNote && (
            <>
              <Typography.Title level={3}>{formatDate(selectedReleaseNote.date as string)}</Typography.Title>
              <div
                dangerouslySetInnerHTML={{ __html: selectedReleaseNote.content }}
                className="p-4 border rounded-lg bg-gray-100"
              />
            </>
          )}
        </Layout.Content>
      </Layout>
    </Modal>
  );
}

interface IRealeaseNotesContext {
  isModalOpen: boolean;
  userHasUnreadReleaseNote: boolean;
  onOpenModal: () => void;
  onCloseModal: () => void;
}

// Initiate Context
export const ReleaseNotesContext = createContext<IRealeaseNotesContext>({
  isModalOpen: false,
  userHasUnreadReleaseNote: false,
  onCloseModal: () => undefined,
  onOpenModal: () => undefined,
});

export const useReleaseNotesContext = () => useContext(ReleaseNotesContext);

function formatDate(date: string) {
  const d = new Date(date);

  const formattedDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(d);

  return formattedDate;
}

export function ReleaseNotesProvider(props: { children: any }) {
  const { children } = props;
  const { user } = useAuthContext();
  const { data, loading, error } = useQuery<PublishedReleaseNotesQuery>(PUBLISHED_RELEASE_NOTES_QUERY);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [userHasUnreadReleaseNote, setUserHasUnreadReleaseNote] = useState<boolean>(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setUserHasUnreadReleaseNote(false);
  };

  const handleCloseModal = () => setIsModalOpen(false);

  const [notificationApi, contextHolder] = notification.useNotification();

  const [updateUser] = useMutation(UPDATE_USER_LAST_VIEWED_RELEASE_NOTE);

  const updateUserLastViewedReleaseNote = async (date: string) => {
    if (!user) return; // this shouldn't happen.

    try {
      await updateUser({
        variables: {
          userId: user.id,
          lastViewedReleaseNoteDate: date,
        },
      });
    } catch (err) {
      console.error('Failed to update user', err);
    }
  };

  const openRecentReleaseNoteNotification = (args: { date: string }) => {
    const { date } = args;
    const formattedDate = formatDate(date);

    const notificationKey = `release-note-${date}`;

    const updateUser = () => updateUserLastViewedReleaseNote(date);

    notificationApi.info({
      key: notificationKey,
      message: `Recent Updates as of ${formattedDate}`,
      style: {
        width: '500px',
      },
      btn: (
        <div style={{ display: 'flex' }}>
          <NuButton
            type="primary"
            onClick={() => {
              handleOpenModal();

              updateUser();

              notificationApi.destroy(notificationKey);
            }}
          >
            View Updates
          </NuButton>
        </div>
      ),
      placement: 'bottom',
      duration: 0,
      onClose: () => updateUser(),
    });

    // mark this as the most recent release note that the user has viewed.
  };

  // Check to see if a more recent update is out than the users most recently viewed update.
  useEffect(() => {
    if (!data || !user) return;

    const latestReleaseNoteDate = data?.publishedReleaseNotes?.items?.[0]?.date as string | undefined;

    // compare dates of most recent release note user has viewed.
    const userMostRecentlyViewedDate: string | undefined = user?.lastViewedReleaseNoteDate;

    if (latestReleaseNoteDate) {
      // if user has not viewed any releases or there is a new release.
      if (!userMostRecentlyViewedDate || new Date(latestReleaseNoteDate) > new Date(userMostRecentlyViewedDate)) {
        openRecentReleaseNoteNotification({ date: latestReleaseNoteDate });
        setUserHasUnreadReleaseNote(true);
      }
    }
  }, [data, user]);

  const releaseNotes = data?.publishedReleaseNotes?.items;

  return (
    <ReleaseNotesContext.Provider
      value={{
        isModalOpen,
        onOpenModal: handleOpenModal,
        onCloseModal: handleCloseModal,
        userHasUnreadReleaseNote,
      }}
    >
      {contextHolder}
      {children}
      <ReleaseNotesModal open={isModalOpen} releaseNotes={releaseNotes} onClose={handleCloseModal} />
    </ReleaseNotesContext.Provider>
  );
}
