import {
  CloseOutlined,
  CloudServerOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import { App, Checkbox, Dropdown, Empty, MenuProps, message, Tooltip, Typography } from 'antd';
import { useAuthContext } from 'components/auth-context';
import { useClientContext } from 'components/client-context-provider';
import { useDashboardContext } from 'components/dashboard/dashboard-context';
import { Link, NuButton, NuCard, NuCardContent, NuCardTitle } from 'components/nuspire';
import * as NuIcons from 'components/nuspire/nu-icon';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { widgetDetailPath } from 'components/reporting-and-analysis/paths';
import get from 'lodash.get';
import { Dispatch, ReactNode, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Access } from 'types';
import { client } from 'utils/graphql';
import { WidgetComponent } from '.';
import { HIDE_HIDE_WIDGET_WARNING_KEY, HIDE_REMOVE_WIDGET_WARNING_KEY } from '../../../localstorage';
import { WidgetDataQuery, type WidgetDataQueryVariables } from '../../../types/graph-codegen/graph-types';
import { downloadBase64ContentAsFile } from '../../../utils/download-base64-content-as-file';
import { createDebugRequestId, useDebugRequests } from '../../debug';
import { WIDGET_COMPONENTS_MAP } from './widget-types';
import { ColumnConfig } from './widget-types/table';

export const CURRENT_DASHBOARD_ID_SP = 'd';

export interface IWidgetDefinition {
  id: string;
  slug: string;
  type: string;
  name: string;
  description?: string;
  configuration?: any;
  data?: any;
  dataType?: string;
  canDebug?: boolean;
}

const WIDGET_DATA = gql`
  query WidgetData(
    $clientId: String!
    $debugRequestId: String
    $from: Date
    $id: String!
    $to: Date
    $variables: JSONObject
    $viewingClientId: String!
  ) {
    dashboardWidget(id: $id, clientId: $clientId) {
      id
      isHidden
      widget {
        id
        data(
          debugRequestId: $debugRequestId
          from: $from
          to: $to
          variables: $variables
          viewingClientId: $viewingClientId
        )
        configuration
        description
        settings
        filters {
          key
          value
          operator
        }
      }
    }
  }
`;

export function WidgetContents(props: {
  clientId: string;
  dataTypeKey?: string;
  component: WidgetComponent<any, any>;
  data?: any;
  configuration?: any;
  settings?: any;
  loading: boolean;
  setSubAction?: Function;
  setFooterContent?: Dispatch<ReactNode>;
  onFetch?: (args: { variables?: any }) => Promise<any>;
  title?: string;
  size?: number;
  isReportWidget?: boolean;
}) {
  const {
    clientId,
    dataTypeKey,
    component: Component,
    data,
    configuration,
    loading,
    setSubAction,
    setFooterContent,
    title,
    size,
    isReportWidget,
    settings,
  } = props;

  if (data) {
    return (
      <Component
        clientId={clientId}
        dataTypeKey={dataTypeKey}
        data={data}
        configuration={configuration}
        setSubAction={setSubAction}
        setFooterContent={setFooterContent}
        onFetch={props.onFetch}
        title={title}
        size={size}
        isReportWidget={isReportWidget}
        settings={settings}
      />
    );
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" />;
}

const REMOVE_WIDGET = gql`
  mutation RemoveWidgetFromDashboard($dashboardWidgetId: String!) {
    removeWidgetFromDashboard(dashboardWidgetId: $dashboardWidgetId) {
      ok
    }
  }
`;

const HIDE_WIDGET = gql`
  mutation AddHiddenWidgetToSettings($clientId: String!, $dashboardId: String!, $dashboardWidgetId: String!) {
    addHiddenWidgetToSettings(dashboardWidgetId: $dashboardWidgetId, clientId: $clientId, dashboardId: $dashboardId)
  }
`;

const UNHIDE_WIDGET = gql`
  mutation RemoveHiddenWidgetFromSettings($clientId: String!, $dashboardId: String!, $dashboardWidgetId: String!) {
    removeHiddenWidgetFromSettings(
      dashboardWidgetId: $dashboardWidgetId
      clientId: $clientId
      dashboardId: $dashboardId
    )
  }
`;

export function createCSVBase64(args: { rows: any[]; columns?: ColumnConfig[] }) {
  const { rows } = args;
  let { columns = [] } = args;

  // Some tables have nested child columns to enhance the UI
  // for example, tables that have a quarter row
  if (columns?.find((c) => c.children)) {
    const flatColumns: ColumnConfig[] = [];

    for (const column of columns) {
      if (column.children) {
        for (const childColumn of column.children) {
          flatColumns.push(childColumn);
        }
      } else {
        flatColumns.push(column);
      }
    }

    columns = flatColumns;
  }

  const titles = columns?.map((c) => c.title);

  const escapeCSVField = (value?: string | null) => {
    // return empty field instead of "undefined"
    if (!value || !value?.toString().trim()) return '';
    /**
     * escape double quotes and commas
     *
     * Example: foo, "bar, baz" -> "foo, ""bar, baz"""
     */
    return `"${value.toString().replace(/"/g, '""')}"`;
  };

  const headerRow = titles?.map(escapeCSVField).join(',');
  const dataRows = rows
    .map((item) => {
      // using get here because some dataIndex values reference nested object values
      // ex: snow assets model.display_value

      const values = columns.map((c) => {
        const dataIndex = c?.csvDataIndex ?? c?.dataIndex ?? '';
        const getIndex = Array.isArray(dataIndex) ? dataIndex.join('.') : dataIndex;

        return escapeCSVField(get(item, getIndex));
      });

      return values.join(',');
    })
    .join('\n');

  let csvString = `${dataRows}\n`;

  if (headerRow) {
    csvString = `${headerRow}\n${csvString}`;
  }

  return window.btoa(csvString);
}

/**
 * Widget Menu
 * Should only appear on dashboards.
 */
export function WidgetMenu(props: {
  authorized?: boolean; // whether or not user has the right to edit widget or remove from dashboard.
  canDebug: boolean;
  configuration?: any;
  dashboardWidgetId?: string;
  data?: any;
  debugRequestId?: string;
  editable?: boolean;
  exportable?: boolean;
  isHidden?: boolean;
  widgetId?: string;
  widgetName: string;
}) {
  const { clientId, client, authorize } = useClientContext();
  const { dashboardWidgetId, data, configuration, editable, exportable, widgetId, widgetName, authorized, isHidden } =
    props;

  const { dashboardId } = useParams<{ dashboardId: string }>();
  const debugCtx = useDebugRequests();

  const [removeWidget] = useMutation(REMOVE_WIDGET, {
    refetchQueries: ['DashboardDetails'],
  });
  const [hideWidget] = useMutation(HIDE_WIDGET, {
    refetchQueries: ['DashboardDetails'],
  });
  const [unhideWidget] = useMutation(UNHIDE_WIDGET, {
    refetchQueries: ['DashboardDetails'],
  });

  const canEditClientContext = authorize({
    requiredPermissions: {
      dashboards: Access.write,
    },
  });
  const { modal } = App.useApp();

  const handleRemoveWidget = async () => {
    try {
      const result = await removeWidget({
        variables: {
          dashboardWidgetId,
        },
      });

      const ok = result?.data?.removeWidgetFromDashboard?.ok;

      if (!ok) {
        throw new Error('Failed to remove Widget');
      }

      message.success('Widget has been removed from Dashboard');
    } catch (err: any) {
      console.error('Failed to Remove Widget', err);
      message.error('Failed to remove widget from dashboard.');
    }
  };

  const handleRemove = async () => {
    const hideWarning: boolean = window.localStorage.getItem(HIDE_REMOVE_WIDGET_WARNING_KEY) === 'true';

    if (!hideWarning) {
      let checkboxValue: string;

      modal.confirm({
        title: `Remove Widget From Dashboard`,
        content: (
          <div>
            <Typography.Text>
              Warning: Removing this widget will remove it from all dashboards, inlcuding all shared dashboards.
            </Typography.Text>
            <div style={{ marginTop: '1rem' }}>
              <Checkbox
                onChange={(e) => {
                  checkboxValue = JSON.stringify(e.target.checked);
                }}
              >
                Don't show again
              </Checkbox>
            </div>
          </div>
        ),

        okText: `Remove Widget`,
        onOk: async () => {
          window.localStorage.setItem(HIDE_REMOVE_WIDGET_WARNING_KEY, checkboxValue);
          await handleRemoveWidget();
        },
      });
    } else {
      await handleRemoveWidget();
    }
  };

  const handleHideWidget = async () => {
    const result = await hideWidget({
      variables: {
        dashboardWidgetId,
        clientId,
        dashboardId,
      },
    });

    const errors = result?.errors;

    if (errors?.length) {
      console.error('Failed to Hide Widget', errors);
      message.error('Failed to hide widget from dashboard.');
    }

    message.success('Widget has been hidden from Dashboard');
  };

  const handleHide = async () => {
    const hideWarning: boolean = window.localStorage.getItem(HIDE_HIDE_WIDGET_WARNING_KEY) === 'true';

    if (!hideWarning) {
      let checkboxValue: string;

      modal.confirm({
        title: `Hide Widget From ${client ? client.name : 'this clients'} Dashboard`,
        content: (
          <div>
            <Typography.Text>
              This will hide this widget from only {client ? client.name : 'this clients'} dashboard
            </Typography.Text>
            <div style={{ marginTop: '1rem' }}>
              <Checkbox
                onChange={(e) => {
                  checkboxValue = JSON.stringify(e.target.checked);
                }}
              >
                Don't show again
              </Checkbox>
            </div>
          </div>
        ),

        okText: `Hide Widget`,
        onOk: async () => {
          window.localStorage.setItem(HIDE_HIDE_WIDGET_WARNING_KEY, checkboxValue);
          await handleHideWidget();
        },
      });
    } else {
      await handleHideWidget();
    }
  };

  const handleExport = () => {
    const csvContent = createCSVBase64({ rows: data.tableData, columns: configuration?.columns });

    downloadBase64ContentAsFile({
      content: csvContent,
      contentType: 'text/plain',
      filename: `${widgetName}-${new Date().toISOString()}.csv`,
    });
  };

  if (!canEditClientContext && !authorized && !exportable) {
    // no menu items.

    return null;
  }

  const menuItems: MenuProps['items'] = [];
  if (exportable) {
    menuItems.push({
      key: 'export',
      icon: <DownloadOutlined />,
      onClick: handleExport,
      label: 'Export',
    });
  }

  if (authorized && widgetId && editable) {
    menuItems.push({
      icon: <EditOutlined />,
      key: 'edit',
      label: (
        <Link to={widgetDetailPath({ clientId: clientId ?? '', id: widgetId, dashboardId })} mode="plain">
          Edit
        </Link>
      ),
    });
  }

  if ((authorized || canEditClientContext) && dashboardWidgetId) {
    if (isHidden) {
      menuItems.push({
        icon: <EyeOutlined />,
        key: 'unhide',
        onClick: () => unhideWidget({ variables: { dashboardWidgetId, clientId, dashboardId } }),
        label: 'Unhide',
      });
    } else {
      menuItems.push({
        icon: <EyeInvisibleOutlined />,
        key: 'hide',
        onClick: handleHide,
        label: 'Hide',
      });
    }
  }

  if (authorized && dashboardWidgetId) {
    menuItems.push({
      icon: <DeleteOutlined />,
      key: 'remove',
      onClick: handleRemove,
      label: 'Remove',
    });
  }

  if (props.canDebug && props.debugRequestId) {
    const isDebugging: boolean = debugCtx.hasRequestId(props.debugRequestId);
    const onDebugClick = (): void => {
      if (isDebugging) {
        debugCtx.removeRequestId(props.debugRequestId);
        return;
      }

      debugCtx.addRequestId(props.debugRequestId);
    };

    menuItems.push({
      icon: isDebugging ? <CloseOutlined /> : <CloudServerOutlined />,
      key: 'debug-requests',
      label: isDebugging ? 'Remove debug' : 'Debug widget',
      onClick: onDebugClick,
    });
  }

  return (
    <Dropdown trigger={['click']} placement="bottomRight" menu={{ items: menuItems }}>
      <NuButton shape="circle" icon={<NuIcons.Ellipsis style={{ fontSize: '24px' }} />} type="text" />
    </Dropdown>
  );
}

export interface WidgetProps {
  widgetDefinition: IWidgetDefinition;
  // pass if widget has been saved and is being rendered on a dashboard.
  // if !widgetDef.data, we can call widget.data({ variables }) to get widget data.
  dashboardWidgetId?: string;
  name?: string;
  onRemove?: () => void;
  canEdit?: boolean;
  authorized?: boolean;
  viewingClientId?: string; // clientId
  handleFetch?: (args: { variables?: any }) => Promise<any>;
  size?: number;
}

function Widget(props: WidgetProps) {
  const [subAction, setSubAction] = useState(null);
  const [footerContent, setFooterContent] = useState<ReactNode>(null);
  // data will vary depending on the client viewing said widget.
  const { clientId } = useClientContext();
  const { user } = useAuthContext();
  const debugCtx = useDebugRequests();

  const {
    widgetDefinition,
    widgetDefinition: { type, dataType },
    dashboardWidgetId,
    name,
    canEdit,
    viewingClientId,
    authorized,
    size,
  } = props;

  // Get date range from dashboard context.
  const { fromIso, toIso } = useDashboardContext();

  const debugRequestId = useMemo(() => {
    if (!debugCtx.canDebugRequests) {
      return undefined;
    }

    return createDebugRequestId({ suffix: `dashboard:widget:${widgetDefinition.slug}`, user });
  }, [debugCtx.canDebugRequests, widgetDefinition.slug, user]);

  // Query backend for widget data and configuration
  // Widget makes initial data request
  const { data: dashboardWidgetData, loading } = useQuery<WidgetDataQuery, WidgetDataQueryVariables>(WIDGET_DATA, {
    variables: {
      clientId: clientId ?? '',
      debugRequestId: debugCtx.hasRequestId(debugRequestId) ? debugRequestId : undefined,
      from: fromIso,
      id: dashboardWidgetId!,
      to: toIso,
      viewingClientId: viewingClientId ?? clientId ?? '',
    },
    skip: !dashboardWidgetId,
  });

  const data = dashboardWidgetData?.dashboardWidget?.widget?.data ?? widgetDefinition.data;
  const settings = dashboardWidgetData?.dashboardWidget?.widget?.settings ?? {};
  const canExport = type === 'table' || type === 'technology-source-device-health'; // allow any table-based widget to be exported, or the device list

  // Some widgets such as tables and lists will need to fetch more data as user scrolls.
  const handleFetch = async (args: { variables?: any }) => {
    // widget preview fetches data slightly differently.
    if (props.handleFetch) {
      return props.handleFetch(args);
    }

    const fetchResults = await client.query<WidgetDataQuery, WidgetDataQueryVariables>({
      query: WIDGET_DATA,
      variables: {
        clientId: clientId ?? '',
        debugRequestId: debugCtx.hasRequestId(debugRequestId) ? debugRequestId : undefined,
        id: dashboardWidgetId!,
        variables: args.variables,
        viewingClientId: viewingClientId ?? clientId ?? '',
      },
      fetchPolicy: 'network-only',
    });

    const data = fetchResults?.data?.dashboardWidget?.widget?.data;

    return data;
  };

  const configuration = dashboardWidgetData?.dashboardWidget?.widget?.configuration ?? widgetDefinition.configuration;
  const widgetId = dashboardWidgetData?.dashboardWidget?.widget?.id;
  const widgetName = name ?? widgetDefinition.name;
  const title = data?.isFromDateTooLarge ? (
    <span>
      <Tooltip title={`Data for this widget capped at ${data?.maxFromDays} days`}>
        <WarningOutlined style={{ color: 'red', marginRight: '.5rem' }} />
      </Tooltip>
      {widgetName}
    </span>
  ) : (
    widgetName
  );

  // widgets are meant to use generic widget types (ie: table, pie chart, etc...);
  const Component = WIDGET_COMPONENTS_MAP[type];
  if (!Component) {
    console.log(`Could not find matching widget component for type: ${type}`);
    return null;
  }

  if (!clientId) {
    return null;
  }

  return (
    <NuCard fullHeight dataIntercomTarget={`widget-${name}`}>
      <NuCardTitle
        title={title}
        actions={
          <>
            {subAction && subAction}
            <WidgetMenu
              authorized={authorized}
              canDebug={widgetDefinition?.canDebug ?? false}
              configuration={configuration}
              dashboardWidgetId={dashboardWidgetId}
              data={data}
              debugRequestId={debugRequestId}
              editable={canEdit}
              exportable={canExport}
              isHidden={dashboardWidgetData?.dashboardWidget?.isHidden}
              widgetId={widgetId}
              widgetName={widgetName}
            />
          </>
        }
      />

      <NuCardContent>
        <WidgetContents
          dataTypeKey={dataType}
          clientId={clientId}
          component={Component}
          data={data}
          configuration={configuration}
          loading={loading}
          setSubAction={setSubAction}
          setFooterContent={setFooterContent}
          onFetch={handleFetch}
          title={widgetName}
          size={size}
          settings={settings}
        />
      </NuCardContent>
      {footerContent && footerContent}
    </NuCard>
  );
}

export default Widget;
