/* eslint-disable camelcase */
import { InboxOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Form, Input, message, Select, Typography, Upload } from 'antd';
import { useClientContext } from 'components/client-context-provider';
import InfiniteSelect, { NO_MORE_DATA, OnFetch } from 'components/nuspire/infinite-select/infinite-select';
import Breadcrumb from 'components/nuspire/nu-breadcrumb';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router';
import styled from 'styled-components';
import { IAccountContact } from 'types';
import {
  AccountAssetsQuery,
  AccountContactsQuery,
  Client,
  CreateCaseMutation,
  CreateCaseMutationVariables,
  OpenCaseCategoriesQuery,
  OpenCaseClientTreeQuery,
  SubcategoriesByCategoryQuery,
} from 'types/graph-codegen/graph-types';
import { Access } from 'types/index';
import { mixpanelTrack } from 'utils/mixpanel/mixpanel-track';
import { useClientIdentifier } from 'utils/react-hooks/use-client-identifier';
import { useAuthContext } from '../auth-context';
import { IncidentValues } from '../cyberx/event/case-management/open-case-modal';
import { Content } from '../layouts/content';
import { H1, H3 } from '../shared-components';
import { priorities } from './case-management-table';
import PriorityDetailsModal from './priority-details-modal';
import WatchListSelect from './watch-list-select';

const { Option } = Select;
const { Dragger } = Upload;

const CLIENT_TREE_QUERY = gql`
  query OpenCaseClientTree($searchQuery: String, $next: String) {
    currentUser {
      allRelatedClients(searchQuery: $searchQuery, next: $next) {
        clientTree
        next
        total
      }
    }
  }
`;

const ACCOUNT_ASSETS_QUERY = gql`
  query AccountAssets($clientId: String) {
    accountAssets(clientId: $clientId) {
      assets {
        display_name
        sys_id
      }
    }
  }
`;

export const ACCOUNT_CONTACTS_QUERY = gql`
  query AccountContacts($clientId: String) {
    accountContacts(clientId: $clientId) {
      id
      email
      name
    }
  }
`;

const OPEN_CASE_CATEGORIES_QUERY = gql`
  query OpenCaseCategories {
    openCaseCategories {
      id
      name
    }
  }
`;

export const SUBCATEGORIES_BY_CATEGORY_QUERY = gql`
  query SubcategoriesByCategory($categoryValue: String!) {
    subcategoriesByCategory(categoryValue: $categoryValue) {
      id
      name
    }
  }
`;

const CREATE_CASE = gql`
  mutation CreateCase($input: CreateCaseInput!) {
    createCase(input: $input) {
      number
      sys_id
    }
  }
`;

const Header = styled.div`
  margin-bottom: 2rem;
`;

const SubmitButton = styled(Button)`
  margin-top: 2rem;
`;

const PriorityInfoBtn = styled(InfoCircleOutlined)`
  margin-left: 5px;

  &:hover {
    cursor: pointer;
  }
`;

export interface IDropdownValue {
  id: string;
  name: string;
}

const defaultPriority = '4 - Low';

const formLayout = {
  labelCol: {
    span: 2,
  },
  wrapperCol: {
    span: 22,
  },
};

export function OpenCase(props: {
  layout: object;
  title?: string;
  subtitle?: string;
  defaults?: IncidentValues;
  incident?: string;
  account?: string;
  isModal?: boolean;
  /**
   * if clientId is undefined and client is multi-tenant then clientId selector will be shown
   */
  clientId: string | undefined;
  onSubmit?: (success: boolean, newCase?: { number: string }) => void;
  eventId?: string;
  refetch?: () => void;
  disabledFields?: { [key in string]: boolean };
}) {
  const {
    layout,
    title,
    subtitle,
    defaults,
    incident,
    account,
    isModal = false,
    onSubmit,
    eventId,
    refetch,
    disabledFields,
  } = props;
  const [isPriorityDetailModalOpen, setIsPriorityDetailModalOpen] = useState<boolean>(false);

  const { authorize, client, clientId: userClientId } = useClientContext();
  const serviceNowAccountId = useClientIdentifier({
    clientIdentifiers: client?.clientIdentifiers,
    type: 'serviceNowAccountId',
  })?.value;

  const { user } = useAuthContext();
  const [form] = Form.useForm();
  const { authorized: canWriteCaseManagement } = authorize({ requiredPermissions: { caseManagement: Access.write } });
  const canOpenCase: boolean = !!user?.serviceNowUserId && !!serviceNowAccountId && canWriteCaseManagement;

  const multiClient = user?.client?.isMultiTenancyEnabled || (user?.managedClients ?? []).length > 0;
  const showClientSelector = props.clientId === undefined && multiClient;
  const [clientId, setClientId] = useState(props.clientId ?? userClientId!);

  const [nextClientsToken, setNextClientsToken] = useState<string>();
  const [totalClients, setTotalClients] = useState(0);
  const [queryClientTree, { error: clientTreeError }] = useLazyQuery<OpenCaseClientTreeQuery>(CLIENT_TREE_QUERY);

  const [contacts, setContacts] = useState<IAccountContact[]>([]);
  const [queryContacts, { data: contactsData, loading: contactsLoading, error: contactsError }] =
    useLazyQuery<AccountContactsQuery>(ACCOUNT_CONTACTS_QUERY);
  const accountContacts = contactsData?.accountContacts;

  const [assets, setAssets] = useState<IDropdownValue[]>([]);
  const [queryAssets, { data: assetsData, loading: assetsLoading, error: assetsError }] =
    useLazyQuery<AccountAssetsQuery>(ACCOUNT_ASSETS_QUERY);
  const accountAssets = assetsData?.accountAssets?.assets;

  const [categories, setCategories] = useState<IDropdownValue[]>([]);
  const {
    data: categoriesData,
    loading: categoriesLoading,
    error: categoriesError,
  } = useQuery<OpenCaseCategoriesQuery>(OPEN_CASE_CATEGORIES_QUERY);
  const openCaseCategories = categoriesData?.openCaseCategories;

  const [subcategories, setSubcategories] = useState<IDropdownValue[]>([]);
  const [getSubcategories, { data: subcategoriesData, loading: subcategoriesLoading, error: subcategoriesError }] =
    useLazyQuery<SubcategoriesByCategoryQuery>(SUBCATEGORIES_BY_CATEGORY_QUERY);
  const subcategoriesByCategory = subcategoriesData?.subcategoriesByCategory;

  const [watchListDropdownItems, setWatchListDropdownItems] = useState<IAccountContact[]>([]);

  useEffect(() => {
    if (showClientSelector) {
      queryClientTree();
    }
  }, []);

  useEffect(() => {
    queryContacts({ variables: { clientId } });
    queryAssets({ variables: { clientId } });
  }, [clientId]);

  const fetchClients: OnFetch = async (searchQuery, reset) => {
    const currNext = reset ? undefined : nextClientsToken;
    const currTotalClients = reset ? 0 : totalClients;

    const { data } = await queryClientTree({
      variables: {
        next: currNext,
        searchQuery,
      },
    });

    const { clientTree: items, next, total = 0 } = data?.currentUser?.allRelatedClients ?? {};

    if (!items) {
      return Promise.reject(NO_MORE_DATA);
    }

    const numClients = currTotalClients + items.length;
    setTotalClients(numClients);
    setNextClientsToken(next);

    return {
      selectOptions: items
        .filter((v): v is Client => v?.name !== undefined)
        .map((v) => ({
          value: v.id,
          label: v.name!,
        })),
      hasNoMoreData: !next || numClients >= total,
    };
  };

  useEffect(() => {
    if (!accountContacts) return;

    setContacts(accountContacts);
    setWatchListDropdownItems(accountContacts);
  }, [accountContacts]);

  useEffect(() => {
    if (!accountAssets) return;

    const a = accountAssets.map(({ display_name: name, sys_id: id }) => ({
      id,
      name: name ?? '',
    }));

    setAssets(a);
  }, [accountAssets]);

  useEffect(() => {
    if (!openCaseCategories) return;

    setCategories(openCaseCategories);
  }, [openCaseCategories]);

  useEffect(() => {
    if (!subcategoriesByCategory) return;

    setSubcategories(subcategoriesByCategory);
  });

  useEffect(() => {
    if (!user || !contacts.length) return;

    const { serviceNowUserId } = user!;
    if (contacts.find((i) => i.id === serviceNowUserId)) {
      form.setFieldsValue({ contact: serviceNowUserId });
    }
  }, [user, contacts]);

  const error = clientTreeError ?? contactsError ?? assetsError ?? categoriesError ?? subcategoriesError;
  useEffect(() => {
    if (error) {
      console.error(error);
      message.error('An unexpected error occurred.');
    }
  }, [error]);

  const onCategoryChange = (categoryValue: string) => {
    if (categoryValue.trim() === '') {
      setSubcategories([]);
    } else {
      getSubcategories({
        variables: {
          categoryValue,
        },
      });
    }
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  // https://ant.design/components/upload/#components-upload-demo-upload-manually
  const beforeUpload = () => false;

  const [createCase, { loading }] = useMutation<CreateCaseMutation, CreateCaseMutationVariables>(CREATE_CASE);
  const onFormFinish = async (values: any) => {
    if (!canOpenCase) {
      if (!canWriteCaseManagement) {
        message.error('You do not have the required permissions to open a case.');
      } else if (!user?.serviceNowUserId) {
        message.error('Your user is not linked with ServiceNow. Unable to open case.');
      } else if (!serviceNowAccountId) {
        message.error('Your client is not linked with ServiceNow. Unable to open case.');
      } else {
        message.error('Unable to open case.');
      }

      return;
    }

    const {
      contact,
      asset,
      category,
      subcategory,
      priority,
      subject,
      description,
      watchList,
      attachments = [],
    } = values;

    try {
      const result = await createCase({
        variables: {
          input: {
            accountId: account,
            contact,
            asset,
            category,
            subcategory,
            priority,
            shortDescription: subject,
            incidentSysId: incident,
            state: 10,
            clientId,
            description,
            watchList,
            eventId,
            files: attachments.map(({ originFileObj }) => originFileObj as File),
          },
        },
      });

      const newCase = result.data?.createCase;

      if (result.errors) {
        throw result.errors[0];
      }

      // Analytics
      mixpanelTrack('case-opened', {
        category,
        subCategory: subcategory,
        priority,
      });

      refetch?.();
      onSubmit?.(true, newCase);

      message.success(`Case created successfully!`, 5);
    } catch (err: any) {
      if (err.message) {
        message.error(err.message);
      } else {
        message.error('Failed to create case.');
      }
      onSubmit?.(false);
    }
  };

  const filterSelectItems = (input: string, option: any) => {
    const children = option.children;

    if (Array.isArray(children)) {
      const childrenAsString = children.join('');
      return childrenAsString.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }

    return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <>
      {title && subtitle && (
        <Header>
          <H1>{title}</H1>
          <H3>{subtitle}</H3>
          <Typography.Text>
            <span style={{ fontWeight: 700 }}>Note: </span>For cases above a moderate priority, please contact Nuspire
            directly at{' '}
            <a href="tel:(877) 435-1640" style={{ fontWeight: 700 }}>
              (877) 435-1640
            </a>
            {' '}or{' '}
            <a href="tel:(248) 896-6150" style={{ fontWeight: 700 }}>
              (248) 896-6150
            </a>
          </Typography.Text>
        </Header>
      )}
      <Form
        form={form}
        initialValues={{
          client: client!.name,
          contact: user?.serviceNowUserId ? contacts.find((c) => c.id === user.serviceNowUserId) : undefined,
          priority: defaults?.priority || defaultPriority,
          description: defaults?.description,
          subject: defaults?.shortDescription,
          watchList: [],
          category: defaults?.category,
          subcategory: defaults?.subcategory?.id,
        }}
        onFinish={onFormFinish}
        {...layout}
      >
        {showClientSelector && (
          <Form.Item label="Client" name="client">
            <InfiniteSelect
              onFetch={fetchClients}
              onChange={(id) => setClientId(id)}
              showSearch
              allowClear
              disabled={disabledFields?.client}
            />
          </Form.Item>
        )}
        <Form.Item label="Contact" name="contact" rules={[{ required: true, message: 'A contact is required!' }]}>
          <Select
            showSearch
            allowClear
            placeholder="Contact"
            loading={contactsLoading}
            disabled={loading || contactsLoading || disabledFields?.contact}
            filterOption={filterSelectItems}
          >
            {contacts.map((c) => (
              <Option key={c.id} value={c.id}>
                {c.name} &lt;{c.email}&gt;
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Asset" name="asset">
          <Select
            showSearch
            allowClear
            placeholder="Asset"
            loading={assetsLoading}
            disabled={loading || assetsLoading || disabledFields?.asset}
            filterOption={filterSelectItems}
          >
            {assets.map((a) => (
              <Option key={a.id} value={a.id}>
                {a.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Category" name="category" rules={[{ required: true, message: 'A category is required!' }]}>
          <Select
            showSearch
            placeholder="Category"
            loading={categoriesLoading}
            disabled={loading || categoriesLoading || disabledFields?.category}
            onChange={onCategoryChange}
            filterOption={filterSelectItems}
          >
            {categories?.map((c) => (
              <Option key={c.id} value={c.id}>
                {c.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Subcategory"
          name="subcategory"
          rules={[{ required: true, message: 'A subcategory is required!' }]}
        >
          <Select
            showSearch
            placeholder="Subcategory"
            loading={subcategoriesLoading}
            disabled={loading || subcategoriesLoading || !subcategories.length || disabledFields?.subcategory}
            filterOption={filterSelectItems}
          >
            {disabledFields?.subcategory && defaults?.subcategory
              ? [defaults.subcategory]?.map((s) => (
                  <Option key={s.id} value={s.id}>
                    {s.name}
                  </Option>
                ))
              : subcategories?.map((s) => (
                  <Option key={s.id} value={s.id}>
                    {s.name}
                  </Option>
                ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <>
              Priority <PriorityInfoBtn onClick={() => setIsPriorityDetailModalOpen(true)} />
            </>
          }
          name="priority"
          rules={[{ required: true, message: 'A priority is required!' }]}
        >
          <Select
            showSearch
            placeholder="Priority"
            disabled={loading || disabledFields?.priority}
            filterOption={filterSelectItems}
          >
            {Object.keys(priorities)
              .filter((p) => !['1 - Critical', '2 - High', '5 - Planning'].includes(p))
              .map((p) => (
                <Option key={p} value={p}>
                  {p}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item label="Subject" name="subject" rules={[{ required: true, message: 'A subject is required!' }]}>
          <Input disabled={loading || disabledFields?.subject} />
        </Form.Item>

        <Form.Item label="Description" name="description">
          <Input.TextArea
            rows={3}
            disabled={loading || disabledFields?.description}
            maxLength={!isModal ? 4000 : undefined}
            showCount
          />
        </Form.Item>

        <Form.Item
          label="Watch List"
          name="watchList"
          tooltip="List of users that will receive notifications about this case when additional comments are added or if the state of the case is changed to Resolved or Closed."
        >
          <WatchListSelect
            setWatchListDropdownItems={setWatchListDropdownItems}
            loading={contactsLoading || loading}
            filterOption={filterSelectItems}
            watchListDropdownItems={watchListDropdownItems}
          />
        </Form.Item>

        {!isModal && (
          <Form.Item label="Attachments" name="attachments" valuePropName="fileList" getValueFromEvent={normFile}>
            <Dragger beforeUpload={beforeUpload} disabled={loading}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload.</p>
              <p className="ant-upload-hint">Support for a single or bulk upload.</p>
            </Dragger>
          </Form.Item>
        )}

        <SubmitButton type="primary" htmlType="submit" loading={loading} disabled={!canOpenCase || loading}>
          Create Case
        </SubmitButton>
      </Form>
      <PriorityDetailsModal visible={isPriorityDetailModalOpen} onClose={setIsPriorityDetailModalOpen} />
    </>
  );
}

export function OpenCasePage() {
  const navigate = useNavigate();
  const { clientId } = useClientContext();

  return (
    <Content>
      <Helmet title="Open A New Case" />
      <Breadcrumb
        items={[
          {
            key: `${clientId}-case-management-cases`,
            title: <Link to={`/${clientId}/case-management/cases`}>Case Management</Link>,
          },
          {
            key: 'open-case',
            title: 'Open Case',
          },
        ]}
        target="case-management"
      />
      <OpenCase
        clientId={undefined}
        layout={formLayout}
        title="Create a case for a product"
        subtitle="Submit a case about your product and our support team will assist you."
        onSubmit={() => navigate(`/${clientId}/case-management`)}
      />
    </Content>
  );
}
