import { gql, useQuery } from '@apollo/client';
import { Badge, Col, Drawer, Row, Space } from 'antd';
import { TaskLogs } from 'components/admin/schedules/schedule-tasks/task-logs';
import { Link, EmptyState, NuButton, NuCard, NuCardContent, NuCardTitle } from 'components/nuspire';
import PageHeader from 'components/nuspire/nu-page-header';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useState } from 'react';
import styled from 'styled-components';
import { TaskLogDetailsQuery, TaskStatus } from 'types/graph-codegen/graph-types';
import { logListPath } from './task-handler';
import { SentryEventLinkButton } from './task-log-list';

dayjs.extend(relativeTime);

// types.
export type TaskLog = NonNullable<TaskLogDetailsQuery['taskLog']>;
export type Task = NonNullable<TaskLog['task']>;
export type TaskResult = NonNullable<TaskLog['taskResult']>;

const TASK_LOG_DETAIL = gql`
  query TaskLogDetails($id: String!) {
    taskLog(id: $id) {
      id
      summary
      clientId
      taskId
      # client
      status
      startedAt
      summary
      error
      errorStack

      sentryEvent {
        orgSlug
        projectSlug
        eventId
      }

      task {
        id
        executableSlug
        destination
        scheduleId
        scheduledTime
        startedAt
        schedule {
          id
          cron
          clientId
        }
      }

      taskResult {
        id
        ok
        taskStatus
        scheduleId
        scheduledTime
        startedAt
        completedAt
        summary
        error
        data
        variables
      }
    }
  }
`;

const MetaTable = styled.table``;
const MetaRow = styled.tr``;
const MetaCol = styled.td`
  padding-right: 16px;
  padding-bottom: 12px;
  vertical-align: top;

  &:first-child {
    font-weight: 600;
  }

  line-height: 1.5em;
`;

export function TaskStatusBadge(props: { status?: TaskStatus }) {
  const { status } = props;

  return (
    <Badge
      text={status ? `${status.charAt(0).toUpperCase()}${status.slice(1)}` : 'Unknown'}
      status={(() => {
        if (status === 'success') return 'success';

        if (status === 'failed') return 'error';

        if (status === 'rescheduled') return 'warning';

        if (status === 'running') return 'processing';

        return 'default';
      })()}
    />
  );
}

export function LogCard(props: { log: TaskLog }) {
  const {
    log: { status, summary },
  } = props;

  return (
    <NuCard>
      <NuCardTitle title="Log" />
      <NuCardContent>
        <MetaTable>
          {/* Status */}
          <MetaRow>
            <MetaCol>Status</MetaCol>
            <MetaCol>
              <TaskStatusBadge status={status} />
            </MetaCol>
          </MetaRow>

          {/* Summary */}
          <MetaRow>
            <MetaCol>Summary</MetaCol>
            <MetaCol>{summary}</MetaCol>
          </MetaRow>
        </MetaTable>
      </NuCardContent>
    </NuCard>
  );
}
export function ErrorCard(props: { log: TaskLog }) {
  const {
    log: { error, errorStack, status, sentryEvent },
  } = props;

  if (status !== 'failed') {
    return null;
  }

  return (
    <NuCard>
      <NuCardTitle title="Error" actions={<SentryEventLinkButton sentryEvent={sentryEvent} />} />
      <NuCardContent>
        <MetaTable>
          {/* Error MEssage */}
          <MetaRow>
            <MetaCol>Message</MetaCol>
            <MetaCol>{error ?? '--'}</MetaCol>
          </MetaRow>
          {/* Error MEssage */}
          <MetaRow>
            <MetaCol>Stack</MetaCol>
            <MetaCol>{errorStack ?? '--'}</MetaCol>
          </MetaRow>
        </MetaTable>
      </NuCardContent>
    </NuCard>
  );
}

export function TaskCard(props: { log: TaskLog; onViewTaskLogs: (taskId: string) => void }) {
  const {
    log: { task, taskResult, taskId },
    onViewTaskLogs,
  } = props;

  const startedAt = taskResult?.startedAt ? dayjs(taskResult.startedAt).fromNow() : '--';

  return (
    <NuCard>
      <NuCardTitle
        title="Task"
        actions={
          <NuButton type="text" onClick={() => onViewTaskLogs(taskId)}>
            View All Logs
          </NuButton>
        }
      />

      <NuCardContent>
        <Row>
          <Col lg={12}>
            <MetaTable>
              {/* Action */}
              <MetaRow>
                <MetaCol>Action</MetaCol>
                <MetaCol>{task?.executableSlug ?? '--'}</MetaCol>
              </MetaRow>

              {/* Started At */}
              <MetaRow>
                <MetaCol>Started At</MetaCol>
                <MetaCol>{startedAt}</MetaCol>
              </MetaRow>

              {/* Status */}
              <MetaRow>
                <MetaCol>Status</MetaCol>
                <MetaCol>
                  <TaskStatusBadge status={taskResult?.taskStatus as TaskStatus} />
                </MetaCol>
              </MetaRow>
            </MetaTable>
          </Col>
          <Col lg={12}>
            <MetaTable>
              {/* Schedule */}
              <MetaRow>
                <MetaCol>Schedule</MetaCol>
                {/* <MetaCol>{taskResult?.schedule? ?? '--'}</MetaCol> */}
                <MetaCol>
                  {task?.schedule ? (
                    <Link to={`/admin/schedules/${task.schedule.clientId}/${task.scheduleId}`} mode="plain">
                      {task.schedule.cron}
                    </Link>
                  ) : (
                    '--'
                  )}
                </MetaCol>
              </MetaRow>
              {/* Summary */}
              <MetaRow>
                <MetaCol>Summary</MetaCol>
                <MetaCol>{taskResult?.summary ?? '--'}</MetaCol>
              </MetaRow>
            </MetaTable>
          </Col>
        </Row>
      </NuCardContent>
    </NuCard>
  );
}

export function TaskLogDetailView(props: { log: TaskLog }) {
  const { log } = props;
  const [taskLogsId, setTaskLogsId] = useState<string | undefined>();
  const handleViewTaskLogs = (taskId: string) => setTaskLogsId(taskId);
  const handleCloseLogs = () => setTaskLogsId(undefined);

  return (
    <>
      <PageHeader title="Log" />

      <Space
        direction="vertical"
        style={{
          display: 'flex',
          alignItems: 'stretch',
        }}
        size="large"
      >
        <LogCard log={log} />

        <ErrorCard log={log} />

        <TaskCard log={log} onViewTaskLogs={handleViewTaskLogs} />
      </Space>

      <Drawer
        title="Task Logs"
        placement="right"
        onClose={handleCloseLogs}
        open={Boolean(taskLogsId)}
        getContainer={false}
        // rootStyle={}
        width={1000}
      >
        {taskLogsId && Boolean(taskLogsId) && <TaskLogs taskId={taskLogsId} />}
      </Drawer>
    </>
  );
}

export function TaskLogDetail(props: { id: string }) {
  const { id } = props;

  const { data, loading } = useQuery<TaskLogDetailsQuery>(TASK_LOG_DETAIL, {
    variables: { id },
  });

  const log = data?.taskLog;
  if (log) {
    return <TaskLogDetailView log={log} />;
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  return (
    <EmptyState
      actions={
        <Link to={logListPath()} mode="plain">
          <NuButton>Back to Log List</NuButton>
        </Link>
      }
    >
      {`Log: ${id} could not be found.`}
    </EmptyState>
  );
}
