import { useMutation } from '@apollo/client';
import { useOktaAuth } from '@okta/okta-react';
import { Alert, Collapse, Form, Result, Typography } from 'antd';
import { NuButton } from 'components/nuspire';
import { NuspireIcon } from 'components/nuspire/nu-icon';
import { FormikInput } from 'components/shared-components';
import baseTheme from 'components/theme';
import { Formik } from 'formik';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router';
import styled from 'styled-components';
import * as yup from 'yup';
import { MAKE_CONNECTION_MUTATION } from '..';
import { ConnectionInstructionsWrapper } from '../index';
import { decodeOAuthState } from '../utils/oauth';
import connector from './meraki';

const validationSchema = yup.object().shape({
  apiKey: yup.string().required(),
  baseURL: yup.string().url().required(),
  name: yup.string().required(),
});

interface IAuthFormValues {
  apiKey: string;
  baseURL: string;
  name: string;
  testConnection: boolean;
}

interface IMerakiAuthFormProps {
  onSubmit: (args: {
    values: IAuthFormValues;
    setSubmitting: (isSubmitting: boolean) => void;
    setError: (value: string) => void;
  }) => Promise<void>;
}

function MerakiAuthForm({ onSubmit }: IMerakiAuthFormProps) {
  const [error, setError] = useState<string | undefined>();

  return (
    <Formik
      initialValues={{
        apiKey: '',
        baseURL: '',
        name: '',
        testConnection: true,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit({ values, setSubmitting, setError });
      }}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting, errors, dirty }) => (
        <Form
          layout="vertical"
          onFinish={() => {
            submitForm();
          }}
        >
          {error ? <Alert type="error" message={error} /> : null}

          <FormikInput
            name="name"
            label="API Client Name"
            required
            tooltip="This will be used in myNuspire when referencing this API client."
          />
          <FormikInput name="apiKey" label="API Key" required tooltip="API Key" />
          <FormikInput
            name="baseURL"
            label="Instance URL"
            required
            tooltip="Ex: https://api.meraki.com/api/v1"
            placeholder="Ex: https://api.meraki.com/api/v1"
          />

          <FormikInput name="testConnection" checkbox>
            Test credentials before creation
          </FormikInput>
          <Form.Item>
            <NuButton
              type="primary"
              htmlType="submit"
              disabled={!dirty || Object.keys(errors).length > 0 || isSubmitting}
              loading={isSubmitting}
            >
              Submit
            </NuButton>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
}

function MerakiConnectionInstructions() {
  return (
    <Collapse style={{ marginBottom: '32px' }} defaultActiveKey="instructions">
      <Collapse.Panel key="instructions" header="API Token Instructions">
        <ConnectionInstructionsWrapper>
          <Typography.Paragraph>
            myNuspire leverages Meraki REST APIs which are authenticated with an API Key. Follow the instructions{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://developer.cisco.com/meraki/api-latest/#!authorization/obtaining-your-meraki-api-key"
            >
              here
            </a>{' '}
            in order to generate an API Key within the Meraki dashboard in order to create a Connection.
          </Typography.Paragraph>
        </ConnectionInstructionsWrapper>
      </Collapse.Panel>
    </Collapse>
  );
}

const Layout = styled.div``;

const Header = styled.div`
  padding: 16px 32px;
  border-bottom: 1px solid ${(p) => p.theme.token.colorBorder};
`;

const Content = styled.div`
  padding: 16px 32px;
`;

export default function MerakiRedirect() {
  const location = useLocation();
  const search = location.search;
  const { authState: oktaAuthState } = useOktaAuth();

  const [makeConnection] = useMutation(MAKE_CONNECTION_MUTATION);
  const [success, setSuccess] = useState<boolean>(false);

  const stateString = new URLSearchParams(search).get('state');
  const authState = decodeOAuthState(stateString);

  if (!oktaAuthState?.isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return (
    <Layout>
      <Header>
        <Typography.Title level={2} style={{ marginBottom: 0 }}>
          <NuspireIcon style={{ color: baseTheme.color.primaryBlue, marginRight: '8px' }} />
          Connect to Meraki
        </Typography.Title>
      </Header>
      <Content>
        {!success ? (
          <>
            <MerakiConnectionInstructions />

            <Typography.Title level={3}>Create Connection</Typography.Title>
            <MerakiAuthForm
              onSubmit={async ({ values, setSubmitting, setError }) => {
                const { testConnection, ...payload } = values;
                const { clientId } = authState;

                if (!clientId) {
                  throw new Error('No client id was passed');
                }

                const { data, errors } = await makeConnection({
                  variables: {
                    connectorSlug: connector.slug,
                    payloadJson: JSON.stringify(payload),
                    clientId,
                    testConnection,
                  },
                });

                setSubmitting(false);

                if (errors?.length) {
                  setError(errors[0].message);
                  return;
                }

                const newConnection = data?.makeConnection;

                if (newConnection && !errors) {
                  const newEvent = new CustomEvent(`new-connection-${connector.slug}`, {
                    detail: {
                      connection: newConnection,
                    },
                  });

                  window?.opener?.dispatchEvent(newEvent);

                  setSuccess(true);
                }
              }}
            />
          </>
        ) : (
          <Result status="success" title="Connection was successfully created!" />
        )}
      </Content>
    </Layout>
  );
}
