import { gql, useQuery } from '@apollo/client';
import { Button, Popover, Space, Spin, Typography } from 'antd';
import { useAuthContext } from 'components/auth-context';
import { GripIcon } from 'lucide-react';
import { useState } from 'react';
import styled from 'styled-components';
import { GetUserApplicationsQuery, GetUserApplicationsQueryVariables } from 'types/graph-codegen/graph-types';

const GET_USER_APPLICATIONS = gql`
  query GetUserApplications($userId: String!) {
    userApplications(userId: $userId) {
      apps {
        id
        appName
        linkUrl
        logoUrl
        label
        hidden
        sortOrder
      }
    }
  }
`;

const ApplicationCardContainer = styled(Space).attrs({
  direction: 'vertical',
})`
  height: 100px;
  width: 150px;
  align-items: center;
  text-align: center;
  justify-content: center;

  & img {
    max-width: 120px;
    max-height: 70px;
  }
`;

interface ApplicationCardProps {
  label: string;
  linkUrl: string;
  logoUrl?: string;
}

function ApplicationCard(props: ApplicationCardProps) {
  const { label, linkUrl, logoUrl } = props;

  return (
    <a href={linkUrl}>
      <ApplicationCardContainer>
        <img src={logoUrl} />
        <Typography.Text>{ label }</Typography.Text>
      </ApplicationCardContainer>
    </a>
  );
}

const ApplicationSwitcherContainer = styled(Space)`
  display: flex;
  width: 350px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  row-gap: 25px;
`;

interface UnifiedApplicationSwitcherProps {
  apps: (ApplicationCardProps & {
    sortOrder: number,
  })[];
}

function UnifiedApplicationSwitcher(props: UnifiedApplicationSwitcherProps) {
  const apps = [...props.apps]
    .sort((a, b) => a.sortOrder - b.sortOrder);

  return (
    <ApplicationSwitcherContainer>
      {...apps.map((app) => ApplicationCard(app))}
    </ApplicationSwitcherContainer>
  );
}

export function UnifiedApplicationSwitcherButton() {
  const [isSwitcherVisible, setIsSwitcherVisible] = useState(false);
  const { user } = useAuthContext();
  if (!user) {
    console.error("no current user found");
    return;
  }

  const { data, loading } = useQuery<GetUserApplicationsQuery, GetUserApplicationsQueryVariables>(
    GET_USER_APPLICATIONS,
    {
      variables: {
        userId: user.id,
      },
    }
  );

  const popoverContent = (
    <Spin spinning={loading}>
      <UnifiedApplicationSwitcher apps={data?.userApplications?.apps ?? []} />
    </Spin>
  );

  return (
    <Popover
      placement="bottomRight"
      content={popoverContent}
      title="PDI Apps"
    >
      <Button
        shape="circle"
        type="text"
        size="large"
        onClick={() => setIsSwitcherVisible(!isSwitcherVisible)}
      >
        <GripIcon size={20} />
      </Button>
    </Popover>
  );
}
