import { NuButton, NuCard, NuCardTitle, NuCardContent } from 'components/nuspire';
import { Dropdown, Menu } from 'antd';
import * as NuIcons from 'components/nuspire/nu-icon';
import { WidgetRendererProps } from 'components/dashboard/widget-definitions/types';

export interface ConnectionWidgetProps {
  title: string;
  slug: string;
  connectionId: string;

  /**
   * Component to render contents.
   */
  component: (props: WidgetRendererProps) => JSX.Element | null;

  onAddWidgetToDashboard: (widgetSlug: string) => void;
  onCloseAddWidgetModal: () => void;
}

function ConnectionWidget(props: ConnectionWidgetProps) {
  const { title, connectionId, component: Component, slug, onAddWidgetToDashboard } = props;

  // simulating what would be saved if the user saves this widget to a dashboard.
  const configuration = {
    connectionId,
  };

  return (
    <NuCard fullHeight>
      <NuCardTitle
        title={title}
        actions={
          <Dropdown
            trigger={['click']}
            placement="bottomRight"
            menu={{
              items: [{
                key: 'add-to-dashboard',
                onClick: () => onAddWidgetToDashboard(slug),
                label: 'Add to Dashboard',
              }],
            }}
          >
            <NuButton shape="circle" icon={<NuIcons.Ellipsis style={{ fontSize: '24px' }} />} type="text" />
          </Dropdown>
        }
      />
      <NuCardContent>
        <Component configuration={configuration} />
      </NuCardContent>
    </NuCard>
  );
}

export default ConnectionWidget;
