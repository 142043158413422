import { useState, useEffect } from 'react';
import { useLazyQuery, gql, useMutation } from '@apollo/client';
import { Modal, message } from 'antd';
import { NuButton } from 'components/nuspire';
import WatchListSelect from './watch-list-select';
import { IAccountContact } from 'types';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { CaseWatchListQuery } from 'types/graph-codegen/graph-types';

export const CASE_WATCH_LIST_QUERY = gql`
  query CaseWatchList($caseNumber: String!, $clientId: String, $displayValue: Boolean) {
    case(caseNumber: $caseNumber, clientId: $clientId, displayValue: $displayValue) {
      watch_list
    }

    accountContacts(clientId: $clientId) {
      id
      email
      name
    }
  }
`;

export const UPDATE_CASE_WATCH_LIST_MUTATION = gql`
  mutation UpdateCaseWatchList($clientId: String, $caseNumber: String!, $watchList: [String!]!) {
    updateCaseWatchList(clientId: $clientId, caseNumber: $caseNumber, watchList: $watchList) {
      number
      sys_id
    }
  }
`;

const UpdateWatchListModal = (props: { caseNumber: string; clientId: string; open: boolean; close: Function }) => {
  const { clientId, open, close, caseNumber } = props;
  const [watchListQuery, { data, loading }] = useLazyQuery<CaseWatchListQuery>(CASE_WATCH_LIST_QUERY);
  const [updateWatchList, { loading: updatingWatchList }] = useMutation(UPDATE_CASE_WATCH_LIST_MUTATION);
  const [selectedContacts, setSelectedContacts] = useState<string[]>([]);
  const [options, setOptions] = useState<IAccountContact[]>([]);

  useEffect(() => {
    if (data) {
      setOptions(data?.accountContacts);
      setSelectedContacts(
        data?.case?.watch_list
          ?.split(',')
          ?.filter((w) => w)
          ?.map((w) => {
            const contact = data?.accountContacts?.find((c) => {
              const item = w.trim();

              return c.id === item || c.email === item;
            })?.id;

            if (contact) return contact;

            // It's possible that it's just an email with no contact
            // Validate that it at least has an @
            if (w.includes('@')) {
              return w;
            }

            console.error('Case watch list contact is not a contact or email.');
            return '';
          }) ?? [],
      );
    }
  }, [data]);

  useEffect(() => {
    if (open && selectedContacts?.length === 0) {
      watchListQuery({ variables: { clientId, caseNumber, displayValue: false } });
    }
  }, [open]);

  return (
    <Modal open={open} title="Update Case Watch List" onCancel={() => close(false)} footer={null}>
      {loading ? (
        <SpinContainer>
          <Spin />
        </SpinContainer>
      ) : (
        <>
          <div style={{ width: '100%', margin: '1rem 0' }}>
            <WatchListSelect
              loading={loading}
              watchListDropdownItems={options}
              setWatchListDropdownItems={setOptions}
              value={selectedContacts}
              onChange={(values) => setSelectedContacts(values)}
            />
          </div>
          <NuButton
            type="primary"
            loading={updatingWatchList}
            disabled={loading}
            onClick={async () => {
              try {
                await updateWatchList({ variables: { caseNumber, clientId, watchList: selectedContacts } });
                message.success('Watch List Updated!');
              } catch (error) {
                console.error(error);
                message.error('Failed to Update Watch List.');
              }
            }}
          >
            Save
          </NuButton>
        </>
      )}
    </Modal>
  );
};

export default UpdateWatchListModal;
