import { CalendarOutlined, DownOutlined } from '@ant-design/icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Badge, Dropdown, MenuProps, Space, Tooltip, Typography, message } from 'antd';
import classnames from 'classnames';
import { useClientTaskModalContext } from 'components/client-tasks/client-task-modal-context-provider';
import { NuButton, NuCard, NuCardContent } from 'components/nuspire';
import baseTheme from 'components/theme';
import dayjs from 'dayjs';
import { useState } from 'react';
import styled from 'styled-components';
import { mixpanelTrack } from 'utils/mixpanel/mixpanel-track';
import { getShortIdLabel } from './client-task-modal';
import { TaskMembersAvatars } from './task-members';
import { ClientTaskStatus, ClientTaskType } from './types';

export const PRIORITY_SCALE: {
  min: number;
  label: string;
  color: string;
}[] = [
  {
    min: 8,
    label: 'Critical',
    color: baseTheme.color.error,
  },
  {
    min: 5,
    label: 'High',
    color: baseTheme.color.orange,
  },
  {
    min: 3,
    label: 'Medium',
    color: baseTheme.color.yellow,
  },
  {
    min: 0,
    label: 'Low',
    color: baseTheme.color.green,
  },
  {
    min: -1,
    label: 'Unknown',
    color: baseTheme.color.primaryGray,
  },
];

export function getPriority(value?: number) {
  if (!value) {
    return PRIORITY_SCALE[PRIORITY_SCALE.length - 1];
  }

  const match = PRIORITY_SCALE.find((d) => value >= d.min) ?? PRIORITY_SCALE[PRIORITY_SCALE.length - 1];

  return match;
}

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const HeaderLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;
const Body = styled.div`
  margin-bottom: 24px;
`;
const Footer = styled.div`
  display: flex;
  align-items: center;
`;
const CreatedAt = styled.div`
  color: ${baseTheme.color.gray5};
`;
const FooterMeta = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;
const Summary = styled.div`
  color: #343434;
  margin-bottom: 8px;
`;
const Content = styled(NuCardContent)`
  padding-top: 8px;
  &:hover {
    cursor: pointer;
  }
`;

const ClientTaskStatusButtonRoot = styled.div`
  &.todo,
  &.open,
  &.archived {
    .ant-btn-primary {
      background-color: #f3f3f3;
      border-color: #f3f3f3;
      color: #555555;
    }
  }

  &.completed {
    .ant-btn-primary {
      background-color: ${(p) => p.theme.color.success};
      border-color: ${(p) => p.theme.color.success};
      color: #fff;
    }
  }
`;

const UPDATE_CLIENT_TASK_STATUS = gql`
  mutation UpdateClientTask($id: String!, $status: ClientTaskStatus) {
    updateClientTask(id: $id, status: $status) {
      id
      shortId
      type
      clientId
      label
      status
      snowCaseNumber
      snowIncidentNumber
      members

      startDate
      endDate
      createdAt

      summary

      impact
      remediation
      info
      tags
      priority
    }
  }
`;

const CLIENT_TASK_STATUS = gql`
  query ClientTaskStatus($id: String!) {
    clientTask(id: $id) {
      id
      shortId
      type
      clientId
      label
      status
      snowCaseNumber
      snowIncidentNumber
      members

      startDate
      endDate
      createdAt

      summary

      impact
      remediation
      info
      tags
      priority
    }
  }
`;

const taskStatuses: {
  key: string;
  label: string;
  type: string[];
}[] = [
  {
    key: 'open',
    label: 'Open',
    type: ['issue'],
  },
  {
    key: 'closed',
    label: 'Closed',
    type: ['issue'],
  },
  {
    key: 'todo',
    label: 'To do',
    type: ['recommendation'],
  },
  {
    key: 'in_progress',
    label: 'In Progress',
    type: ['recommendation'],
  },
  {
    key: 'review',
    label: 'Ready for Review',
    type: ['recommendation'],
  },
  {
    key: 'completed',
    label: 'Completed',
    type: ['recommendation'],
  },
  {
    key: 'suppressed',
    label: 'Suppressed',
    type: ['recommendation'],
  },
  {
    key: 'archived',
    label: 'Archive',
    type: ['recommendation'],
  },
];

export function getTaskStatusMenuItems(type?: ClientTaskType) {
  const t = type ?? 'recommendation';
  return taskStatuses.filter((taskStatus) => taskStatus.type.includes(t));
}

export function ClientTaskStatusButton(props: { id: string }) {
  const { id } = props;

  const { data, loading } = useQuery(CLIENT_TASK_STATUS, { variables: { id } });

  const status = data?.clientTask?.status;
  const type = data?.clientTask?.type;

  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);

  // Keep status in state because data coming across widget is not cached.
  // Display
  const rootClasses = classnames(status);

  const [updateTaskStatus] = useMutation(UPDATE_CLIENT_TASK_STATUS);

  const handleStatusChange = async (key: string) => {
    const prevStatus = status;
    try {
      const { data } = await updateTaskStatus({
        variables: {
          id,
          status: key,
        },
        optimisticResponse: {
          updateClientTask: {
            id,
            status: key,
            __typename: 'ClientTask',
          },
        },
      });

      const updatedTask = data?.updateClientTask;

      if (updatedTask) {
        await mixpanelTrack('client_task_status_changed', {
          ...updatedTask,
          prevStatus,
        });
      }
    } catch (err) {
      console.error(err);

      message.error('Failed to update task Status.');
    }
  };

  const menuItems = getTaskStatusMenuItems(type);
  const selected = menuItems.find((item) => item.key === status);
  const label = selected?.label ?? taskStatuses.find((taskStatus) => taskStatus.key === status)?.label;

  const menu: MenuProps = {
    onClick: async (e) => {
      e.domEvent.stopPropagation();
      await handleStatusChange(e.key);
    },
    items: menuItems,
  };

  return (
    <ClientTaskStatusButtonRoot className={rootClasses}>
      <Dropdown
        menu={menu}
        open={isDropdownVisible}
        onOpenChange={(visible) => {
          setIsDropdownVisible(visible);
        }}
        trigger={['click']}
      >
        <NuButton
          type="primary"
          onClick={(e) => {
            e.stopPropagation();
          }}
          loading={!status && loading}
        >
          {label} <DownOutlined />
        </NuButton>
      </Dropdown>
    </ClientTaskStatusButtonRoot>
  );
}

type TaskMember = {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
};

export function ClientTaskCard(props: {
  id: string;
  label: string;
  summary?: string;
  status?: ClientTaskStatus;
  priority?: number;
  createdAt?: string;
  showUsers?: boolean;
  showSchedule?: boolean;
  type?: ClientTaskType;
  members?: TaskMember[];
  shortId?: number;
  size?: 'regular' | 'small';
}) {
  const {
    id,
    label,
    summary,
    status,
    priority,
    createdAt,
    showSchedule = true,
    showUsers = true,
    type,
    members,
    shortId,
    size = 'regular',
  } = props;
  const { showModal } = useClientTaskModalContext();

  const priorityDef = getPriority(priority);

  const fCreatedAt = createdAt ? `Created: ${dayjs(createdAt).format('MMMM D, YYYY')}` : undefined;

  const showScheduling = showSchedule && type && ['recommendation'].includes(type);

  const shortIdLabel = getShortIdLabel({
    shortId,
    type,
  });

  return (
    <NuCard paperProps={{ level: 2 }}>
      <Content onClick={() => showModal(id)}>
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Header>
            <HeaderLeft>
              <Badge
                color={priorityDef.color}
                text={size === 'regular' ? priorityDef.label : undefined}
                style={{
                  marginRight: size === 'regular' ? '24px' : '0',
                }}
              />

              {shortIdLabel && (
                <Typography.Text type="secondary" style={{ textTransform: 'capitalize' }}>
                  {shortIdLabel}
                </Typography.Text>
              )}
            </HeaderLeft>

            {status && <ClientTaskStatusButton id={id} />}
          </Header>

          {size === 'regular' && (
            <>
              <Body>
                <Typography.Title level={5} style={{ marginBottom: '8px' }}>
                  {label}
                </Typography.Title>

                {summary && <Summary>{summary}</Summary>}
              </Body>

              <Footer>
                <FooterMeta>
                  {showUsers && <TaskMembersAvatars members={members} />}

                  {showScheduling && (
                    <div style={{ margin: 'auto 0 auto 2rem' }}>
                      <Tooltip overlay="Coming Soon">
                        <CalendarOutlined />
                        <Typography.Text style={{ marginLeft: '.5rem' }}>Unscheduled</Typography.Text>
                      </Tooltip>
                    </div>
                  )}
                </FooterMeta>
                {fCreatedAt && <CreatedAt>{fCreatedAt}</CreatedAt>}
              </Footer>
            </>
          )}
        </Space>
      </Content>
    </NuCard>
  );
}
