import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { loginOktaUser } from 'utils/okta';
import Spin, { SpinContainer } from './nuspire/spin';

export default function SAMLCallback() {
  const navigate = useNavigate();

  useEffect(() => {
    loginOktaUser(navigate);
  });

  return (
    <SpinContainer>
      <Spin />
    </SpinContainer>
  );
}
