import { Spin } from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { mixpanelTrack } from '../utils/mixpanel/mixpanel-track';
import { loginOktaUser } from '../utils/okta';
import { UnauthenticatedLayout } from './layouts/unauthenticated-layout';
import { SpinContainer } from './nuspire/spin';

export const LoginCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    loginOktaUser(navigate, () => {
      mixpanelTrack('login-success', {
        method: 'username-password',
      });
    });
  });

  return (
    <UnauthenticatedLayout showAuthActions={false}>
      <SpinContainer>
        <Spin />
      </SpinContainer>
    </UnauthenticatedLayout>
  );
};
