import { gql, useQuery } from '@apollo/client';
import { Col, Row, Tooltip, Typography, message } from 'antd';
import { useClientContext } from 'components/client-context-provider';
import Breadcrumb from 'components/nuspire/nu-breadcrumb';
import { P } from 'components/shared-components';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router';
import { mixpanelTrack } from 'utils/mixpanel/mixpanel-track';
import type {
  IncidentAdditionalCommentsQuery,
  IncidentAdditionalCommentsQueryVariables,
  IncidentByNumberQuery,
  IncidentByNumberQueryVariables,
  ServiceNowCaseAdditionalDetails,
} from '../../types/graph-codegen/graph-types';
import InfoCard from '../info-card';
import { Content } from '../layouts/content';
import { EmptyState, NuButton } from '../nuspire';
import Spin, { SpinContainer } from '../nuspire/spin';
import { Description } from './case-detail';
import CaseDetailsTimeline from './case-details-timeline';
import { PriorityText } from './case-management-table';

export const INCIDENT_BY_NUMBER_QUERY = gql`
  query IncidentByNumber($incidentNumber: String!, $clientId: String) {
    incident(incidentNumber: $incidentNumber, clientId: $clientId) {
      category
      close_code
      close_notes
      closed_by {
        display_value
      }
      description
      number
      state
      priority
      resolved_at
      short_description
      subcategory
      sys_created_on
      sys_updated_on
      u_account {
        display_value
      }
    }
  }
`;

export const INCIDENT_ADDITIONAL_COMMENTS_QUERY = gql`
  query IncidentAdditionalComments($clientId: String, $incidentNumber: String!) {
    incidentAdditionalComments(clientId: $clientId, incidentNumber: $incidentNumber) {
      new
      sys_id
      sys_created_on
      user {
        value
      }
      user_name
    }
  }
`;

export function IncidentDetailPage() {
  const { clientId } = useClientContext();
  const { number: incidentNumber } = useParams<{ number: string }>();
  const { data, loading, error } = useQuery<IncidentByNumberQuery, IncidentByNumberQueryVariables>(
    INCIDENT_BY_NUMBER_QUERY,
    {
      variables: {
        incidentNumber: incidentNumber ?? '',
        clientId,
      },
    },
  );
  const {
    data: acData,
    loading: additionalCommentsLoading,
    error: additionalCommentsError,
  } = useQuery<IncidentAdditionalCommentsQuery, IncidentAdditionalCommentsQueryVariables>(
    INCIDENT_ADDITIONAL_COMMENTS_QUERY,
    {
      variables: {
        incidentNumber: incidentNumber ?? '',
        clientId,
      },
    },
  );

  useEffect(() => {
    if (error) {
      message.error(`There was an error fetching incident ${incidentNumber}`);
    }
  }, [error]);

  useEffect(() => {
    mixpanelTrack('incident-details-viewed');
  }, [incidentNumber]);

  const breadcrumb = (
    <Breadcrumb
      items={[
        {
          key: 'case-management',
          title: 'Case Management',
        },
        {
          key: `${clientId}-case-management-incidents`,
          title: <Link to={`/${clientId}/case-management/incidents`}>Incidents</Link>,
        },
        {
          key: incidentNumber,
          title: incidentNumber,
        },
      ]}
      target="case-management"
    />
  );

  if (loading) {
    return (
      <Content>
        <Helmet title={`Incident ${incidentNumber}`} />
        {breadcrumb}
        <SpinContainer>
          <Spin />
        </SpinContainer>
      </Content>
    );
  }

  if (!loading && !data?.incident) {
    return (
      <Content>
        <Helmet title={`Incident ${incidentNumber}`} />
        {breadcrumb}
        <EmptyState
          actions={
            <Link to={`/${clientId}/case-management/incidents`}>
              <NuButton type="primary">Back to Case Management</NuButton>
            </Link>
          }
        >
          Could not find incident {incidentNumber}.
        </EmptyState>
      </Content>
    );
  }

  return (
    <Content>
      <Helmet title={`Incident ${incidentNumber}`} />
      {breadcrumb}

      <Row>
        <Col lg={16}>
          <Typography.Title>{data?.incident?.short_description}</Typography.Title>

          <P>
            <Tooltip
              open={data?.incident ? undefined : false}
              placement="right"
              title={dayjs.utc(data?.incident?.sys_created_on).local().format('MMMM Do YYYY, h:mm:ss a')}
            >
              <strong>Created:</strong> {data?.incident && dayjs.utc(data?.incident?.sys_created_on).local().fromNow()}
            </Tooltip>
          </P>

          <P>
            <Tooltip
              open={data?.incident ? undefined : false}
              placement="right"
              title={dayjs.utc(data?.incident?.sys_updated_on).local().format('MMMM Do YYYY, h:mm:ss a')}
            >
              <strong>Updated:</strong> {data?.incident && dayjs.utc(data?.incident?.sys_updated_on).local().fromNow()}
            </Tooltip>
          </P>

          {data?.incident?.resolved_at && (
            <P>
              <Tooltip
                open={data?.incident ? undefined : false}
                placement="right"
                title={dayjs.utc(data?.incident.resolved_at).local().format(`MMMM Do YYYY, h:mm:ss a`)}
              >
                <strong>Resolved:</strong> {dayjs.utc(data?.incident.resolved_at).local().fromNow()}
              </Tooltip>
            </P>
          )}

          <P>
            <strong>State:</strong> {data?.incident?.state}
          </P>

          {data?.incident?.closed_by?.display_value && (
            <P>
              <strong>Closed by:</strong> {data?.incident?.closed_by.display_value}
            </P>
          )}
          {data?.incident?.close_code && (
            <P>
              <strong>Close Code:</strong> {data?.incident?.close_code}
            </P>
          )}
          {data?.incident?.close_notes && (
            <P>
              <strong>Close Notes:</strong> {data?.incident.close_notes}
            </P>
          )}

          <P>
            <strong>Priority:</strong> {data?.incident && <PriorityText priority={data?.incident?.priority} />}
          </P>

          <P>
            <strong>Category:</strong> {data?.incident?.category}
          </P>

          <P>
            <strong>Subcategory:</strong> {data?.incident?.subcategory}
          </P>

          <P style={{ marginBottom: 0 }}>
            <strong>Description:</strong>
          </P>
          <Description>{data?.incident?.description}</Description>
        </Col>
        <Col lg={8} style={{ paddingTop: '1rem' }}>
          <Typography.Paragraph type="secondary" style={{ marginBottom: '0.25rem' }}>
            Account
          </Typography.Paragraph>
          {data?.incident?.u_account?.display_value}
        </Col>
        {data?.incident && (
          <InfoCard title="Incident Details" style={{ marginTop: '5rem', width: '100%' }}>
            <div style={{ paddingTop: '1.5rem' }}>
              <CaseDetailsTimeline
                caseNumber={incidentNumber ?? ''}
                createdOn={data?.incident?.sys_created_on}
                details={
                  acData?.incidentAdditionalComments?.map((ac): ServiceNowCaseAdditionalDetails => {
                    //removes markdown formatting from the SNOW side
                    if (ac?.new?.includes('[code]') || ac?.new?.includes('[/code]')) {
                      ac.new.replace(/\[code\]|\[\/code\]/g, ' ');
                    }
                    return {
                      user_name: ac?.user_name,
                      sys_created_on: ac?.sys_created_on ?? '',
                      sys_id: ac?.sys_id ?? '',
                      user_sys_id: ac?.user?.value,
                      detail: ac?.new,
                      type: '',
                      attachments: [],
                      additionalDetails: [],
                    };
                  }) ?? []
                }
                error={additionalCommentsError}
                loading={additionalCommentsLoading}
              />
            </div>
          </InfoCard>
        )}
      </Row>
    </Content>
  );
}
