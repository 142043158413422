import { useMutation } from '@apollo/client';

import { useOktaAuth } from '@okta/okta-react';
import { Checkbox, Form, Input, Result, Typography, message } from 'antd';
import { NuButton } from 'components/nuspire';
import { NuspireIcon } from 'components/nuspire/nu-icon';
import baseTheme from 'components/theme';
import { useState } from 'react';
import { Navigate } from 'react-router';
import styled from 'styled-components';
import useSearchParams from 'utils/react-hooks/useSearchParams';
import { MAKE_CONNECTION_MUTATION } from '..';
import connector from './qualys-connector-definition';
import { QualysApiServerUrlSmartSelect } from 'components/connections/connector-detail/connection-detail/connection-detail';

type AuthFormFields = {
  qualysApiServerUrl: string;
  name: string;
  username: string;
  password: string;
  testConnection: boolean;
};

function QualysAuthForm(props: { onSubmit: (values: AuthFormFields) => Promise<void> }) {
  const { onSubmit } = props;

  const [form] = Form.useForm();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={async (values) => {
        try {
          setIsSubmitting(true);

          await onSubmit(values);
        } catch (err) {
          console.error(err);
          message.error(`Failed to create connection`);
        } finally {
          setIsSubmitting(false);
        }
      }}
      initialValues={{
        testConnection: true,
      }}
    >
      <Form.Item
        name="name"
        label="Connection Name"
        required
        tooltip
        rules={[{ required: true, message: 'Please enter Connection Name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="username"
        label="Username"
        required
        rules={[{ required: true, message: 'Please enter Username' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="password"
        label="Password"
        required
        rules={[{ required: true, message: 'Please enter Password' }]}
      >
        <Input.Password />
      </Form.Item>

      <QualysApiServerUrlSmartSelect
        name="qualysApiServerUrl"
        label="Qualys Api Server Url"
        tooltip="The Qualys API URL you should use for API requests depends on the Qualys platform
          where your account is located."
        required={true}
      />

      <Form.Item name="testConnection" valuePropName="checked">
        <Checkbox>Test credentials before creation</Checkbox>
      </Form.Item>

      <NuButton type="primary" htmlType="submit" disabled={isSubmitting} loading={isSubmitting}>
        Submit
      </NuButton>
    </Form>
  );
}

const RedirectLayout = styled.div``;
const RedirectHeader = styled.div`
  padding: 16px 32px;
  border-bottom: 1px solid ${(p) => p.theme.token.colorBorder};
`;
const RedirectContent = styled.div`
  padding: 16px 32px;
`;

function QualysRedirect() {
  const { parsed: search } = useSearchParams();

  const { authState: oktaAuthState } = useOktaAuth();

  const [makeConnection] = useMutation(MAKE_CONNECTION_MUTATION);
  const [success, setSuccess] = useState<boolean>(false);

  if (!oktaAuthState?.isAuthenticated) {
    return <Navigate to="/log-in" replace />;
  }
  // render form

  return (
    <RedirectLayout>
      <RedirectHeader>
        <Typography.Title style={{ marginBottom: 0 }} level={2}>
          <NuspireIcon style={{ color: baseTheme.color.primaryBlue, marginRight: '8px' }} />
          Connect to Qualys Account
        </Typography.Title>
      </RedirectHeader>

      <RedirectContent>
        {!success ? (
          <QualysAuthForm
            onSubmit={async (values) => {
              const { testConnection, ...payload } = values;

              const { data, errors } = await makeConnection({
                variables: {
                  connectorSlug: connector.slug,
                  payloadJson: JSON.stringify(payload),
                  clientId: search?.clientId,
                  testConnection,
                },
              });

              if (errors?.length) {
                throw new Error(errors[0].message);
              }

              const newConnection = data?.makeConnection;

              if (newConnection && !errors) {
                const newEvent = new CustomEvent(`new-connection-${connector.slug}`, {
                  detail: {
                    connection: newConnection,
                  },
                });

                window?.opener?.dispatchEvent(newEvent);

                setSuccess(true);
              }
            }}
          />
        ) : (
          <Result status="success" title="Connection was successfully created!" />
        )}
      </RedirectContent>
    </RedirectLayout>
  );
}

export default QualysRedirect;
