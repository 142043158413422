import {
  DeleteOutlined,
  HistoryOutlined,
  HomeOutlined,
  PlusOutlined,
  SearchOutlined,
  StarOutlined,
} from '@ant-design/icons';
import { Button, Collapse, CollapseProps, List, Popconfirm, Space } from 'antd';
import { CSSProperties, ReactNode } from 'react';
import { Link, NavLink, useNavigate } from 'react-router';
import styled, { css } from 'styled-components';
import { useClientContext } from '../../../client-context-provider';
import { AddDashboardModal } from '../../../dashboard/dashboard-actions/add-dashboard-button';
import { DashboardsModal } from '../../../dashboard/dashboards-modal';
import { ExpandIcon } from '../../../nuspire/expand-icon';
import { DraggableItemWithHandle } from '../../../nuspire/sortable-list/draggable-item-with-handle';
import { SortableList, SortableListItemProps } from '../../../nuspire/sortable-list/sortable-list';
import { IDashboardMenuContext } from './dashboard-menu-context';

const FullWidthLink = styled(Link)`
  width: 100%;
`;

const DeleteButton = styled(Button).attrs({
  icon: <DeleteOutlined />,
  type: 'text',
  size: 'small',
})`
  color: ${(p) => p.theme.token.colorTextSecondary};
  &:hover {
    background-color: ${(p) => p.theme.token.colorBgTextHover};
  }
`;

const navItemMixin = css`
  && {
    padding: 4px 16px 4px 48px;
    position: relative;
    transition: background 0.3s ease;
    min-height: 40px;
    border: none;
    border-radius: 8px;
    transition: all 0.3s;
    margin-inline: 4px;
    margin-block: 4px;

    :first-child,
    :last-child {
      margin-block: 0;
    }
  }
  & a {
    color: ${(p) => p.theme.token.colorText};
  }
  &:hover {
    background-color: ${(p) => p.theme.token.colorBgTextHover};
  }
  &:active {
    background-color: ${(p) => p.theme.token.colorPrimaryBg};
  }
  &.is-current-page {
    background-color: ${(p) => p.theme.token.colorPrimaryBg};
    & a {
      color: ${(p) => p.theme.color.primary};
    }
  }
`;

const FavoriteNavItem = styled(DraggableItemWithHandle)`
  ${DeleteButton} {
    visibility: hidden;
    opacity: 0;
  }
  &:hover ${DeleteButton} {
    visibility: visible;
    opacity: 1;
  }
  ${navItemMixin};
`;

const RecentNavItem = styled(List.Item)`
  ${navItemMixin};
`;

export type DashboardNavData = {
  name: string;
  id: string;
};

export type LinkedDashboardNavData = DashboardNavData & {
  to: string;
};

export interface DashboardListViewProps {
  dashboards: LinkedDashboardNavData[];
  currentDashboardId?: string;
  loading?: boolean;
}

export interface FavoriteDashboardListViewProps extends DashboardListViewProps {
  onReordered?: (items: SortableListItemProps[]) => void;
  onRemove?: (id: string) => void;
}

function FavoriteDashboardListView(props: FavoriteDashboardListViewProps) {
  const { currentDashboardId, dashboards, loading, onReordered, onRemove } = props;

  const items = dashboards.map(({ name, id, to }, i): SortableListItemProps<DashboardNavData> => {
    const isCurrentPage = id === currentDashboardId;
    const isHomeDashboard = i === 0;

    return {
      data: { name, id },
      id,
      render: (renderProps, ref) => (
        <FavoriteNavItem className={isCurrentPage ? 'is-current-page' : ''} ref={ref} {...renderProps}>
          <FullWidthLink to={to}>
            <Space size={10}>
              {isHomeDashboard && <HomeOutlined />}
              {name}
            </Space>
          </FullWidthLink>
          <Popconfirm
            title={`Are you sure you want to remove ${name} from your favorites?`}
            placement="right"
            onConfirm={() => onRemove?.(id)}
          >
            <DeleteButton />
          </Popconfirm>
        </FavoriteNavItem>
      ),
    };
  });

  return <SortableList items={items} loading={loading} onReordered={onReordered} size="small" />;
}

export function RecentDashboardListView(props: DashboardListViewProps) {
  const { currentDashboardId, dashboards, loading } = props;

  const items = dashboards
    .map(({ name, id, to }) => {
      const isCurrentPage = id === currentDashboardId;

      return {
        key: id,
        name,
        to,
        isCurrentPage,
      };
    })
    .slice(0, 5)
    .sort((a, b) => a.name.localeCompare(b.name));

  if (items.length === 0) {
    return null;
  }

  return (
    <List
      loading={loading}
      dataSource={items}
      renderItem={({ name, to, isCurrentPage }) => (
        <RecentNavItem className={isCurrentPage ? 'is-current-page' : ''}>
          <FullWidthLink to={to}>{name}</FullWidthLink>
        </RecentNavItem>
      )}
    />
  );
}

const RootMenu = styled(Collapse).attrs({
  ghost: true,
  expandIconPosition: 'end',
  expandIcon: ExpandIcon,
})<{ $indent: number }>`
  &&& {
    .ant-collapse-content-box {
      padding-bottom: 0;
      padding-top: 0;
      padding: 1px 0;
    }

    .selected > .ant-collapse-header {
      color: ${(p) => p.theme.color.primary};
    }

    .ant-collapse-header {
      height: 40px;
      line-height: 40px;
      padding: 0 ${(p) => p.$indent}px;
      border-radius: 8px;
      margin-inline: 4px;
      margin-block: 4px;

      &:hover {
        background-color: ${(p) => p.theme.token.colorBgTextHover};
      }

      &:active {
        background-color: ${(p) => p.theme.token.colorPrimaryBg};
      }
    }
    .ant-collapse-content {
      background-color: ${(p) => p.theme.token.colorFillAlter};
      border-radius: 0;
    }
  }
`;

const NestedMenu = styled(RootMenu)`
  .ant-collapse-content-box {
    padding-bottom: 0;
    padding-top: 0;
    padding: 0;
  }
`;

interface DashboardModalsProps {
  searchModalOpen: boolean;
  newDashboardModalOpen: boolean;
  onSearchModalClose?: () => void;
  onNewDashboardModalClose?: () => void;
}

export function DashboardModals(props: DashboardModalsProps) {
  const {
    searchModalOpen,
    newDashboardModalOpen,
    onSearchModalClose = () => {},
    onNewDashboardModalClose = () => {},
  } = props;
  const { clientId } = useClientContext();
  const navigate = useNavigate();

  return (
    <>
      <DashboardsModal onClose={onSearchModalClose} visible={searchModalOpen} />
      <AddDashboardModal
        onNewDashboard={(newDashboard) => {
          navigate(`/${clientId}/dashboard/${newDashboard.id}`);
        }}
        clientId={clientId!}
        visible={newDashboardModalOpen}
        onClose={onNewDashboardModalClose}
      />
    </>
  );
}

interface DashboardMenuViewProps {
  clientId: string;
  context: IDashboardMenuContext;
  onSearchClick?: () => void;
  onNewDashboardClick?: () => void;
  onOpenChange?: (key: string[]) => void;
  openKeys?: string[];
  selected?: boolean;

  // @default - 24
  // to match antd's menu
  inlineIndent?: number;

  style?: CSSProperties;
}

export function DashboardMenuView(props: DashboardMenuViewProps) {
  const {
    clientId,
    context,
    onSearchClick,
    onNewDashboardClick,
    onOpenChange,
    openKeys,
    selected,
    inlineIndent = 24,
    style,
  } = props;
  const {
    currentDashboardId,
    recentDashboards,
    favoriteDashboards,
    loadingFavorites,
    loadingRecents,
    removeFavoriteDashboard,
    reorderFavoriteDashboards,
  } = context;

  const onChange: CollapseProps['onChange'] = (key) => {
    const keys = Array.isArray(key) ? key : [key];
    onOpenChange?.(keys);
  };

  type CollapseItems = NonNullable<CollapseProps['items']>;
  type PanelData = CollapseItems[number] & {
    icon?: ReactNode;
  };
  const panels: PanelData[] = [
    {
      key: 'dashboards|search',
      label: 'Search All',
      icon: <SearchOutlined />,
      onClick: onSearchClick,
      collapsible: 'icon',
      showArrow: false,
    },
    {
      key: 'dashboards|new',
      label: 'New Dashboard',
      icon: <PlusOutlined />,
      onClick: onNewDashboardClick,
      collapsible: 'icon',
      showArrow: false,
    },
    {
      key: 'dashboards|favorites',
      label: 'Favorites',
      icon: <StarOutlined />,
      children: (
        <FavoriteDashboardListView
          currentDashboardId={currentDashboardId}
          dashboards={favoriteDashboards}
          loading={loadingFavorites}
          onReordered={reorderFavoriteDashboards}
          onRemove={removeFavoriteDashboard}
        />
      ),
    },
    {
      key: 'dashboards|recent',
      label: 'Recently Viewed',
      icon: <HistoryOutlined />,
      children: (
        <RecentDashboardListView
          currentDashboardId={currentDashboardId}
          dashboards={recentDashboards}
          loading={loadingRecents}
        />
      ),
    },
  ];

  const nestedItems: CollapseItems = panels.map(({ icon, label, onClick, children, ...p }) => ({
    ...p,
    label: (
      <Space
        size={9}
        onClick={onClick}
        style={{
          width: '100%',
          cursor: onClick ? 'pointer' : '',
        }}
      >
        {icon && icon}
        {label}
      </Space>
    ),
    children,
  }));

  const items: CollapseItems = [
    {
      key: 'dashboards',
      label: (
        <Space size={9}>
          <HomeOutlined />
          <NavLink to={`/${clientId}/dashboard`} style={{ color: 'currentcolor' }}>
            Dashboards
          </NavLink>
        </Space>
      ),
      children: <NestedMenu onChange={onChange} $indent={inlineIndent * 2} activeKey={openKeys} items={nestedItems} />,
      className: selected ? 'selected' : undefined,
    },
  ];

  return <RootMenu onChange={onChange} activeKey={openKeys} $indent={inlineIndent} style={style} items={items} />;
}
