import { integrationsPath } from 'components/settings/paths';

/**
 * After merging all settings path management should happen in settings/paths.ts
 */
type ConnectionPathArgs = {
  clientId: string;
};

// path util funcs
export const connectionsPath = ({ clientId }: ConnectionPathArgs) => {
  return `${integrationsPath({ clientId })}`;
};

export const connectorsPath = (args: ConnectionPathArgs) => {
  return `${connectionsPath(args)}/connectors`;
};

export const connectorDetailPath = (args: ConnectionPathArgs & { connectorId: string }) =>
  `${connectorsPath(args)}/${args.connectorId}`;

export const connectionDetailPath = (args: ConnectionPathArgs & { connectorId: string; connectionId: string }) => {
  return `${connectorDetailPath(args)}/${args.connectionId}`;
};
