import { CloseOutlined, CloudServerOutlined, PicRightOutlined } from '@ant-design/icons';
import { gql, useQuery } from '@apollo/client';
import { App, Button, Dropdown, Typography } from 'antd';
import { ModalFunc } from 'antd/es/modal/confirm';
import {
  ClearFiltersButton,
  ComplexSearchRoot,
  ComplexSearchSelector,
  ComplexSearchSelectorOverflow,
} from 'components/complex-search';
import { Link, NuButton } from 'components/nuspire';
import Breadcrumb from 'components/nuspire/nu-breadcrumb';
import { InsightsIcon, Search as SearchIcon } from 'components/nuspire/nu-icon';
import PageHeader from 'components/nuspire/nu-page-header';
import { NuTimeRangeButton, RelativeRangeMenuItem } from 'components/nuspire/nu-time-range-button/nu-time-range-button';
import { checkTimeRangeSupportedMin, timeSyntaxToReadable } from 'components/nuspire/nu-time-range-button/time';
import { queryDataTypePath, reportingAndAnalysisOverviewPath } from 'components/reporting-and-analysis/paths';
import baseTheme from 'components/theme';
import debounce from 'lodash.debounce';
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { styled } from 'styled-components';
import {
  ActionFormFieldType,
  DataTypeFiltersAndColumnsQuery,
  DataTypeFiltersQuery,
} from 'types/graph-codegen/graph-types';
import { mixpanelTrack } from 'utils/mixpanel/mixpanel-track';
import useSearchParams, { SearchParamsInterface } from 'utils/react-hooks/useSearchParams';
import { useAuthContext } from '../auth-context';
import { useClientContext } from '../client-context-provider';
import { createDebugRequestId, useDebugRequests } from '../debug';
import { Content } from '../layouts/content';
import { DataExplorerFilters, useDataExplorerFilters } from './data-explorer-filters';
import { DataExplorerResults } from './data-explorer-results';
import { DataTypeSelect } from './data-type-select';
import { ExportAsReportButton } from './export-as-report';
import { FilterDropdown } from './filter-dropdown';
import { DataExplorerFilterTags } from './filter-tags';
import { DATA_TYPE_FILTERS_QUERY } from './graphql';
import { SearchButton } from './search-button';
import { Input } from './search-input';

const DATA_TYPE_DEFINITIONS = gql`
  query DataTypes($clientId: String) {
    dataTypes(clientId: $clientId) {
      id
      description
      name
      isSupported
    }
  }
`;

export const DATA_TYPE_FILTERS = gql`
  query DataTypeFilters($dataTypeSlug: String!, $clientId: String) {
    dataType(slug: $dataTypeSlug) {
      id

      # does this data type support text search?
      supportSearch

      # Support Date Range?
      supportsDateRange

      # If present check time selected to make sure user isn't going back too far.
      minTime

      columns(clientId: $clientId) {
        key
        title
        sorter
        defaultSortOrder
      }

      # filterInputs {
      filterInputs {
        key
        label
        type
        jsonSchema
        required
        editable
        inputType
        parameters
        inputType
        hasGetOptions
        defaultString
        filterInputOperators
      }
    }
  }
`;

export const SearchInputRoot = styled.div`
  input {
    cursor: auto;
    margin: 0;
    padding: 0;
    background: 0 0;
    border: none;
    outline: none;
  }

  input:focus {
    border: none;
    box-shadow: none;
    outline: none;
  }
`;

const FullSizeContainer = styled.div`
  height: calc(100%);
  /* width: calc(100vw); */
  overflow: auto; // Adds scrollbars if content exceeds container size
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 16px;
  width: 100%;
`;

const { Group: ButtonGroup } = Button;

const ButtonRow = styled(Row)`
  display: flex;
  justify-content: space-between;
`;

export function ComplexSearchFilterButton(props: { onClick: () => void }) {
  const { onClick } = props;
  return (
    <NuButton
      type="text"
      style={{ marginRight: '4px', color: baseTheme.color.riverBlue }}
      onClick={onClick}
      icon={<SearchIcon />}
    >
      Filters
    </NuButton>
  );
}

export type DataType = NonNullable<DataTypeFiltersQuery['dataType']>;
export type DataTypeInput = NonNullable<DataType['filterInputs']>; // this is what defines the inputs (i.e., type, label, required, etc)

export type IInput = {
  __typename?: 'ActionFormField';
  defaultString?: string;
  editable?: boolean | undefined;
  hasGetOptions?: boolean | undefined;
  inputType?: string | undefined;
  jsonSchema?: Record<string, any> | undefined;
  key: string;
  label: string | JSX.Element;
  parameters?: Record<string, any> | undefined;
  required?: boolean | undefined;
  type: ActionFormFieldType;
};

export type DataTypeDefinition = {
  id: string; // is this the slug?
  description?: string;
  name: string;
  isSupported?: boolean;
};

export const SEARCH = 'search';
export const TIME = 'time';

// 'now-30d' => "30 Days from now."

function buildUnsupportedMinTimeRangeMessage(args: { minTime: string }) {
  const { minTime } = args;
  const parsedFromNow = timeSyntaxToReadable(minTime);
  const message = `The dates queried are older than the supported retention policy of ${parsedFromNow}`;
  return (
    <>
      <Typography.Paragraph>{message}</Typography.Paragraph>
      <Typography.Paragraph>
        {
          'We suggest that you choose dates within the supported range, or acknowledge that the results may no longer be available.'
        }
      </Typography.Paragraph>
    </>
  );
}

function warnUserMinTime(args: {
  time?: string;
  minTime?: string;
  modal: Pick<
    Record<'info' | 'success' | 'error' | 'warn' | 'warning' | 'confirm', ModalFunc>,
    'info' | 'success' | 'error' | 'warning' | 'confirm'
  >;
}) {
  const { time, minTime, modal } = args;
  if (!time || !minTime) {
    return;
  }

  const isSupported = checkTimeRangeSupportedMin(time, minTime);

  if (!isSupported) {
    const unsupportedMessage = buildUnsupportedMinTimeRangeMessage({ minTime });

    modal.warning({
      title: 'Unsupported Time Range Selected',
      content: unsupportedMessage,
    });
  }
}

export type SearchFunction = () => void;
export interface IDataExplorerContext {
  dataTypeSlug?: string;
  dataType?: DataType;
  dataTypeDefinition?: DataTypeDefinition; // really do not understand why this isn't just DataType
  searchParamsInterface?: SearchParamsInterface;
  dataExplorerFilters: DataExplorerFilters;
  loading: boolean;
  setLoading: (isLoading: boolean) => void;
  searchFunction: SearchFunction | null;
  setSearchFunction: (func: SearchFunction | null) => void;
  setFilterDropdownVisible: (isVisible: boolean) => void;
}

const DataExplorerContext = createContext<IDataExplorerContext | undefined>(undefined);
export const useDataExplorerContext = () => useContext(DataExplorerContext);

export function DataExplorerPage() {
  const { clientId } = useClientContext();
  const { user } = useAuthContext();
  const { addRequestId, canDebugRequests, hasRequestId, removeRequestId } = useDebugRequests();
  const { dataType: dataTypeSlug } = useParams<{ dataType: string }>();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const searchParamsInterface = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchFunction, setSearchFunction] = useState<SearchFunction | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [filterDropdownVisible, setFilterDropdownVisible] = useState<boolean>(false);
  const handleOpenSearch = () => setFilterDropdownVisible(true);
  const handleCloseSearch = () => setFilterDropdownVisible(false);

  const debugRequestId = useMemo(() => {
    if (!canDebugRequests) {
      return undefined;
    }

    return createDebugRequestId({ suffix: `data-explorer:${dataTypeSlug}`, user });
  }, [canDebugRequests, dataTypeSlug, user]);

  const searchParamsApi = useSearchParams();

  const { parsed: params, setParameter, clearAll, setParameters } = searchParamsApi;
  const handleClearFilters = () => clearAll();
  const handleAddFilter = debounce((key: string, value: string) => {
    setParameter(key, value);
  }, 250);
  const handleRemoveFilter = (key: string) => setParameter(key, null);
  const handleDataTypeChange = (label: string, slug: string) => {
    return navigate(queryDataTypePath({ clientId: clientId ?? '', dataType: slug }));
  };

  const onClick = ({ target }) => {
    if (target !== inputRef.current) {
      const isIE = (document.body.style as any).msTouchAction !== undefined;
      if (isIE) {
        setTimeout(() => {
          inputRef?.current?.focus();
        });
      } else {
        inputRef?.current?.focus();
      }
    }
  };

  const { data: dataForDataTypeDefinitions } = useQuery(DATA_TYPE_DEFINITIONS, {
    variables: { clientId },
  });

  const dataTypeDefinitions = dataForDataTypeDefinitions?.dataTypes ?? [];
  const dataTypeDefinition = dataTypeDefinitions.find((def) => def.id === dataTypeSlug);

  /**
   * Fetch Data type by slug and it's filter Inputs.
   */
  const { data } = useQuery<DataTypeFiltersAndColumnsQuery>(DATA_TYPE_FILTERS_QUERY, {
    variables: { dataTypeSlug, clientId },
    skip: !dataTypeSlug,
  });

  const dataType = data?.dataType ?? undefined;
  const minTime = dataType?.minTime;

  const dataExplorerFilters = useDataExplorerFilters({
    filterInputs: dataType?.filterInputs,
  });

  /**
   * data explorer context we pass through a provider to child content.
   */
  const dataExplorerContext: IDataExplorerContext = {
    dataTypeSlug,
    dataType,
    dataTypeDefinition,
    searchParamsInterface,
    dataExplorerFilters,
    loading,
    setLoading,
    searchFunction,
    setSearchFunction,
    setFilterDropdownVisible,
  };

  /**
   * Once on load of data type
   * check to see if any inputs have default values.
   * for any inputs that have defaults we set the search params with these values.
   * - should check to make sure this doesn't overwrite existing values.
   */
  useEffect(() => {
    if (!dataType) {
      return;
    }

    const defaults = dataType?.filterInputs?.reduce((acc: { name: string; value: any }[], i) => {
      if (i.defaultString) {
        acc.push({
          name: i.key,
          value: JSON.parse(i.defaultString),
        });
      }

      return acc;
    }, []);

    if (defaults?.length) {
      setParameters(defaults);
    }

    // store the data type filters in the context so that we can access them in the search button, or pass them in as props
  }, [dataType]); // set search parameters whenever a data type changes

  useEffect(() => {
    if (dataType) {
      mixpanelTrack('data-explorer-type-viewed', { dataType });
    }
  }, [dataType]);

  const [searchValue, setSearchValue] = useState<string>('');
  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const {
      target: { value },
    } = event;

    setSearchValue(value);
  };

  const updateSearchTag = () => {
    if (searchValue.length) {
      setParameter(SEARCH, searchValue);
      setSearchValue('');
    }
  };

  const deleteLastTag = () => {
    if (params[SEARCH]?.length) {
      // remove search tag.
      setParameter(SEARCH, null);
    } else if (dataType) {
      const tags = dataType.filterInputs?.filter((f) => params[f.key]?.length) ?? [];
      const lastTag = tags[tags.length - 1];

      if (lastTag) {
        setParameter(lastTag.key, null);
      }
    }
  };

  const supportsDateRange = dataType?.supportsDateRange;
  const time = params[TIME];
  const { modal } = App.useApp();

  const handleTime = (args) => {
    const { time } = args;

    // Check to see if data type has a minTime set
    warnUserMinTime({ time, minTime, modal });

    setParameter(TIME, time);
  };

  useEffect(() => {
    warnUserMinTime({ time, minTime, modal });

    if (supportsDateRange && !time) {
      // set default time range
      setParameter(TIME, 'from:now-30d,to:now');
    }
  }, [dataType]);

  const handleInputKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    const { key } = event;

    if (key === 'Enter') {
      updateSearchTag();
    }

    if (key === 'Backspace' && searchValue.length === 0) {
      deleteLastTag();
    }
  };

  const inputNode = dataType?.supportSearch ? (
    <SearchInputRoot>
      <Input ref={inputRef} value={searchValue} onChange={onInputChange} onKeyDown={handleInputKeyDown} />
    </SearchInputRoot>
  ) : null;

  const breadcrumb = (
    <Breadcrumb
      target=""
      items={[
        {
          key: 'reporting-analysis',
          title: (
            <Link to={reportingAndAnalysisOverviewPath({ clientId: clientId ?? '' })}>{'Reporting & Analysis'}</Link>
          ),
        },
        {
          key: 'data-explorer',
          title: 'Data Explorer',
        },
      ]}
    />
  );

  const buildDateTimeOptions = (): RelativeRangeMenuItem[] => {
    return [
      {
        key: 'from:now-15m,to:now',
        label: 'Last 15 Minutes',
      },
      {
        key: 'from:now-1h,to:now',
        label: 'Last Hour',
      },
      {
        key: 'from:now-24h,to:now',
        label: 'Last 24 Hours',
      },
      {
        key: 'from:now-7d,to:now',
        label: 'Last 7 Days',
      },
      {
        key: 'from:now-30d,to:now',
        label: 'Last 30 Days',
      },
    ];
  };

  return (
    <Content>
      <FullSizeContainer>
        <DataExplorerContext.Provider value={dataExplorerContext}>
          {breadcrumb}
          <PageHeader title="Data Explorer" backIcon={<InsightsIcon />} />

          <ButtonRow>
            <ButtonGroup>
              <DataTypeSelect
                selectedDataTypeSlug={dataTypeSlug}
                dataTypeDefinitions={dataTypeDefinitions}
                onDataTypeChange={handleDataTypeChange}
              />
              <NuTimeRangeButton
                time={time || 'from:now-30d,to:now'}
                onChange={handleTime}
                relativeOptions={buildDateTimeOptions()}
                disabled={!supportsDateRange}
              />
            </ButtonGroup>
            <ButtonGroup>
              <SearchButton
                onClick={() => {
                  if (isDrawerOpen) {
                    setIsDrawerOpen(false);
                  }
                }}
              />
              <ExportAsReportButton dataTypeSlug={dataTypeSlug} />
              <NuButton
                type="default"
                onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                size="large"
                icon={<PicRightOutlined />}
                disabled={!searchFunction || loading}
              >
                Widgets
              </NuButton>
              {canDebugRequests && !!debugRequestId && (
                <NuButton
                  style={{ width: 165 }}
                  type="default"
                  onClick={() => {
                    if (hasRequestId(debugRequestId)) {
                      removeRequestId(debugRequestId);
                      return;
                    }

                    addRequestId(debugRequestId);
                  }}
                  size="large"
                  icon={hasRequestId(debugRequestId) ? <CloseOutlined /> : <CloudServerOutlined />}
                  disabled={!debugRequestId || !searchFunction || loading}
                >
                  {hasRequestId(debugRequestId) ? 'Remove debug' : 'Debug query'}
                </NuButton>
              )}
            </ButtonGroup>
          </ButtonRow>

          <Row>
            <ComplexSearchRoot className="complex-search">
              <Dropdown
                trigger={['click']}
                open={filterDropdownVisible}
                menu={{
                  items: [
                    {
                      key: '1',
                      label: (
                        <FilterDropdown
                          onClick={(e) => e.stopPropagation()}
                          dataType={dataType}
                          handleAddFilter={handleAddFilter}
                          handleRemoveFilter={handleRemoveFilter}
                          dataExplorerFilters={dataExplorerFilters}
                        />
                      ),
                    },
                  ],
                }}
                onOpenChange={(open) => {
                  if (!open) {
                    handleCloseSearch();
                  }
                }}
              >
                <ComplexSearchSelector onClick={onClick}>
                  <ComplexSearchSelectorOverflow>
                    <ComplexSearchFilterButton onClick={handleOpenSearch} />
                    <DataExplorerFilterTags dataExplorerFilters={dataExplorerFilters} />
                    {inputNode}
                  </ComplexSearchSelectorOverflow>
                  <ClearFiltersButton onClick={handleClearFilters} />
                </ComplexSearchSelector>
              </Dropdown>
            </ComplexSearchRoot>
          </Row>

          <DataExplorerResults
            dataTypeSlug={dataTypeSlug}
            debugRequestId={debugRequestId}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            dataExplorerContext={dataExplorerContext}
          />
        </DataExplorerContext.Provider>
      </FullSizeContainer>
    </Content>
  );
}
