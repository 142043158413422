import { gql } from '@apollo/client';
import { Table, Badge, Dropdown, Space, Typography } from 'antd';
import { FilterDropdown, FilterTags } from 'components/case-management/tasks/task-list';
import { ClearFiltersButton, ComplexSearchSelector, ComplexSearchSelectorOverflow } from 'components/complex-search';
import { ComplexSearchFilterButton, IInput, SearchInputRoot } from 'components/data-explorer/data-explorer';
import { Input } from 'components/data-explorer/search-input';
import { Link, NuButton, Spacer } from 'components/nuspire';
import {
  Automation as AutomationIcon,
  SentryGlyph,
  Time as TimeIcon,
  User as UserIcon,
} from 'components/nuspire/nu-icon';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { TaskLogSearchQuery as QueryType, TaskStatus } from 'types/graph-codegen/graph-types';
import { client } from 'utils/graphql';
import { SearchParamsInterface } from 'utils/react-hooks/useSearchParams';
import { TaskStatusBadge } from './task-log-detail';

dayjs.extend(relativeTime);

const TaskLogControlsRoot = styled.div`
  display: flex;
`;
const ComplexSearchRoot = styled.div`
  flex: 1;
  margin-right: 24px;
`;

export enum SearchParam {
  STATE = 'state',
  STATUS = 'status',
  ACTION = 'action',
  CLIENT_ID = 'client_id',
  TASK_ID = 'task_id',
}

export const SEARCH = 'search';

function getInputs(status?: TaskStatus) {
  const inputs: IInput[] = [];

  if (!status) {
    inputs.push({
      key: SearchParam.STATUS,
      label: 'Status',
      type: 'array',
      inputType: 'select',
      defaultString: 'failed',
      parameters: {
        placeholder: 'Select Status',
        // mode: ''
        options: [
          {
            key: 'failed',
            label: 'Error',
            value: 'failed',
          },
          {
            key: 'success',
            label: 'Success',
            value: 'success',
          },
        ],
      },
    });
  }

  inputs.push(
    {
      key: SearchParam.ACTION,
      label: 'Action',
      type: 'string',
    },
    {
      key: SearchParam.CLIENT_ID,
      label: 'Client ID',
      type: 'string',
    },
    {
      key: SearchParam.TASK_ID,
      label: 'Task ID',
      type: 'string',
    },
  );

  return inputs;
}

export function TaskLogSearchControls(props: {
  searchParams: SearchParamsInterface;
  status?: TaskStatus;
  inputs: IInput[];
}) {
  const { searchParams, inputs } = props;

  // ========== Refs ===========
  const inputRef = useRef<HTMLInputElement>(null);

  const onClick = ({ target }) => {
    if (target !== inputRef.current) {
      const isIE = (document.body.style as any).msTouchAction !== undefined;
      if (isIE) {
        setTimeout(() => {
          inputRef?.current?.focus();
        });
      } else {
        inputRef?.current?.focus();
      }
    }
  };

  // ========== State ===========
  const [filterDropdownVisible, setFilterDropdownVisible] = useState<boolean>(false);
  const handleOpenSearch = () => setFilterDropdownVisible(true);
  const handleCloseSearch = () => {
    setFilterDropdownVisible(false);
  };

  // ============ Filters ===========
  const { parsed: params, setParameter, clearAll } = searchParams;
  const handleClearFilters = () => {
    clearAll();
  };

  const handleAddFilter = (key: string, value: string) => {
    setParameter(key, value);
  };
  const handleRemoveFilter = (key: string) => {
    setParameter(key, null);
  };

  // ========== Search Input =========
  const [searchValue, setSearchValue] = useState<string>('');

  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const {
      target: { value },
    } = event;

    setSearchValue(value);
  };

  const updateSearchTag = () => {
    if (searchValue.length) {
      setParameter(SEARCH, searchValue);
      setSearchValue('');
    }
  };

  const deleteLastTag = () => {
    if (params[SEARCH]?.length) {
      // remove search tag.
      setParameter(SEARCH, null);
    } else {
      const tags = inputs?.filter((f) => params[f.key]?.length) ?? [];
      const lastTag = tags[tags.length - 1];

      if (lastTag) {
        setParameter(lastTag.key, null);
      }
    }
  };

  // ========== Input Node ==========
  const handleInputKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    const { key } = event;

    if (key === 'Enter') {
      updateSearchTag();
    }

    if (key === 'Backspace' && searchValue.length === 0) {
      deleteLastTag();
    }
  };
  const inputNode = (
    <SearchInputRoot>
      <Input ref={inputRef} value={searchValue} onChange={onInputChange} onKeyDown={handleInputKeyDown} />
    </SearchInputRoot>
  );

  return (
    <TaskLogControlsRoot className="complex-search">
      <ComplexSearchRoot>
        <Dropdown
          trigger={['click']}
          open={filterDropdownVisible}
          overlay={
            <FilterDropdown
              filterInputs={inputs}
              handleAddFilter={handleAddFilter}
              handleRemoveFilter={handleRemoveFilter}
            />
          }
          onOpenChange={(open) => {
            if (!open) {
              handleCloseSearch();
            }
          }}
        >
          <ComplexSearchSelector onClick={onClick}>
            <ComplexSearchSelectorOverflow>
              <ComplexSearchFilterButton onClick={handleOpenSearch} />

              <FilterTags filterInputs={inputs} />

              {inputNode}
            </ComplexSearchSelectorOverflow>
            <ClearFiltersButton onClick={handleClearFilters} />
          </ComplexSearchSelector>
        </Dropdown>
      </ComplexSearchRoot>
    </TaskLogControlsRoot>
  );
}

// we need to be able to filter by task type...
const TASK_LOG_SEARCH = gql`
  query TaskLogSearch(
    $clientId: String
    $status: TaskStatus
    $taskId: String
    $executableSlug: String
    $queryString: String
  ) {
    taskLogs(
      clientId: $clientId
      status: $status
      taskId: $taskId
      executableSlug: $executableSlug
      queryString: $queryString
    ) {
      next
      total
      items {
        id
        summary
        taskId
        error
        errorStack
        status
        startedAt
        client {
          id
          name
        }

        # Sentry
        sentryEvent {
          orgSlug
          projectSlug
          eventId
        }

        # Scheduled Task
        task {
          id
          executableSlug
          destination
        }

        # Results
        taskResult {
          id
          taskStatus
        }
      }
    }
  }
`;

const LogErrorColumnRoot = styled.div`
  display: flex;
`;

function truncateString(str: string, maxLength: number) {
  if (str.length <= maxLength) {
    return str;
  }

  return `${str.substring(0, maxLength)}...`;
}

function buildTitle(args: { log: Log }) {
  const {
    log: { summary, error },
  } = args;

  return (
    <>
      {summary && (
        <>
          {truncateString(summary, 140)}
          <br />
        </>
      )}

      {error && `Error: ${truncateString(error, 140)}`}
    </>
  );
}

const LogMetaItemRoot = styled.div`
  display: flex;
`;
const LogMetaItemIcon = styled.div`
  margin-right: 6px;
`;
function LogMetaItem(props: { label: ReactNode; icon?: ReactNode }) {
  const { label, icon } = props;

  return (
    <LogMetaItemRoot>
      {icon && <LogMetaItemIcon>{icon}</LogMetaItemIcon>}
      <Typography.Text type="secondary">{label}</Typography.Text>
    </LogMetaItemRoot>
  );
}

export function LogErrorColumn(props: { log: Log; listPath?: string }) {
  const {
    log: { id, startedAt, client, task, status },
    listPath,
  } = props;
  const title = buildTitle(props);
  const fromNow = startedAt ? dayjs(startedAt).fromNow() : '--';

  const to = `${listPath ?? '/admin/task-handler/errors'}/${id}`;

  return (
    <LogErrorColumnRoot>
      <Space direction="vertical">
        <Link to={to}>{title}</Link>

        <Space direction="horizontal" size="large">
          <TaskStatusBadge status={status} />
          <LogMetaItem label={fromNow} icon={<TimeIcon />} />
          <LogMetaItem label={client?.name ?? '--'} icon={<UserIcon />} />
          <LogMetaItem label={task?.executableSlug ?? '--'} icon={<AutomationIcon />} />
          <LogMetaItem label={task?.destination ?? '--'} />
        </Space>
      </Space>
    </LogErrorColumnRoot>
  );
}

// export type Log = ReturnType<NonNullable<QueryType['taskLogs']>>
type NonNullableTaskLogs = NonNullable<QueryType['taskLogs']>;
type Unpacked<T> = T extends (infer U)[] ? U : T;
type Log = Unpacked<NonNullableTaskLogs['items']>;
type SentryEvent = NonNullable<Log['sentryEvent']>;

function buildSentryEventLink({ orgSlug, projectSlug, eventId }: SentryEvent) {
  return `https://sentry.io/${orgSlug}/${projectSlug}/events/${eventId}/`;
}

export function SentryEventLinkButton({ sentryEvent }: { sentryEvent?: SentryEvent }) {
  if (!sentryEvent) {
    return null;
  }

  return (
    <a target="_blank" href={buildSentryEventLink(sentryEvent)}>
      <NuButton type="link" icon={<SentryGlyph style={{ fontSize: '18px' }} />}>
        View in Sentry
      </NuButton>
    </a>
  );
}

export function TaskLogSearchQuery(props: { searchParams: SearchParamsInterface; status?: string; listPath?: string }) {
  const {
    listPath,
    searchParams: { parsed, initializing },
  } = props;

  // ========== State ==========
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [next, setNext] = useState<string | undefined>(undefined);

  const clientId = parsed[SearchParam.CLIENT_ID];
  let status = props?.status ?? parsed[SearchParam.STATUS];
  if (status) {
    try {
      const parsedStatus = JSON.parse(status);

      status = parsedStatus;
    } catch (error) {
      console.warn('failed to parse status');
    }
  }
  const taskId = parsed[SearchParam.TASK_ID];
  const executableSlug = parsed[SearchParam.ACTION];
  const queryString = parsed[SEARCH];
  const variables = { size: 25, clientId, status, taskId, executableSlug, queryString };

  // query data
  const fetchData = async (args?: { newSearch?: boolean }) => {
    if (initializing) {
      return;
    }

    setLoading(true);

    const { data } = await client.query<QueryType>({
      query: TASK_LOG_SEARCH,
      variables,
      fetchPolicy: 'network-only',
    });

    const items = data?.taskLogs?.items;
    const nextIdx = data?.taskLogs?.next;
    const total = data?.taskLogs?.total;

    if (items) {
      let newItems = args?.newSearch ? [...items] : [...dataSource, ...items];

      setDataSource(newItems);

      if (total && nextIdx && total > newItems.length) {
        setNext(nextIdx);
      } else {
        setNext(undefined);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData({ newSearch: true });
  }, [initializing, JSON.stringify(variables)]);

  return (
    <Table
      loading={loading}
      dataSource={dataSource}
      // lastId={dataSource[dataSource.length - 1]?.id}
      // debug
      columns={[
        {
          title: 'Log',
          render: (errorLog) => {
            return <LogErrorColumn log={errorLog} listPath={listPath} />;
          },
        },
        {
          title: 'Task Status',
          width: '100px',
          render: ({ taskResult }: Log) => {
            const status = taskResult?.taskStatus ?? 'unknown';

            return (
              <Badge
                text={status}
                status={(() => {
                  if (status === 'success') return 'success';

                  if (status === 'failed') return 'error';

                  if (status === 'rescheduled') return 'warning';

                  if (status === 'running') return 'processing';

                  return 'default';
                })()}
              />
            );
          },
        },

        {
          render: ({ sentryEvent }: Log) => {
            if (!sentryEvent) {
              return null;
            }

            return <SentryEventLinkButton sentryEvent={sentryEvent} />;
          },
        },
      ]}
      pagination={false}
    />
  );
}

export function TaskLogList(props: { searchParams: SearchParamsInterface; status?: TaskStatus; listPath?: string }) {
  return (
    <>
      <TaskLogSearchControls {...props} inputs={getInputs(props?.status)} />
      <Spacer />
      <TaskLogSearchQuery {...props} />
    </>
  );
}
