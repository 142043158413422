import { ExportOutlined, FileExcelOutlined } from '@ant-design/icons';
import { gql, useLazyQuery } from '@apollo/client';
import { Checkbox, Empty, Input, Table, Tag, message } from 'antd';
import { useClientContext } from 'components/client-context-provider';
import { Industry } from 'components/industries';
import { Link, NuButton, Spacer } from 'components/nuspire';
import * as NuIcon from 'components/nuspire/nu-icon';
import { CopyToClipboardIcon } from 'components/shared-components';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router';
import styled from 'styled-components';
import { GetClientsForAdminQuery, GetClientsForAdminQueryVariables } from 'types/graph-codegen/graph-types';
import { useClientIdentifier } from 'utils/react-hooks/use-client-identifier';
import { useForceRerender } from 'utils/react-hooks/use-force-rerender';
import useSearchParams from 'utils/react-hooks/useSearchParams';
import { config } from '../../../config';
import { Access, IClient } from '../../../types';
import { BreadcrumbBar } from '../../bread-crumb-bar';
import { Content } from '../../layouts/content';
import { FirstColumn } from '../users/users';
import ClientActionButtons from './client-action-buttons';
import ClientContextButton from './client-context-button';
import { runExportClientsAndDownload } from './export-clients';

const ActionRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActionRowColumn = styled.div``;

const GET_CLIENTS = gql`
  query GetClientsForAdmin($search: String, $showDeleted: Boolean, $options: PaginationOptionsInput) {
    allClients(search: $search, showDeleted: $showDeleted, options: $options) {
      items {
        id
        name
        clientIdentifiers {
          type
          value
        }
        industry {
          id
          name
        }
        owner {
          id
          email
        }
        deletedAt
        stats {
          userCount
        }
      }
      count
      nextKey
    }
  }
`;

export const TableScroll = styled.div`
  overflow-y: auto;
`;

const ExternalLink = styled.a`
  color: ${(p) => p.theme.token.colorLink};
  &:hover {
    color: ${(p) => p.theme.token.colorLinkHover};
  }
`;

export type GraphClient = GetClientsForAdminQuery['allClients']['items'][number];

type ClientsTableProps = {
  actionsColumnRender: (client: IClient, onActionSuccess: (data?: any) => void) => JSX.Element | null;
  currentPage: number;
  dataSource?: GraphClient[];
  emptyDescription: JSX.Element | string;
  loading: boolean;
  onActionSuccess: () => void;
  onPageChange: (newPage: number) => void;
  pageSize: number;
  searchValue: string;
  total: number;
};

function ClientsTable(props: ClientsTableProps) {
  const {
    actionsColumnRender,
    currentPage,
    dataSource,
    emptyDescription,
    loading,
    onActionSuccess,
    onPageChange,
    pageSize,
    searchValue,
    total,
  } = props;
  const { paginationDefaults } = config;

  return (
    <Table
      loading={loading}
      locale={{
        emptyText: () => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyDescription} />,
      }}
      columns={[
        {
          key: 'view',
          dataIndex: 'id',
          render: (clientId: string) => {
            return (
              <FirstColumn>
                <ClientContextButton clientId={clientId} buttonType="link" buttonSize="small" />
                <CopyToClipboardIcon altTooltip="Copy ID to Clipboard" copyText={clientId} />
              </FirstColumn>
            );
          },
          width: '35px',
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          render: (clientName: string, item) => {
            return (
              <>
                <Link to={`/admin/clients/${item.id}`} state={{ search: searchValue }} style={{ display: 'block' }}>
                  {clientName}
                </Link>
                {item.deletedAt && <Tag color="red">Deleted</Tag>}
              </>
            );
          },
          sortOrder: 'ascend',
        },
        {
          title: 'Owner',
          dataIndex: 'id',
          key: 'id',
          render: (_id, client) => {
            return <Link to={`/admin/users/${client.owner?.id}`}>{client?.owner?.email}</Link>;
          },
        },
        {
          title: 'Industry',
          dataIndex: 'industry',
          render: (industry?: Industry) => industry?.name ?? '--',
        },
        {
          title: 'Users',
          dataIndex: 'stats',
          render: (stats: any, client) => {
            return (
              <Link to={`/admin/clients/${client.id}`} state={{ search: searchValue }}>
                {stats?.userCount || 0}
              </Link>
            );
          },
        },
        {
          title: 'Client Identifiers',
          dataIndex: 'clientIdentifiers',
          key: 'clientIdentifiers',
          render: (_, client) => {
            // prioritize only the client identifiers that we most commonly use. 99% of the time, it's these two we're after.
            const fortisiemId = useClientIdentifier({
              clientIdentifiers: client.clientIdentifiers,
              type: 'fortisiemOrgId',
            });
            const snowId = useClientIdentifier({
              clientIdentifiers: client.clientIdentifiers,
              type: 'serviceNowAccountId',
            });

            return (
              <>
                {fortisiemId && (
                  <div key="fortisiemId">
                    FortiSIEM: {fortisiemId.value}
                    {fortisiemId.value && <CopyToClipboardIcon copyText={fortisiemId.value} />}
                  </div>
                )}

                {snowId && (
                  <div key="snowId">
                    ServiceNow:&nbsp;
                    <ExternalLink
                      href={`${config.serviceNowBaseUrl}/nav_to.do?uri=customer_account.do?sys_id=${snowId.value}%26sysparm_view=case`}
                      target="_blank"
                      rel="noreferrer"
                      style={{ marginRight: snowId.value ? '0.5rem' : undefined }}
                    >
                      {snowId.value}
                    </ExternalLink>
                    {snowId.value && <CopyToClipboardIcon copyText={snowId.value} />}
                  </div>
                )}
              </>
            );
          },
        },
        {
          title: 'Actions',
          dataIndex: '',
          key: 'actions',
          render: (client: IClient) => actionsColumnRender(client, onActionSuccess),
        },
      ]}
      dataSource={dataSource}
      rowKey="id"
      pagination={{
        current: currentPage,
        defaultCurrent: paginationDefaults.currentPage,
        defaultPageSize: paginationDefaults.pageSize,
        pageSize,
        showSizeChanger: false,
        showPrevNextJumpers: true,
        total,
      }}
      onChange={({ current: newCurrentPage }, _filters, _sorterResult, _extra) => {
        if (!newCurrentPage) return;

        onPageChange(newCurrentPage);
      }}
      size="middle"
      bordered
    />
  );
}

type ClientsTableWithSearchProps = {
  actionsColumnRender: (client: IClient, onActionSuccess: (data?: any) => void) => JSX.Element | null | null;
  disableHistoryPush?: boolean;
  extraActions?: JSX.Element;
  onActionSuccess?: (data?: any) => void;
};

export function ClientsTableWithSearch(props: ClientsTableWithSearchProps) {
  const { paginationDefaults } = config;
  const { actionsColumnRender, disableHistoryPush = false, extraActions } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { parsed } = useSearchParams();
  const search = parsed?.search ?? '';
  const [searchValue, setSearchValue] = useState(search);
  const [getAllClients, { data, error, loading }] = useLazyQuery<
    GetClientsForAdminQuery,
    GetClientsForAdminQueryVariables
  >(GET_CLIENTS);
  const [currentPage, setCurrentPage] = useState(paginationDefaults.currentPage);
  const [pages, _setPages] = useState(new Map<number, GraphClient[]>());
  const [showDeletedClients, setShowDeletedClients] = useState(false);
  const { pageSize } = paginationDefaults;
  const forceRerender = useForceRerender();

  useEffect(() => {
    if (error) {
      console.error(error);
      message.error('An error occurred while fetching clients.');
    }
  }, [error]);

  useEffect(() => {
    // Don't fire the search if there isn't any search value.
    if (!searchValue) return;

    getAllClients({
      variables: {
        search: searchValue,
        showDeleted: showDeletedClients,
        options: {
          pageSize,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (!data?.allClients?.items) return;

    const newPageData = data?.allClients?.items.map((i) => ({ ...i }));
    pages.set(currentPage, newPageData);
    // setPages(new Map<number, GraphClient[]>(pages.entries()));
    // forceRerender is more performant than above commented-out logic. react dumb sometimes 🤷‍♂️
    // react not detecting changes to map for some reason.
    forceRerender();
  }, [data?.allClients?.items]);

  const resetAndRefetchData = () => {
    pages.clear();
    setCurrentPage(1);
    getAllClients({
      variables: {
        options: {
          pageSize,
        },
        search: searchValue,
        showDeleted: showDeletedClients,
      },
    });
  };

  const handleChange = (e: any) => setSearchValue(e.target.value);
  const onSearch = () => {
    resetAndRefetchData();

    if (!disableHistoryPush) {
      navigate({
        pathname: location.pathname,
        search: `?search=${searchValue}`,
      });
    }
  };

  const clearSearch = () => {
    setSearchValue('');
    setShowDeletedClients(false);
    resetAndRefetchData();

    if (!disableHistoryPush) {
      navigate(location.pathname);
    }
  };

  const isSearchingClients: boolean = !disableHistoryPush
    ? search.trim() !== ''
    : searchValue?.trim() !== false && !!data?.allClients?.items;
  const createEmptyDescription = () => {
    if (!isSearchingClients) {
      return (
        <>
          <div>
            <Input
              type="text"
              placeholder="Search Clients"
              onPressEnter={onSearch}
              style={{ width: '300px' }}
              value={searchValue}
              onChange={handleChange}
              autoFocus
            />
          </div>
          <div>
            <NuButton type="primary" icon={<NuIcon.Search />} style={{ marginTop: '1rem' }} onClick={onSearch}>
              Search
            </NuButton>
          </div>
        </>
      );
    }

    if (loading) return 'Loading...';

    return 'No Clients found for current search.';
  };

  const defaultOnActionSuccess = () => {
    resetAndRefetchData();
    forceRerender();
  };

  const onActionSuccess = props.onActionSuccess ?? defaultOnActionSuccess;

  const EXPORT_CLIENTS_QUERY = gql`
    query GetExportClientsForAdmin($search: String, $showDeleted: Boolean, $options: PaginationOptionsInput) {
      allClients(search: $search, showDeleted: $showDeleted, options: $options) {
        items {
          id
          name
          type
          clientIdentifiers {
            type
            value
          }
          isMultiTenancyEnabled
          userCount: stats {
            userCount
          }
          path
        }
        count
        nextKey
      }
    }
  `;

  const [runExport, { loading: exportLoading }] = useLazyQuery(EXPORT_CLIENTS_QUERY);

  return (
    <>
      <ActionRow>
        <ActionRowColumn>
          {isSearchingClients && (
            <>
              <Input
                type="text"
                placeholder="Search Clients"
                onPressEnter={onSearch}
                style={{ width: '300px' }}
                value={searchValue}
                onChange={handleChange}
                autoFocus
              />
              <Checkbox
                style={{ marginLeft: '0.25rem' }}
                checked={showDeletedClients}
                onChange={(e) => setShowDeletedClients(e.target.checked)}
              >
                Show deleted clients
              </Checkbox>
              <NuButton type="primary" icon={<NuIcon.Search />} style={{ marginLeft: '18px' }} onClick={onSearch}>
                Search
              </NuButton>
              <NuButton type="default" style={{ marginLeft: '18px' }} onClick={clearSearch}>
                Clear
              </NuButton>
            </>
          )}
        </ActionRowColumn>
        <ActionRowColumn>
          {extraActions}
          <NuButton
            type="default"
            style={{ marginLeft: '18px' }}
            onClick={() => {
              runExportClientsAndDownload(runExport, searchValue, showDeletedClients);
            }}
            disabled={loading || exportLoading}
            loading={exportLoading}
            icon={<ExportOutlined />}
          >
            Export
          </NuButton>
        </ActionRowColumn>
      </ActionRow>
      <Spacer height="24px" />
      <TableScroll>
        <ClientsTable
          actionsColumnRender={actionsColumnRender}
          currentPage={currentPage}
          dataSource={pages.get(currentPage)}
          emptyDescription={createEmptyDescription()}
          loading={loading}
          pageSize={pageSize}
          searchValue={searchValue}
          total={[...pages.values()].flat().length + 1}
          onActionSuccess={onActionSuccess}
          onPageChange={(newPage) => {
            setCurrentPage(newPage);

            if (!pages.has(newPage)) {
              // Need to request new data.
              getAllClients({
                variables: {
                  options: {
                    nextKey: data?.allClients?.nextKey,
                    pageSize,
                  },
                  search: searchValue,
                  showDeleted: showDeletedClients,
                },
              });
            }
          }}
        />
      </TableScroll>
    </>
  );
}

export function AllClients() {
  const { authorize } = useClientContext();
  const { authorized: canImportClients } = authorize({ requiredPermissions: { allClients: Access.write } });

  const breadCrumbBar = (
    <BreadcrumbBar
      items={[
        { text: 'Home', to: '/' },
        { text: 'Admin', to: '/admin' },
        { text: 'Clients', to: '/admin/clients' },
      ]}
    />
  );

  return (
    <Content>
      <Helmet title="All Clients" />
      {breadCrumbBar}
      <ClientsTableWithSearch
        actionsColumnRender={(client, onActionSuccess) => (
          <ClientActionButtons client={client} onActionSuccess={onActionSuccess} />
        )}
        extraActions={
          <Link to="/admin/clients/import">
            <NuButton
              type="primary"
              style={{ cursor: !canImportClients ? 'not-allowed' : 'pointer' }}
              disabled={!canImportClients}
            >
              <FileExcelOutlined /> Import
            </NuButton>
          </Link>
        }
      />
    </Content>
  );
}

export default AllClients;
