import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Form as AntForm,
  Breadcrumb,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tabs,
  TabsProps,
  message,
} from 'antd';
import { useClientContext } from 'components/client-context-provider';
import { connectorDetailPath } from 'components/connections/connection-paths';
import { WidgetDefinition } from 'components/dashboard/widget-definitions/types';
import { NuButton } from 'components/nuspire';
import EmptyState from 'components/nuspire/nu-empty-state';
import PageHeader from 'components/nuspire/nu-page-header';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { integrationsPath, settingsPath } from 'components/settings/paths';
import { Formik, useField } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Link, Navigate, useLocation, useParams } from 'react-router';
import styled from 'styled-components';
import { useTimeMixpanelEvent } from 'utils/mixpanel/mixpanel-page-timer';
import useSearchParams from 'utils/react-hooks/useSearchParams';
import ConnectionWidget from '../../common/connection-widget';
import { CONNECTORS, IConnector, UpdatableField } from '../../connectors';
import { ClientConnection } from '../connector-detail';
import ConnectionSchedules from './connection-schedules';
import { ConnectionWorkflows } from './connection-workflows/connection-workflows';
import debounce from 'lodash.debounce';

const CcwRoot = styled.div``;

export const DASHBOARD_SELECT = gql`
  query DashboardSelect($clientId: String) {
    dashboards(clientId: $clientId) {
      id
      name
    }
  }
`;

function DashboardSelect(props: { name: string }) {
  const { name } = props;
  const [{ value }, { touched, error }, { setValue }] = useField({ name });
  const { clientId } = useClientContext();
  const { data, loading } = useQuery(DASHBOARD_SELECT, { variables: { clientId } });
  const dashboards = data?.dashboards;

  return (
    <AntForm.Item
      label="Dashboard"
      hasFeedback={Boolean(touched && error)}
      validateStatus={touched && error ? 'error' : undefined}
      status={touched && error ? 'error' : undefined}
      required
    >
      <Select
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        loading={loading}
        placeholder="Select Dashboard"
      >
        {dashboards &&
          dashboards.map((d: { id: string; name: string }) => (
            <Select.Option key={d.id} value={d.id}>
              {d.name}
            </Select.Option>
          ))}
      </Select>
    </AntForm.Item>
  );
}

function AddWidgetToDashboardModal(props: { widgetSlug: string; connectionId: string; onCloseModal: () => void }) {
  const { widgetSlug, connectionId, onCloseModal } = props;
  /**
   * Add widgets to a Dashboard.
   */
  const [addDashboardToWidget] = useMutation(ADD_WIDGET_TO_DASHBOARD);

  return (
    <Modal open title="Add Widget to Dashboard" footer={false} onCancel={onCloseModal}>
      <Formik
        initialValues={{ dashboardId: undefined }}
        onSubmit={async (values, helpers) => {
          const input = {
            dashboardId: values.dashboardId,
            widgetSlug,
            configuration: { connectionId },
          };

          try {
            const result = await addDashboardToWidget({ variables: { input } });
            const widget = result.data?.createDashboardWidget ?? null;

            if (!widget || result.errors) {
              throw new Error('Error Creating Dashboard Widget');
            }

            message.success('Widget has been added to the selected Dashboard.');

            helpers.setSubmitting(false);

            // close modal;
            onCloseModal();
          } catch (err) {
            message.error('Could not add widget to dashboard. Please try again.');
          }
        }}
      >
        {({ submitForm, isSubmitting, dirty, errors = {} }) => {
          return (
            <AntForm layout="vertical" onFinish={() => submitForm()}>
              <DashboardSelect name="dashboardId" />

              <AntForm.Item>
                <NuButton
                  htmlType="submit"
                  type="primary"
                  disabled={isSubmitting || !dirty || Boolean(Object.keys(errors).length)}
                  loading={isSubmitting}
                >
                  Add
                </NuButton>
              </AntForm.Item>
            </AntForm>
          );
        }}
      </Formik>
    </Modal>
  );
}

const ADD_WIDGET_TO_DASHBOARD = gql`
  mutation AddWidgetToDashboad($input: CreateDashboardWidgetInput!) {
    createDashboardWidget(input: $input) {
      id
      widgetSlug
      configuration
    }
  }
`;

function ClientConnectionWidgets(props: { widgets: WidgetDefinition[]; connectionId: string }) {
  const { widgets, connectionId } = props;
  const [widgetSlugToAdd, setWidgetSlugToAdd] = useState<string | null>(null);
  const handleAddWidgetToDashboard = (widgetSlug: string) => setWidgetSlugToAdd(widgetSlug);
  const handleCloseAddWidgetModal = () => setWidgetSlugToAdd(null);

  return (
    <CcwRoot>
      <Row gutter={24}>
        {widgets.map((w) => {
          return (
            <Col span={24} lg={12} xxl={8} key={w.slug}>
              <ConnectionWidget
                title={w.name}
                slug={w.slug}
                connectionId={connectionId}
                component={w.component}
                onAddWidgetToDashboard={handleAddWidgetToDashboard}
                onCloseAddWidgetModal={handleCloseAddWidgetModal}
              />
            </Col>
          );
        })}
      </Row>
      {widgetSlugToAdd && (
        <AddWidgetToDashboardModal
          widgetSlug={widgetSlugToAdd}
          connectionId={connectionId}
          onCloseModal={handleCloseAddWidgetModal}
        />
      )}
    </CcwRoot>
  );
}

export const UPDATE_CONNECTION = gql`
  mutation UpdateConnection($id: String!, $privateConnectionDataJson: String, $publicConnectionDataJson: String) {
    updateConnection(
      id: $id
      privateConnectionDataJson: $privateConnectionDataJson
      publicConnectionDataJson: $publicConnectionDataJson
    ) {
      id
      publicConnectionData
      publicConnectionDataJson
    }
  }
`;

function UpdatableCheckboxField(props: { field: UpdatableField; required: boolean }) {
  const { field, required } = props;
  const [checked, setChecked] = useState(false);

  return (
    <>
      <Form.Item
        name={field.value}
        key={field.key}
        rules={[{ required, message: required ? field.requiredMessage : undefined }]}
        valuePropName="checked"
      >
        <Checkbox onChange={(e) => setChecked(e.target.checked)}>{field.key}</Checkbox>
      </Form.Item>
      {checked && field.alertWhenChecked}
    </>
  );
}

type QualysPlatform = {
  key: string;
  identifier: string;
  apiServerUrl: string;
};

const QUALYS_PLATFORMS: QualysPlatform[] = [
  {
    key: 'US1',
    identifier: '_',
    apiServerUrl: 'https://qualysapi.qualys.com',
  },
  {
    key: 'US2',
    identifier: '2',
    apiServerUrl: 'https://qualysapi.qg2.apps.qualys.com',
  },
  {
    key: 'US3',
    identifier: '3',
    apiServerUrl: 'https://qualysapi.qg3.apps.qualys.com',
  },
  {
    key: 'US4',
    identifier: '6',
    apiServerUrl: 'https://qualysapi.qg4.apps.qualys.com',
  },
  {
    key: 'EU1',
    identifier: '-',
    apiServerUrl: 'https://qualysapi.qualys.eu',
  },
  {
    key: 'EU2',
    identifier: '5',
    apiServerUrl: 'https://qualysapi.qg2.apps.qualys.eu',
  },
  {
    key: 'EU3',
    identifier: 'B',
    apiServerUrl: 'https://qualysapi.qg3.apps.qualys.it',
  },
  {
    key: 'IN1',
    identifier: '8',
    apiServerUrl: 'https://qualysapi.qg1.apps.qualys.in',
  },
  {
    key: 'CA1',
    identifier: '9',
    apiServerUrl: 'https://qualysapi.qg1.apps.qualys.ca',
  },
  {
    key: 'AE1',
    identifier: '7',
    apiServerUrl: 'https://qualysapi.qg1.apps.qualys.ae',
  },
  {
    key: 'UK1',
    identifier: '1',
    apiServerUrl: 'https://qualysapi.qg1.apps.qualys.co.uk',
  },
  {
    key: 'AU1',
    identifier: '4',
    apiServerUrl: 'https://qualysapi.qg1.apps.qualys.com.au',
  },
  {
    key: 'KSA1',
    identifier: 'A',
    apiServerUrl: 'https://qualysapi.qg1.apps.qualysksa.com',
  },
];

// username pattern = (5 char client acryonym) + platform identifier + (2 char user initials) + optional user number.
const USERNAME_REGEX = /^(?<prefix>.{5})(?<identifier>.)(?<initials>[a-zA-Z]{2})(?<suffix>\d*)$/;

// Returns server url if valid username is entered.
// otherwise, simply returns undefined.
function getApiServerUrlFromUsername(username: string): string | undefined {
  const match = username.match(USERNAME_REGEX);

  // user may not have completed a full username.
  // nothing to do in this case.
  if (!match?.groups) return undefined;

  // normalize identifier to uppercase just in case.
  const identifier = match.groups.identifier.toUpperCase();

  return QUALYS_PLATFORMS.find((e) => e.identifier === identifier)?.apiServerUrl;
}

function QualysApiServerUrlSelect(props: {
  name: string;
  username: string;
  onChange: (val: string) => void;
  required?: boolean;
  label?: string;
  tooltip?: string;
}) {
  const { name, username, onChange, required = true, label = 'Qualys Api Server Url', tooltip } = props;

  // Wait until user stops typing to select new server URL.
  const debouncedAutoSelect = useCallback(
    debounce((username: string | undefined) => {
      if (!username?.length) return; // nothing to see here.

      const apiServerUrl = getApiServerUrlFromUsername(username);

      if (apiServerUrl) {
        // update form value.

        onChange(apiServerUrl);
      }
    }, 600),
    [], // no need to update this callback.
  );

  useEffect(() => {
    debouncedAutoSelect(username);

    // update server url value.
  }, [username]);

  return (
    <Form.Item
      // Refactor to pass args from field def.
      name={name}
      label={label}
      required={required}
      tooltip={tooltip}
      rules={required ? [{ required: true }] : undefined}
    >
      <Select
        options={QUALYS_PLATFORMS.map((e) => ({
          label: `(${e.key}) - ${e.apiServerUrl}`,
          value: e.apiServerUrl,
        }))}
      />
    </Form.Item>
  );
}

export function QualysApiServerUrlSmartSelect(props: {
  required?: boolean;
  name: string;
  label?: string;
  tooltip?: string;
}) {
  const { required, name, label, tooltip } = props;
  // This assumes that the input is being used alongside a field named "username".
  // nothing should break if it's not. but that's how the autocomplete works.
  return (
    <Form.Item noStyle shouldUpdate={(prev, curr) => prev.username !== curr.username}>
      {({ getFieldValue, setFieldValue }) => {
        // Specific to the Qualys Connection Form.
        const username = getFieldValue('username');

        const handleChange = (value: string) => {
          setFieldValue(name, value);
        };

        return (
          <QualysApiServerUrlSelect
            name={name}
            label={label}
            tooltip={tooltip}
            username={username}
            onChange={handleChange}
            required={required}
          />
        );
      }}
    </Form.Item>
  );
}

function ClientConnectionConfiguration(props: {
  connection: ClientConnection;
  updatableAuthFields: IConnector['updatableAuthFields'];
}) {
  const {
    connection: { id: connectionId },
    updatableAuthFields,
  } = props;
  const [form] = Form.useForm();
  const [updateConnection, { loading }] = useMutation(UPDATE_CONNECTION);

  if (!updatableAuthFields?.length) {
    return null;
  }

  return (
    <Form
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      onFinish={async (values) => {
        try {
          await updateConnection({
            variables: {
              id: connectionId,
              privateConnectionDataJson: JSON.stringify({ authData: values }),
            },
          });

          message.success(`Updated connection!`);
          form.resetFields();
        } catch (error) {
          console.error(error);
          message.error(`Failed to update connection.`);
        }
      }}
    >
      {updatableAuthFields.map((f) => {
        const { required = true } = f;

        if (f.inputType === 'checkbox') {
          return <UpdatableCheckboxField key={f.value} field={f} required={required} />;
        }

        // Handle Custom Inputs
        if (f.inputType === 'qualysApiServerUrl') {
          return <QualysApiServerUrlSmartSelect name={f.value} label={f.key} required={f.required} />;
        }

        return (
          <Form.Item
            name={f.value}
            label={f.key}
            key={f.value}
            rules={[{ required, message: required ? f.requiredMessage : undefined }, { type: f.inputType }]}
          >
            <Input type={f.inputType} />
          </Form.Item>
        );
      })}
      <NuButton type="primary" htmlType="submit" disabled={loading} loading={loading}>
        Update Connection
      </NuButton>
    </Form>
  );
}

enum TabKey {
  widgets = 'widgets',
  schedules = 'schedules',
  settings = 'settings',
  configuration = 'configuration',
  workflows = 'workflows',
}

function getDefaultTab(connection: ClientConnection, widgets?: WidgetDefinition[]): TabKey {
  const { systemConnector } = connection;

  const workflows = systemConnector?.dataSourceDefinitions;

  if (workflows?.length) {
    return TabKey.workflows;
  }

  const { publicConnectionData } = connection;
  if (widgets?.length) {
    return TabKey.widgets;
  }

  if (publicConnectionData?.schedules?.length) {
    return TabKey.schedules;
  }

  return TabKey.settings;
}

function ClientConnectionView(props: { connection: ClientConnection; connector: IConnector }) {
  const routeLocation = useLocation();

  const { clientId, client } = useClientContext();
  const {
    connection: { connectorSlug, systemConnector, name, id, publicConnectionData },
    connection,
    connector: { widgets, icon, updatableAuthFields },
  } = props;

  useTimeMixpanelEvent('connection_detail_view', {
    clientId,
    ...connection,
  });

  const defaultTab = getDefaultTab(connection, widgets);
  const { parsed } = useSearchParams([{ key: 'tab', defaultValue: defaultTab }]);
  const activeTab = parsed.tab ?? defaultTab;

  const schedules = publicConnectionData?.schedules;

  const tabItems: TabsProps['items'] = [
    {
      key: TabKey.workflows,
      children: <ConnectionWorkflows connectionId={connection.id} />,
      label: (
        <Link
          to={{
            ...routeLocation,
            search: `?tab=${TabKey.workflows}`,
          }}
        >
          Automations
        </Link>
      ),
    },
  ];
  if (id && Boolean(widgets?.length)) {
    tabItems.push({
      children: <ClientConnectionWidgets widgets={widgets ?? []} connectionId={id} />,
      key: TabKey.widgets,
      label: (
        <Link
          to={{
            ...routeLocation,
            search: '?tab=widgets',
          }}
        >
          Widgets
        </Link>
      ),
    });
  }
  // Maybe only useful for engineers?
  if (schedules?.length) {
    tabItems.push({
      children: <ConnectionSchedules connection={connection} />,
      key: TabKey.schedules,
      label: (
        <Link
          to={{
            ...routeLocation,
            search: '?tab=schedules',
          }}
        >
          Scheduled Tasks
        </Link>
      ),
    });
  }
  if (id && updatableAuthFields) {
    tabItems.push({
      children: <ClientConnectionConfiguration connection={connection} updatableAuthFields={updatableAuthFields} />,
      key: TabKey.configuration,
      label: (
        <Link
          to={{
            ...routeLocation,
            search: '?tab=configuration',
          }}
        >
          Configuration
        </Link>
      ),
    });
  }

  if (!clientId || !connectorSlug) {
    return null;
  }

  const breadcrumb = (
    <Breadcrumb
      items={[
        {
          key: 'client',
          title: <Link to={`/${clientId}`}>{client?.name}</Link>,
        },
        {
          key: 'settings',
          title: <Link to={settingsPath({ clientId })}>Settings</Link>,
        },
        {
          key: 'integrations',
          title: <Link to={integrationsPath({ clientId })}>Integrations</Link>,
        },
        {
          key: 'connector',
          title: <Link to={connectorDetailPath({ clientId, connectorId: connectorSlug })}>Integrations</Link>,
        },
      ]}
    />
  );

  return (
    <>
      {breadcrumb}

      <PageHeader title={name} backIcon={icon} />

      <Tabs activeKey={activeTab} items={tabItems} />
    </>
  );
}

const CONNECTION_DETAILS = gql`
  query ConnectionDetails($id: String!) {
    clientConnection(id: $id) {
      id
      name
      connectorSlug
      publicConnectionDataJson
      publicConnectionData
      systemConnector {
        id
        name

        dataSourceDefinitions {
          id
        }
      }
    }
  }
`;

export function ConnectionDetail() {
  const { clientId } = useClientContext();
  const { connectionId, connectorSlug } = useParams<{ connectorSlug: string; connectionId: string }>();

  // find matching connector definition
  const connector = CONNECTORS.find((c) => c.slug === connectorSlug) ?? null;

  const { data, loading } = useQuery(CONNECTION_DETAILS, {
    variables: { id: connectionId },
  });
  const clientConnection: ClientConnection | null = data?.clientConnection ?? null;

  if (!connector) {
    return <Navigate to={integrationsPath({ clientId: clientId ?? '' })} replace />;
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  if (clientConnection) {
    return <ClientConnectionView connection={clientConnection} connector={connector} />;
  }

  return <EmptyState>{`Could not find connection with id: "${connectionId}"`}</EmptyState>;
}
