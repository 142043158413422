import React from 'react';
import Icon, {
  ArrowRightOutlined,
  ArrowUpOutlined,
  ArrowLeftOutlined,
  ArrowDownOutlined,
  RightOutlined,
  LeftOutlined,
  DownOutlined,
  UpOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  TeamOutlined,
  UnlockFilled,
  CaretDownOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  CaretUpOutlined,
  CheckOutlined,
  CheckCircleFilled,
  QuestionCircleFilled,
  EllipsisOutlined,
  MailOutlined,
  EditOutlined,
  FilterOutlined,
  DeleteFilled,
  CloseCircleOutlined,
  ClockCircleOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  UsergroupDeleteOutlined,
  SearchOutlined,
  CloseOutlined,
  ReloadOutlined,
  ShareAltOutlined,
  ExperimentOutlined,
  HeartFilled,
  CopyOutlined,
  HomeFilled,
  DragOutlined,
  PushpinFilled,
  PushpinOutlined,
  DownloadOutlined,
  SyncOutlined,
  WarningFilled,
  ApiOutlined,
  BellOutlined,
  InfoCircleFilled,
  InboxOutlined,
  LineChartOutlined,
  MessageOutlined,
  ClockCircleFilled,
} from '@ant-design/icons';

// move to ./svgs
import NuspireSVG from './svgs/nuspire-svg';
import MitreSVG from './svgs/mitre-svg';

import DashboardSvg from './svgs/dashboard-svg';
import CxpSvg from './svgs/cxp-svg';
import NspSvg from './svgs/nsp-svg';
import ThreatSvg from './svgs/threat-svg';
import CaseManagementSvg from './svgs/case-management-svg';
import InsightsSvg from './svgs/insights-svg';
import DefensesSvg from './svgs/defenses-svg';
import SettingsSvg from './svgs/settings-svg';
import ConnectionsSvg from './svgs/connections-svg';
import IncompleteSvg from './svgs/incomplete-svg';
import AwsSvg from './svgs/aws-svg';
import AzureSvg from './svgs/azure-svg';
import TargetSvg from './svgs/target-svg';
import SlackSVG from './svgs/slack-svg';
import JiraSVG from './svgs/jira-svg';
import SentinelOneSVG from './svgs/sentinelone-svg';
import CrowdStrikeSVG from './svgs/crowdstrike-svg';
import VmWareSVG from './svgs/vm-ware-svg';
import CybereasonSVG from './svgs/cybereason-svg';
import QualysSvg from './svgs/qualys-svg';
import QualysShieldSvg from './svgs/qualys-shield-svg';
import PrismaCloudSvg from './svgs/prisma-svg';
import FortisiemSvg from './svgs/fortisiem-svg';
import ServicesSvg from './svgs/services-svg';
import MerakiSvg from './svgs/meraki-svg';
import DuoSvg from './svgs/duo-svg';
import CombinedInsightsSvg from './svgs/combined-insights-svg';
import SentryGlyphSvg from './svgs/sentry-glyph-svg';
import { NECESSVG } from './svgs/neces-svg';
import { useTheme } from 'styled-components';

// copy pasta'd from ant-design...
export interface CustomIconComponentProps {
  width?: string | number;
  height?: string | number;
  fill?: string;
  viewBox?: string;
  className?: string;
  style?: React.CSSProperties;
}

export const NuspireIcon = (props: CustomIconComponentProps) => <Icon component={NuspireSVG} {...props} />;

export const DashboardIcon = (props: CustomIconComponentProps) => <Icon component={DashboardSvg} {...props} />;

export const SettingsIcon = (props: CustomIconComponentProps) => <Icon component={SettingsSvg} {...props} />;

export const MitreIcon = (props: CustomIconComponentProps) => <Icon component={MitreSVG} {...props} />;

export const NspIcon = (props: CustomIconComponentProps) => <Icon component={DefensesSvg} {...props} />;

export const CxpIcon = (props: CustomIconComponentProps) => <Icon component={CxpSvg} {...props} />;
export const Automation = (props: CustomIconComponentProps) => <Icon component={SettingsSvg} {...props} />;
export const Enrich = (props: CustomIconComponentProps) => <Icon component={CxpSvg} {...props} />;

export const ThreatIcon = (props: CustomIconComponentProps) => <Icon component={ThreatSvg} {...props} />;

export const InsightsIcon = (props: CustomIconComponentProps) => <Icon component={InsightsSvg} {...props} />;

export const CombinedInsightsIcon = (props: CustomIconComponentProps) => (
  <Icon component={CombinedInsightsSvg} {...props} />
);

export const ConnectionsIcon = (props: CustomIconComponentProps) => <Icon component={ConnectionsSvg} {...props} />;

export const DefensesIcon = (props: CustomIconComponentProps) => <Icon component={NspSvg} {...props} />;

export const Target = (props: CustomIconComponentProps) => <Icon component={TargetSvg} {...props} />;

export const CaseManagementIcon = (props: CustomIconComponentProps) => (
  <Icon component={CaseManagementSvg} {...props} />
);

export const Incomplete = (props: CustomIconComponentProps) => <Icon component={IncompleteSvg} {...props} />;
export const Services = (props: CustomIconComponentProps) => <Icon component={ServicesSvg} {...props} />;
export const SentryGlyph = (props: CustomIconComponentProps) => <Icon component={SentryGlyphSvg} {...props} />;

// Standardize Antd Icons
export const ArrowRight = ArrowRightOutlined;
export const ArrowUp = ArrowUpOutlined;
export const ArrowDown = ArrowDownOutlined;
export const ArrowLeft = ArrowLeftOutlined;
export const Chat = MessageOutlined;
export const ChevronRight = RightOutlined;
export const ChevronUp = UpOutlined;
export const ChevronDown = DownOutlined;
export const ChevronLeft = LeftOutlined;
export const ClientIdentifier = ExperimentOutlined;
export const Info = InfoCircleFilled;
export const Insight = LineChartOutlined;
export const Plus = PlusOutlined;
export const Tour = QuestionCircleOutlined;
export const Team = TeamOutlined;
export const Unlock = UnlockFilled;
export const CaretDown = CaretDownOutlined;
export const CaretRight = CaretRightOutlined;
export const CaretLeft = CaretLeftOutlined;
export const CaretUp = CaretUpOutlined;
export const Checkmark = CheckOutlined;
export const CheckmarkFilled = CheckCircleFilled;
export const Download = DownloadOutlined;
export const QuestionCircle = QuestionCircleFilled;
export const Ellipsis = EllipsisOutlined;
export const Email = MailOutlined;
export const Edit = EditOutlined;
export const Filter = FilterOutlined;
export const Inbox = InboxOutlined;
export const Delete = DeleteFilled;
export const Close = CloseCircleOutlined;
export const Cancel = CloseOutlined;
export const Refresh = SyncOutlined;
export const Time = ClockCircleOutlined;
export const TimeFilled = ClockCircleFilled;
export const User = UserOutlined;
export const UserAdd = UsergroupAddOutlined;
export const UserRemove = UsergroupDeleteOutlined;
export const Reload = ReloadOutlined;
export const Search = SearchOutlined;
export const Share = ShareAltOutlined;
export const Heart = HeartFilled;
export const Copy = CopyOutlined;
export const Home = HomeFilled;
export const Drag = DragOutlined;
export const NotificationBell = BellOutlined;
export const PinIcon = PushpinFilled;
export const UnpinIcon = PushpinOutlined;
export const Warning = WarningFilled;
export const Workflow = ApiOutlined;

// third party icons
export const AWS = (props: CustomIconComponentProps) => <Icon component={AwsSvg} {...props} />;
export const Slack = (props: CustomIconComponentProps) => <Icon component={SlackSVG} {...props} />;
export const Jira = (props: CustomIconComponentProps) => <Icon component={JiraSVG} {...props} />;
export const SentinelOne = (props: CustomIconComponentProps) => <Icon component={SentinelOneSVG} {...props} />;
export const CrowdStrike = (props: CustomIconComponentProps) => <Icon component={CrowdStrikeSVG} {...props} />;
export const VmWare = (props: CustomIconComponentProps) => {
  const theme = useTheme();
  return <Icon component={VmWareSVG} style={{ fill: theme.mode === 'dark' ? 'white' : '' }} {...props} />;
};
export const Cybereason = (props: CustomIconComponentProps) => <Icon component={CybereasonSVG} {...props} />;
export const Meraki = (props: CustomIconComponentProps) => {
  const theme = useTheme();
  return <Icon component={MerakiSvg} style={{ fill: theme.mode === 'dark' ? 'white' : '' }} {...props} />;
};
export const Duo = (props: CustomIconComponentProps) => {
  const theme = useTheme();
  return <Icon component={DuoSvg} style={{ fill: theme.mode === 'dark' ? 'white' : '' }} {...props} />;
};
export const Azure = (props: CustomIconComponentProps) => <Icon component={AzureSvg} {...props} />;
export const Qualys = (props: CustomIconComponentProps) => <Icon component={QualysSvg} {...props} />;
export const QualysShield = (props: CustomIconComponentProps) => <Icon component={QualysShieldSvg} {...props} />;
export const PrismaCloud = (props: CustomIconComponentProps) => <Icon component={PrismaCloudSvg} {...props} />;
export const Fortisiem = (props: CustomIconComponentProps) => {
  const theme = useTheme();
  return <Icon component={FortisiemSvg} style={{ fill: theme.mode === 'dark' ? 'white' : '' }} {...props} />;
};
export const NECES = (props: CustomIconComponentProps) => <Icon component={NECESSVG} {...props} />;
