import { useQuery } from '@apollo/client';
import { ALL_CASES_QUERY } from 'components/case-management/cases-table';
import { caseListPath } from 'components/case-management/paths';
import { CaseListItem } from 'components/case-management/widgets/latest-open-cases/latest-open-cases';
import { EmptyState, NuButton, NuCard, NuCardContent, NuCardTitle } from 'components/nuspire';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { Link } from 'react-router';

/**
 * Really just need to show something to prototype these static functions.
 * they could technically follow the standard widget defintion path if we wanted to add these
 * to custom dashboards. but
 */

function CasesList(props: {
  clientId: string;
  cases?: { number: string; priority: string; short_description: string }[];
  loading?: boolean;
}) {
  const { cases, loading, clientId } = props;

  if (cases?.length) {
    return (
      <>
        {cases.map((c) => {
          return <CaseListItem key={c.number} case={c} clientId={clientId} />;
        })}
      </>
    );
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }
  return <EmptyState>No Open Cases were found.</EmptyState>;
}

export function HighPriorityCasesCard(props: { clientId }) {
  const { clientId } = props;

  const { data, loading } = useQuery(ALL_CASES_QUERY, {
    variables: {
      clientId,
      filters: {
        states: ['new', 'open', 'in progress'],
        priorities: ['2 - High', '1 - Critical'],
        sortBy: 'priority',
        sortOrder: 'asc',
        limit: 20,
      },
    },
  });

  const cases = data?.cases?.cases;

  return (
    <NuCard fullHeight>
      <NuCardTitle
        title="High Priority Cases"
        actions={
          <Link to={caseListPath({ clientId, priorities: ['high', 'critical'] })}>
            <NuButton type="link">View All</NuButton>
          </Link>
        }
      />
      <NuCardContent>
        <CasesList cases={cases} loading={loading} clientId={clientId} />
      </NuCardContent>
    </NuCard>
  );
}
