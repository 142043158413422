import { gql, useQuery } from '@apollo/client';
import { Space } from 'antd';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { ConnectionWorkflowsQuery } from 'types/graph-codegen/graph-types';
import { Unpacked } from 'types';
import { AutomationListItem } from '../../../../automations/automations-list-item';
import { WORKFLOW_LIST_ITEM_FRAGMENT } from '../../../../automations/graphql';

const CONNECTION_WORKFLOWS = gql`
  ${WORKFLOW_LIST_ITEM_FRAGMENT}

  query ConnectionWorkflows($connectionId: String!) {
    clientConnection(id: $connectionId) {
      id

      # get all possible workflows for connector
      systemConnector {
        id
        name
        slug

        # rename to workflows
        dataSourceDefinitions {
          id
          name
          description

          # Fields to edit configuration
          fields {
            key
            inputType
            parameters
            label
            type
            jsonSchemaString
            required
            editable
            errorMessages {
              required
              format
            }
          }

          # Instances of workflow. Should limit one instance per connection per workflow
          connectionDataSources(connectionId: $connectionId) {
            ...WorkflowListItemFields
          }
        }
      }
    }
  }
`;

type ClientConnection = NonNullable<ConnectionWorkflowsQuery['clientConnection']>;
type SystemConnector = NonNullable<ClientConnection['systemConnector']>;
type DataSourceDefinition = NonNullable<Unpacked<SystemConnector['dataSourceDefinitions']>>;

export function WorkflowDefinitionsList(props: {
  workflowDefinitions: DataSourceDefinition[];
  connectionId: string;
  connector: any;
}) {
  const { workflowDefinitions, connectionId, connector } = props;

  return (
    <Space
      direction="vertical"
      style={{
        display: 'flex',
        alignItems: 'stretch',
      }}
    >
      {workflowDefinitions.map((w) => (
        <AutomationListItem
          connector={connector}
          automationDefinition={w}
          automation={w.connectionDataSources?.[0]}
          connectionId={connectionId}
          showConnectionName={false}
        />
      ))}
    </Space>
  );
}

export function ConnectionWorkflows(props: { connectionId?: string }) {
  const { connectionId } = props;

  const { data, loading } = useQuery<ConnectionWorkflowsQuery>(CONNECTION_WORKFLOWS, {
    variables: {
      connectionId,
    },
    skip: !connectionId,
  });

  const workflowDefinitions = data?.clientConnection?.systemConnector?.dataSourceDefinitions;
  const systemConnector = data?.clientConnection?.systemConnector;

  if (connectionId && workflowDefinitions && systemConnector) {
    return (
      <WorkflowDefinitionsList
        connector={systemConnector}
        connectionId={connectionId}
        workflowDefinitions={workflowDefinitions}
      />
    );
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  return null;
}
