import { AreaChartOutlined, ContactsOutlined, UnorderedListOutlined, UserOutlined } from '@ant-design/icons';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Divider, Drawer, List, message, Tabs } from 'antd';
import { Link } from 'components/nuspire';
import { CaseManagementIcon, SettingsIcon } from 'components/nuspire/nu-icon';
import { useEffect, useState } from 'react';
import { IClient, IPermissions, IUser } from '../../types';
import {
  GetClientForAdminPanelQuery,
  GetClientForAdminPanelQueryVariables,
  UserForAdminQuery,
  UserForAdminQueryVariables,
  UserGroupsForUserAdminQuery,
  UserGroupsForUserAdminQueryVariables,
} from '../../types/graph-codegen/graph-types';
import { formatUserName } from '../../utils/users';
import { useClientContext } from '../client-context-provider';
import { NuButton } from '../nuspire';
import Spin, { SpinContainer } from '../nuspire/spin';
import { PermissionsCard } from '../users/permissions/permissions-card';
import { ClientDetails } from './clients/client-details';
import { AdminUserProfile, GLOBAL_USER_GROUPS_QUERY, USER_QUERY, UserGroupsList } from './users/user-details';

const GLOBAL_CLIENT_BY_ID_QUERY = gql`
  query GetClientForAdminPanel($id: String) {
    globalGetClientById(id: $id) {
      id
      name
      serviceNowAccount {
        number
        name
      }
      oktaGroupId
      ownerId
      apiKeyId
      parentId
      parent {
        name
      }
      industry {
        id
        name
      }
      partnerDetails {
        additionalInfo
        businessCountry
        businessEmail
        businessPhone
        businessPhoneExt
        businessSalesRepresentative
        businessType
        businessWebsite
        isApproved
        partnerType
      }
      deletedAt
      clientIdentifiers {
        type
        value
      }
      isMultiTenancyEnabled
      type
    }
  }
`;

export type AdminDrawerProps = {
  user: IUser;
};

export function AdminDrawerAndButton(props: AdminDrawerProps) {
  const { user } = props;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { clientId: contextClientId } = useClientContext();

  const clientId = contextClientId ?? user?.clientId;
  const userName = formatUserName(user);

  return (
    <>
      <NuButton type="text" onClick={() => setIsDrawerOpen(true)} icon={<SettingsIcon />} shape="circle" size="large" />

      <Drawer
        title={`Admin: ${userName}`}
        placement="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        size="large"
      >
        <Divider orientation="left">Quick Links</Divider>
        <List
          size="small"
          split={false}
          grid={{ column: 3 }}
          dataSource={[
            {
              link: (
                <Link to={`/${user.clientId}/reporting-and-analysis/dashboards`}>
                  <NuButton type="link">
                    <AreaChartOutlined />
                    Client Dashboard
                  </NuButton>
                </Link>
              ),
            },
            {
              link: (
                <Link to={`/${user.clientId}/case-management/cases`}>
                  <NuButton type="link">
                    <CaseManagementIcon />
                    Case Management
                  </NuButton>
                </Link>
              ),
            },
            {
              link: (
                <Link to={`/${user.clientId}/settings/users/${user.id}`}>
                  <NuButton type="link">
                    <UnorderedListOutlined />
                    Permissions/Groups
                  </NuButton>
                </Link>
              ),
            },
            {
              link: (
                <Link to={`/${user.clientId}/settings`}>
                  <NuButton type="link">
                    <SettingsIcon />
                    Client Settings
                  </NuButton>
                </Link>
              ),
            },
            {
              link: (
                <Link to={`/admin/clients/${user.clientId}`}>
                  <NuButton type="link">
                    <ContactsOutlined />
                    Client Admin
                  </NuButton>
                </Link>
              ),
            },
            {
              link: (
                <Link to={`/admin/users/${user.id}`}>
                  <NuButton type="link">
                    <UserOutlined />
                    User Admin
                  </NuButton>
                </Link>
              ),
            },
          ]}
          renderItem={(item) => <List.Item>{item.link}</List.Item>}
        />
        <Divider orientation="left">Details</Divider>
        <Tabs
          items={[
            {
              key: 'client-details',
              label: 'Client',
              children: <ClientTab clientId={clientId} />,
            },
            {
              key: 'user-details',
              label: 'User',
              children: <UserTab clientId={clientId} userId={user.id} />,
            },
          ]}
        />
      </Drawer>
    </>
  );
}

type ClientTabProps = {
  clientId: string;
};

function ClientTab(props: ClientTabProps) {
  const { clientId } = props;
  const { data, error, loading, refetch } = useQuery<GetClientForAdminPanelQuery, GetClientForAdminPanelQueryVariables>(
    GLOBAL_CLIENT_BY_ID_QUERY,
    {
      variables: {
        id: clientId,
      },
    },
  );

  useEffect(() => {
    if (error) {
      console.error(error);
      message.error('An error occurred while attempting to fetch client details for admin drawer.');
    }
  }, [error]);

  return (
    <ClientDetails
      client={data?.globalGetClientById as IClient}
      clientId={clientId}
      loading={loading}
      refetch={() => refetch()}
      tabKeysToShow={['invite-new-user']}
    />
  );
}

type UserTabProps = {
  clientId: string;
  userId: string;
};

function UserTab(props: UserTabProps) {
  const { clientId, userId } = props;
  const { data, error, loading, refetch } = useQuery<UserForAdminQuery, UserForAdminQueryVariables>(USER_QUERY, {
    variables: {
      userId,
    },
  });
  const [getUserGroups, { data: userGroupsData, loading: userGroupsLoading, error: userGroupsError }] = useLazyQuery<
    UserGroupsForUserAdminQuery,
    UserGroupsForUserAdminQueryVariables
  >(GLOBAL_USER_GROUPS_QUERY);

  useEffect(() => {
    if (error) {
      console.error(error);
      message.error('An error occurred while attempting to fetch user details for admin drawer.');
    }
  }, [error]);

  useEffect(() => {
    if (userGroupsError) {
      console.error(userGroupsError);
      message.error('An error occurred while attempting to fetch user groups');
    }
  }, [userGroupsError]);

  useEffect(() => {
    if (data?.getUserForAdmin?.clientId) {
      getUserGroups({
        variables: {
          clientId: data?.getUserForAdmin?.clientId,
        },
      });
    }
  }, [data?.getUserForAdmin?.clientId]);

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  if (!data) {
    return null;
  }

  const user = data.getUserForAdmin;
  const memberOf = userGroupsData?.globalUserGroups!.filter((g) => g!.members!.find((m) => m!.id === userId));
  const permissions = user?.client?.effectivePermissions as IPermissions;

  return (
    <Tabs
      items={[
        {
          key: 'details',
          label: 'Details',
          children: (
            <AdminUserProfile
              refetch={refetch}
              memberOfGroupNames={memberOf?.map((g) => g!.name)?.join(', ')}
              user={user as IUser}
            />
          ),
        },
        {
          key: 'permissions',
          label: 'Permissions',
          children: <PermissionsCard loading={loading} permissions={permissions ?? []} />,
        },
        {
          key: 'groups',
          label: 'Groups',
          children: (
            <UserGroupsList
              clientId={clientId}
              loading={loading}
              refetch={refetch}
              user={user as IUser}
              userGroups={userGroupsData?.globalUserGroups}
            />
          ),
        },
      ]}
    />
  );
}
