import { DeleteOutlined } from '@ant-design/icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import { message, Popconfirm } from 'antd';
import { UPDATE_FAVORITE_DASHBOARDS } from 'components/layouts/authenticated-layout/navigation/dashboard-menu-context';
import { NuButton, NuCard, NuCardContent, NuCardTitle } from 'components/nuspire';
import { DraggableItemWithHandle } from 'components/nuspire/sortable-list/draggable-item-with-handle';
import { SortableList, SortableListItemProps } from 'components/nuspire/sortable-list/sortable-list';
import { dashboardDetailPath } from 'components/reporting-and-analysis/paths';
import { useState } from 'react';
import { Link } from 'react-router';
import styled, { css } from 'styled-components';
import { UpdateFavoriteDashboardsMutation } from 'types/graph-codegen/graph-types';

// should come from type generation
type FavoriteDashboard = {
  id: string;
  name: string;
};

const DeleteButton = styled(NuButton).attrs({
  icon: <DeleteOutlined />,
  type: 'text',
  size: 'small',
})`
  color: ${(p) => p.theme.token.colorText};
  &:hover {
    background-color: ${(p) => p.theme.color.gray2};
    color: ${(p) => p.theme.color.slate};
  }
`;

export const navItemMixin = css`
  && {
    background-color: ${(p) => p.theme.token.colorBgElevated};
    padding: 4px 16px;
    position: relative;
    transition: background 0.3s ease;
    min-height: 40px;
    border: none;
    border-radius: 8px;
    transition: all 0.3s;
    margin-inline: 4px;
    margin-block: 4px;
    color: ${(p) => p.theme.token.colorText};

    :first-child,
    :last-child {
      margin-block: 0;
    }
  }
  &:hover {
    color: ${(p) => p.theme.token.colorLinkHover};
  }
`;

export const NavItemLink = styled(Link)`
  color: ${(p) => p.theme.token.colorText};
`;

export const FavoriteNavItem = styled(DraggableItemWithHandle)`
  ${DeleteButton} {
    visibility: hidden;
    opacity: 0;
  }
  &:hover ${DeleteButton} {
    visibility: visible;
    opacity: 1;
  }
  ${navItemMixin};
`;

export const FAVORITE_DASHBOARDS_CARD = gql`
  query FavoriteDashboardsCard($clientId: String!) {
    getUserDashboardsData(clientId: $clientId) {
      favoriteDashboards {
        id
        name
      }
    }
  }
`;

export default function FavoriteDashboardsCard(props: { clientId: string }) {
  const { clientId } = props;

  const [favoriteDashboards, setFavoriteDashboards] = useState<FavoriteDashboard[]>([]);

  const { loading } = useQuery(FAVORITE_DASHBOARDS_CARD, {
    variables: { clientId },
    onCompleted: (data) => {
      setFavoriteDashboards(data?.getUserDashboardsData?.favoriteDashboards ?? []);
    },
  });

  const [updateFavorites] = useMutation<UpdateFavoriteDashboardsMutation>(UPDATE_FAVORITE_DASHBOARDS);

  // Callbacks
  const handleReordered = async (items: any) => {
    // optimistic UI
    setFavoriteDashboards(items.map((i) => i.data));

    try {
      await updateFavorites({
        variables: {
          clientId,
          dashboardIds: items.map((i) => i.id),
        },
      });
    } catch (error: any) {
      message.error(`Failed to update Favorites`);
    }
  };

  const handleRemove = async (id: string) => {
    // update state
    const filteredDashboards = favoriteDashboards.filter((i) => i.id !== id);

    setFavoriteDashboards(filteredDashboards);

    try {
      await updateFavorites({
        variables: {
          clientId,
          dashboardIds: filteredDashboards.map((i) => i.id),
        },
      });
    } catch (err) {
      message.error(`Failed to remove Favorite.`);
    }
  };

  // build sortable items
  const items = favoriteDashboards.map((item): SortableListItemProps<FavoriteDashboard> => {
    const { id, name } = item;
    return {
      data: item,
      id,
      render: (renderProps, ref) => (
        <FavoriteNavItem ref={ref} {...renderProps}>
          <NavItemLink style={{ width: '100%' }} to={dashboardDetailPath({ clientId, id })}>
            {name}
          </NavItemLink>
          <Popconfirm
            title={`Are you sure you want to remove ${name} from your favorites?`}
            placement="right"
            onConfirm={() => handleRemove(id)}
          >
            <DeleteButton />
          </Popconfirm>
        </FavoriteNavItem>
      ),
    };
  });

  return (
    <NuCard fullHeight>
      <NuCardTitle
        title="Favorite Dashboards"
        // actions.
      />
      <NuCardContent>
        {/* <FavoriteDashboardsList clientId={clientId} loading={loading} favoriteDashboards={favoriteDashboards} /> */}
        <SortableList items={items} loading={loading} onReordered={handleReordered} size="small" />
      </NuCardContent>
    </NuCard>
  );
}
