import { Empty, Select, SelectProps, Spin } from 'antd';
import debounce from 'lodash.debounce';
import { forwardRef, UIEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

const { Option } = Select;

const SmallEmpty = styled(Empty).attrs({
  image: Empty.PRESENTED_IMAGE_SIMPLE,
  // there isn't documentation for this className anywhere but it works. 🤷
  className: 'ant-empty-small',
})``;

export const NO_MORE_DATA = 'NO_MORE_DATA';

export type OnFetch = (
  searchQuery?: string,
  reset?: boolean,
) => Promise<{
  hasNoMoreData?: boolean;
  selectOptions: SelectOption[];
}>;

export type SelectOption = {
  label: string;
  value: string;
};

// omit loading because we should retain full control over that state
interface InfiniteSelectProps extends Omit<SelectProps, 'loading'> {
  onFetch: OnFetch;
}

// extract BaseSelectRef type out of antd
type SelectRef = React.ComponentProps<typeof Select>['ref'];

const InfiniteSelect = forwardRef((props: InfiniteSelectProps, ref: SelectRef) => {
  const {
    onFetch,
    onClear: onClearProp,
    onPopupScroll: onPopupScrollProp,
    onSearch: onSearchProp,
    virtual: virtualProp,
    ...restProps
  } = props;
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [hasNoMoreData, setHasNoMoreData] = useState(false);

  const fetchData = useCallback(
    async (searchQuery?: string, initFetch?: boolean) => {
      try {
        setLoading(true);
        const response = await onFetch(searchQuery, initFetch);
        if (initFetch) {
          setOptions(response.selectOptions);
        } else {
          setOptions([...options, ...response.selectOptions]);
        }

        setHasNoMoreData(response.hasNoMoreData ?? false);
      } catch (e) {
        if (e === NO_MORE_DATA && options.length > 0) {
          setHasNoMoreData(true);
          return;
        }
        console.warn(`Infinite Select onFetch failed: ${e}`);
      } finally {
        setLoading(false);
      }
    },
    [onFetch, options],
  );

  const initOptions = async (searchQuery?: string) => {
    setOptions([]);
    setHasNoMoreData(false);

    await fetchData(searchQuery, true);
  };

  useEffect(() => {
    initOptions();
  }, []);

  const notFoundContent = useMemo(() => {
    if (loading) {
      return (
        <Spin>
          <SmallEmpty />
        </Spin>
      );
    }
    return <SmallEmpty />;
  }, [loading]);

  const onClear = () => {
    onClearProp?.();
    setSearchValue('');
    initOptions();
  };

  const onSearch = debounce(async (searchQuery: string) => {
    onSearchProp?.(searchQuery);
    setSearchValue(searchQuery);
    initOptions(searchQuery);
  }, 250);

  const onPopupScroll: UIEventHandler<HTMLDivElement> = async (event) => {
    onPopupScrollProp?.(event);

    const { target } = event;
    if (!(target instanceof HTMLDivElement)) return;
    if (loading || hasNoMoreData) return;

    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      await fetchData(searchValue);
    }
  };

  return (
    <Select
      ref={ref}
      notFoundContent={notFoundContent}
      onClear={onClear}
      onPopupScroll={onPopupScroll}
      onSearch={props.showSearch ? onSearch : props.onSearch}
      virtual={virtualProp ?? false}
      filterOption={false}
      loading={loading}
      {...restProps}
    >
      {options.map(({ label, value }) => (
        <Option value={value} key={value}>
          {label}
        </Option>
      ))}
      {loading && !hasNoMoreData && <Option disabled><Spin/> Loading More Data</Option>}
      {hasNoMoreData && <Option disabled>No More Data</Option>}
    </Select>
  );
});

InfiniteSelect.displayName = 'InfiniteSelect';

export default InfiniteSelect;
