import styled from 'styled-components';
import { ReactNode } from 'react';
import classNames from 'classnames';

/**
 * Styled Components
 */
const PageSectionHeaderRoot = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;

  &.has-subtitle {
    align-items: flex-start;
  }
`;

// Title and Icon will go in here.
const PageSectionHeaderTitleWrap = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;
const PageSectionHeaderIcon = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: ${(p) => p.theme.color.nuspireBlue};
`;
const PageSectionHeaderTitle = styled.h4`
  font-size: 18px;
  font-weight: 500;
  color: ${(p) => p.theme.token.colorText};
  margin-bottom: 0;

  .has-subtitle & {
    font-weight: 700;
  }
`;
const PageSectionHeaderActions = styled.div`
  display: flex;
  gap: 8px;
`;
const PageSectionTitles = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;
const PageSectionSubtitle = styled.div``;

export function PageSectionHeader(props: {
  title: string;
  subtitle?: ReactNode;
  icon?: ReactNode;
  actions?: ReactNode;
}) {
  const { title, icon, actions, subtitle } = props;

  const rootClasses = classNames({
    'has-subtitle': Boolean(subtitle),
  });

  return (
    <PageSectionHeaderRoot className={rootClasses}>
      <PageSectionHeaderTitleWrap>
        {icon && <PageSectionHeaderIcon>{icon}</PageSectionHeaderIcon>}

        <PageSectionTitles>
          <PageSectionHeaderTitle>{title}</PageSectionHeaderTitle>

          {subtitle && <PageSectionSubtitle>{subtitle}</PageSectionSubtitle>}
        </PageSectionTitles>
      </PageSectionHeaderTitleWrap>

      {actions && <PageSectionHeaderActions>{actions}</PageSectionHeaderActions>}
    </PageSectionHeaderRoot>
  );
}

/**
 * Styled Components
 */
const Root = styled.div`
  display: flex;
  flex-direction: column;
`;
const PageSectionContent = styled.div``;

export default function PageSection(props: {
  title: string;
  icon?: ReactNode;
  actions?: ReactNode;
  children: ReactNode;
  subtitle?: ReactNode;
}) {
  const { children } = props;

  return (
    <Root>
      <PageSectionHeader {...props} />

      <PageSectionContent>{children}</PageSectionContent>
    </Root>
  );
}
