import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Form, Input, Modal, Select, Spin, Tooltip, message } from 'antd';
import { LabeledValue } from 'antd/es/select';
import { useClientContext } from 'components/client-context-provider';
import { FC, useEffect, useState } from 'react';
import { Cron } from 'react-js-cron';
import 'react-js-cron/dist/styles.css';
import {
    CreateReportScheduleInput,
  CreateReportScheduleMutation,
  CreateReportScheduleMutationVariables,
  GenerateReportInput,
  GenerateReportMutation,
  GenerateReportMutationVariables,
  GetDashboardsForReportQuery,
  GetDashboardsForReportQueryVariables,
  GetReportScheduleQuery,
  GetReportScheduleQueryVariables,
  ReportType,
  UpdateReportScheduleMutation,
  UpdateReportScheduleMutationVariables,
} from 'types/graph-codegen/graph-types';
import { useAuthContext } from '../auth-context';
import { SortType } from '../data-explorer/data-explorer-table';
import InfiniteSelect, { NO_MORE_DATA, OnFetch } from '../nuspire/infinite-select/infinite-select';
import { GET_ACTIVE_REPORTS } from './active-reports';
import { GET_REPORTS } from './scheduled-reports';
import { CopyToClipboardIcon } from '../shared-components';
import QuestionCircleOutlined from '@ant-design/icons/lib/icons/QuestionCircleOutlined';
import { emailListValidator } from 'utils/validator';
import { useTimezoneSelect, ITimezone, ITimezoneOption } from 'react-timezone-select';

const GET_DASHBOARDS = gql`
  query GetDashboardsForReport($clientId: String!, $queryString: String, $next: String) {
    dashboardSearch(clientId: $clientId, queryString: $queryString, next: $next) {
      next
      total
      items {
        id
        name
      }
    }
  }
`;

export const CREATE_REPORT_SCHEDULE = gql`
  mutation CreateReportSchedule($input: CreateReportScheduleInput!) {
    createReportSchedule(input: $input) {
      id
    }
  }
`;

export const GENERATE_REPORT = gql`
  mutation GenerateReport($input: GenerateReportInput!) {
    generateReport(input: $input) {
      id
    }
  }
`;

export const GET_REPORT = gql`
  query GetReportSchedule($clientId: String!, $id: String!) {
    reportSchedule(clientId: $clientId, id: $id) {
      cron
      timezone
      configuration
      dashboards {
        name
        id
      }
      description
      name
      recipients
      reportType
    }
  }
`;

const UPDATE_REPORT_SCHEDULE = gql`
  mutation UpdateReportSchedule($input: UpdateReportInput!) {
    updateReport(input: $input) {
      id
    }
  }
`;

export type ReportTypeOptions = {
  label: string;
  value: ReportType;
}[];

export const reportTypes: ReportTypeOptions = [
  { label: 'Case Management', value: 'caseManagement' as const },
  { label: 'Dashboard', value: 'dashboard' as const },
  { label: 'Data Explorer', value: 'dataExplorer' as const },
];

export interface BaseReportValues {
  description?: string;
  name: string;
  recipients?: string;
  reportType?: ReportType;

  // cron & timezone are only for creating a recurring schedule. not for scheduling an ad-hoc report
  cron?: string;
  timezone?: string;
}

export interface CaseManagementReportValues extends BaseReportValues {
  reportType: 'caseManagement';
  type?: string;
  template?: string;
}

export function isCaseManagementReportValues(
  values: BaseReportValues
): values is CaseManagementReportValues {
  return values.reportType == 'caseManagement';
}

export interface DashboardReportValues extends BaseReportValues {
  reportType: 'dashboard';
  dashboards?: LabeledValue[];
  dashboardIds?: string[];
}

export function isDashboardReportValues(
  values: BaseReportValues
): values is DashboardReportValues {
  return values.reportType == 'dashboard';
}

export interface DataExplorerReportValues extends BaseReportValues {
  reportType: 'dataExplorer';
  dataTypeSlug?: string;
  url?: string;
  filters?: any;
  startDate?: string;
  endDate?: string;
  sort?: SortType;
  sortBy?: string;
}

export function isDataExplorerReportValues(
  values: BaseReportValues
): values is DataExplorerReportValues {
  return values.reportType == 'dataExplorer';
}

export type ScheduledReportValues = CaseManagementReportValues | DashboardReportValues | DataExplorerReportValues;

function buildReportConfig(values: ScheduledReportValues) {
  if (isCaseManagementReportValues(values)) {
    return {
      template: values.template,
      type: values.type,
    };
  } else if (isDashboardReportValues(values)) {
    return {
      dashboardIds: values.dashboardIds,
    };
  } else if (isDataExplorerReportValues(values)) {
    return {
      dataTypeSlug: values.dataTypeSlug,
      filters: values.filters,
      from: values.startDate,
      to: values.endDate,
      sort: values.sort,
      sortBy: values.sortBy,
    };
  }

  return {};
}

export interface ScheduledReportModalProps {
  initialValues?: Partial<ScheduledReportValues>;
  isLoading?: boolean;
  isModalOpen: boolean;
  setIsModalOpen: (newValue: boolean) => void;
  onFinish?: (values: ScheduledReportValues) => void;
}

export function ScheduledReportModal(props: ScheduledReportModalProps) {
  const {
    initialValues,
    isLoading = false,
    isModalOpen,
    setIsModalOpen,
  } = props;
  const { clientId } = useClientContext();
  const [form] = Form.useForm<ScheduledReportValues>();

  const [reportType, setReportType] = useState<ReportType | undefined>(initialValues?.reportType);

  const { options: timezoneOptions, parseTimezone } = useTimezoneSelect({});
  const getDefaultTimezone = () => {
    return parseTimezone(initialValues?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone);
  };
  const [timezone, setTimezone] = useState<ITimezone>(getDefaultTimezone());

  const [isReportRan, setIsReportRan] = useState(false);
  const [runReport, { loading: runReportLoading }] = useMutation<
    GenerateReportMutation,
    GenerateReportMutationVariables
  >(GENERATE_REPORT, {
    refetchQueries: [
      { query: GET_REPORTS, variables: { clientId: clientId! } },
      { query: GET_ACTIVE_REPORTS, variables: { clientId: clientId! } },
    ],
  });

  // reload the form when the initial values change (i.e., changing which scheduled report we are editing)
  useEffect(() => {
    form.setFieldsValue(initialValues ?? {});

    setReportType(initialValues?.reportType);
    setTimezone(getDefaultTimezone());
    setIsReportRan(false);
  }, [initialValues]);

  const onFinish = (values: ScheduledReportValues) => {
    const mergedValues = {
      ...values,
      timezone: (timezone as ITimezoneOption).value ?? timezone,
    };

    if (isDashboardReportValues(mergedValues)) {
      mergedValues.dashboardIds = mergedValues.dashboards?.map((v) => v.value as string);
    }

    props.onFinish?.(mergedValues);
    props.setIsModalOpen?.(false);
  };

  const onRunNow = async () => {
    const values = form.getFieldsValue();
    const recipients: string[] = values.recipients?.split(',') ?? [];

    const input: GenerateReportInput = {
      clientId: clientId!,
      description: values.description,
      name: values.name,
      recipients,
      reportType: values.reportType ?? 'dashboard',
      reportConfig: buildReportConfig(values),
    };

    await runReport({
      variables: {
        input,
      },
    });

    message.success('Your report is now running.');
    setIsReportRan(true);
  };

  let reportTypeOptions = reportTypes;
  // must remove data explorer from options if this is not a data explorer report
  if ((initialValues as DataExplorerReportValues)?.dataTypeSlug === undefined) {
    reportTypeOptions = reportTypes.filter((rt) => rt.value !== 'dataExplorer');
  }

  const DashboardReportFormItems: FC<{ clientId?: string | null }> = (props) => {
    const [nextDashboardsToken, setNextDashboardsToken] = useState<string>();
    const [queryDashboards] = useLazyQuery<GetDashboardsForReportQuery, GetDashboardsForReportQueryVariables>(
      GET_DASHBOARDS,
    );

    const fetchDashboards: OnFetch = async (searchQuery, reset) => {
      const currNext = reset ? undefined : nextDashboardsToken;

      const { data } = await queryDashboards({
        variables: {
          clientId: props.clientId!,
          next: currNext,
          queryString: searchQuery,
        },
      });

      const { items, next } = data?.dashboardSearch ?? {};

      if (!items) {
        return Promise.reject(NO_MORE_DATA);
      }

      setNextDashboardsToken(next);

      return {
        selectOptions: items
          .filter((v) => v !== undefined)
          .map((v) => ({
            value: v!.id,
            label: v!.name,
          })),
      };
    };

    return (
      <Form.Item name="dashboards" label="Dashboard(s)" rules={[{ required: true }]}>
        <InfiniteSelect onFetch={fetchDashboards} mode="multiple" labelInValue allowClear showSearch />
      </Form.Item>
    );
  };

  const CaseManagementReportFormItems: FC<{}> = (_props) => {
    return (
      <>
        <Form.Item name="type" label="Type" rules={[{ required: true }]}>
          <Select
            options={[
              { label: 'Cases', value: 'cases' },
              { label: 'Incidents', value: 'incidents' },
            ]}
          />
        </Form.Item>
        <Form.Item name="template" label="Report Template" rules={[{ required: true }]}>
          <Select options={[{ label: 'Opened', value: 'opened' }]} />
        </Form.Item>
      </>
    );
  };

  const DataExplorerReportFormItems: FC<{}> = (_props) => {
    const { url } = initialValues as DataExplorerReportValues || {};

    return (
      <>
        <ul>
          <li>You may export up to 30 days of data, or 100k search results.</li>
          <li>Results are based on the filters you currently have set.</li>
          <li>
            The data export function runs as a background task. You should receive the CSV file via an email attachment
            within 10-15 minutes of the scheduled time. If you do not see it check your spam folder for an email from
            no-reply@mynuspire.io.
          </li>
        </ul>
        {url && (
          <Form.Item name="url">
            <Tooltip className="pull-right" placement="left" title="A url to the live Data Explorer search results.">
              <Button size="large" type="text" shape="circle" icon={<QuestionCircleOutlined />} />
            </Tooltip>
            <span style={{ marginRight: '0.5rem' }} className="url-title">
              Shareable Url
            </span>
            <CopyToClipboardIcon altTooltip="Copy to the clipboard." copyText={url} />
          </Form.Item>
        )}
        <Form.Item name="dataTypeSlug" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="filters" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="startDate" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="endDate" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="sort" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="sortBy" hidden>
          <Input />
        </Form.Item>
      </>
    );
  };

  return (
    <Modal
      title="Scheduled Report Settings"
      footer={
        <>
          <Button onClick={onRunNow} loading={runReportLoading} disabled={isReportRan}>
            Run Now
          </Button>
          <Button key="submit" type="primary" onClick={form.submit}>
            Save
          </Button>
        </>
      }
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      destroyOnClose
      width={800}
    >
      <Spin spinning={isLoading}>
        <Form form={form} initialValues={initialValues} layout="vertical" onFinish={onFinish}>
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="reportType" label="Report Type" rules={[{ required: true }]}>
            <Select
              onSelect={(newReportType: ReportType) => {
                setReportType(newReportType);
              }}
              options={reportTypeOptions}
            />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea value={initialValues?.description ?? ''} />
          </Form.Item>
          {reportType === 'dashboard' && <DashboardReportFormItems clientId={clientId} />}
          {reportType === 'caseManagement' && <CaseManagementReportFormItems />}
          {reportType === 'dataExplorer' && <DataExplorerReportFormItems />}
          <Form.Item name="cron">
            <Cron
              value={form.getFieldValue('cron')}
              setValue={(v: string) => {
                form.setFieldValue('cron', v);
              }}
              allowedPeriods={['month', 'week', 'day']}
              defaultPeriod="month"
              mode="single"
              clearButton={false}
              leadingZero
            />
          </Form.Item>
          <Form.Item name="timezone" label="Time Zone">
            <Select
              value={timezone}
              onSelect={setTimezone}
              options={timezoneOptions}
              filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
              showSearch
            />
          </Form.Item>
          <Form.Item
            name="recipients"
            label="Recipients"
            tooltip="Comma separated emails"
            rules={[
              {
                validator: (_rule, value) => emailListValidator(value),
              },
            ]}
          >
            <Input placeholder="john.smith@example.com, jane.doe@example.com" />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}

export interface UpdateScheduledReportModalProps extends ScheduledReportModalProps {
  scheduleId: string;
}

export function UpdateScheduledReportModal(props: UpdateScheduledReportModalProps) {
  const { initialValues, scheduleId, isLoading = false, isModalOpen, setIsModalOpen } = props;
  const { clientId } = useClientContext();

  const { data } = useQuery<GetReportScheduleQuery, GetReportScheduleQueryVariables>(GET_REPORT, {
    variables: {
      clientId: clientId!,
      id: scheduleId,
    },
    skip: scheduleId === '',
  });

  const [updateReport] = useMutation<UpdateReportScheduleMutation, UpdateReportScheduleMutationVariables>(
    UPDATE_REPORT_SCHEDULE,
    {
      refetchQueries: [
        { query: GET_REPORTS, variables: { clientId: clientId! } },
        { query: GET_ACTIVE_REPORTS, variables: { clientId: clientId! } },
      ],
    },
  );

  const reportSchedule = data?.reportSchedule;
  const reportType = reportSchedule?.reportType ?? 'dashboard';

  const onFinish = async (values: ScheduledReportValues) => {
    if (!values.reportType) {
      message.error('No report type selected. Cannot update schedule.');
      return;
    }

    props.onFinish?.(values);

    const recipients: string[] = values.recipients?.split(',') ?? [];
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let reportConfig = buildReportConfig(values);
    if (isDataExplorerReportValues(values)) {
      reportConfig = {
        ...reportConfig,
        filters: reportSchedule?.configuration?.filters,
        from: reportSchedule?.configuration?.from,
        to: reportSchedule?.configuration?.to,
        sort: reportSchedule?.configuration?.sort,
        sortBy: reportSchedule?.configuration?.sortBy,
      };
    }

    const updateInput: UpdateReportScheduleMutationVariables['input'] = {
      id: scheduleId,
      clientId: clientId!,
      description: values.description,
      name: values.name,
      recipients,
      cron: values.cron,
      timezone,
      reportConfig,
      reportType: values.reportType,
    };

    // todo: what happens if you edit and save a data explorer report?
    // i'm guessing we will need to pull those values out of the config and re-apply them here for it to work
    // otherwise i suspect it will just wipe those out and break it on save --mark
    await updateReport({
      variables: {
        input: updateInput,
      },
    });

    message.success('Your report has been updated.');
  };

  return (
    <ScheduledReportModal
      initialValues={{
        cron: reportSchedule?.cron ?? '00 00 1 * *',
        timezone: reportSchedule?.timezone,
        dashboards: reportSchedule?.dashboards.map((d) => ({
          label: d.name,
          value: d.id,
        })),
        description: reportSchedule?.description,
        name: reportSchedule?.name ?? '',
        recipients: reportSchedule?.recipients.join(',') ?? '',
        reportType,
        ...initialValues,
      }}
      isLoading={isLoading}
      isModalOpen={isModalOpen}
      onFinish={onFinish}
      setIsModalOpen={setIsModalOpen}
    />
  );
}

export function NewScheduledReportModal(props: ScheduledReportModalProps) {
  const { initialValues, isLoading, isModalOpen, setIsModalOpen } = props;
  const { clientId, client } = useClientContext();
  const { user } = useAuthContext();

  const [scheduleReport] = useMutation<CreateReportScheduleMutation, CreateReportScheduleMutationVariables>(
    CREATE_REPORT_SCHEDULE,
    {
      refetchQueries: [
        { query: GET_REPORTS, variables: { clientId: clientId! } },
        { query: GET_ACTIVE_REPORTS, variables: { clientId: clientId! } },
        'ScheduledReportsTable',
      ],
    },
  );

  const onFinish = async (values: ScheduledReportValues) => {
    if (!values.reportType) {
      message.error(`No report type selected. Cannot schedule report.`);
      return;
    }

    props.onFinish?.(values);

    const recipients: string[] = values.recipients?.split(',') ?? [];

    const input: CreateReportScheduleInput = {
      clientId: clientId!,
      description: values.description,
      name: values.name,
      recipients,
      reportConfig: buildReportConfig(values),
      reportType: values.reportType,
      cron: values.cron!,
      enabled: true,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    await scheduleReport({
      variables: {
        input,
      },
    });

    message.success('Your report has been scheduled.');
  };

  return (
    <ScheduledReportModal
      // forceRender
      onFinish={onFinish}
      initialValues={{
        cron: '00 00 1 * *',
        name: `${client?.name} report for ${new Date().toLocaleDateString()}`,
        recipients: user?.email || '',
        ...initialValues,
      }}
      isLoading={isLoading}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    />
  );
}
