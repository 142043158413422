import { FC } from 'react';
import { Outlet } from 'react-router';
import { useAuthContext } from '../auth-context';
import { ClientContextProvider } from '../client-context-provider';

export const RequireAdmin: FC = () => {
  // TODO: rbac for admin
  const { user } = useAuthContext();

  return (
    <ClientContextProvider clientId={user?.clientId ?? ''}>
      <Outlet />
    </ClientContextProvider>
  );
};
