import { gql, useQuery } from '@apollo/client';
import { Badge, Descriptions, Divider, Typography } from 'antd';
import { useClientContext } from 'components/client-context-provider';
import { NuButton, NuCard, NuCardContent, NuPageHeader } from 'components/nuspire';
import InfiniteTable from 'components/nuspire/infinite-table';
import { Reload } from 'components/nuspire/nu-icon';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import cronstrue from 'cronstrue';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { client } from 'utils/graphql';
import { ClientConnection } from '../../connector-detail';

const SCHEDULE_LIST_ITEM = gql`
  query ScheduleListItem($clientId: String!, $id: String!) {
    schedule(clientId: $clientId, id: $id) {
      id
      cron
      enabled

      action {
        id
        name
        description
      }

      scheduledTasks {
        id
        scheduledTime
        startedAt
      }
    }
  }
`;

const SCHEDULED_TASK_RESULTS = gql`
  query ScheduledTaskResults($clientId: String!, $id: String!, $startKey: JSONObject, $pageSize: Int) {
    schedule(clientId: $clientId, id: $id) {
      id

      # paginate this
      scheduledTaskResults(startKey: $startKey, pageSize: $pageSize) {
        items {
          id
          ok
          scheduledTime
          completedAt
          summary
        }
        count
        lastEvaluatedKey
      }
    }
  }
`;

interface IAction {
  id: string;
  name: string;
  description?: string;
}
interface IScheduledTask {
  id: string;
  scheduledTime: string;
  startedAt?: string;
}
interface IScheduledTaskResult {
  id: string;
  ok: boolean;
  scheduledTime: string;
  completedAt: string;
  summary?: string;
}
interface IPaginatedScheduledTaskResults {
  count: number;
  lastEvaluatedKey?: any;
  items: IScheduledTaskResult[];
}
interface ISchedule {
  id: string;
  cron: string;
  enabled: boolean;

  action: IAction;

  scheduledTasks?: IScheduledTask[];

  scheduledTaskResults?: IPaginatedScheduledTaskResults[];
}
interface IScheduleQuery {
  schedule?: ISchedule;
}

function renderDateTime(timeString: string) {
  const date = new Date(timeString);

  return `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`;
}

function ScheduledTaskResults(props: { scheduleId: string }) {
  const { clientId } = useClientContext();
  const { scheduleId } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<IScheduledTaskResult[]>([]);
  const [startKey, setStartKey] = useState<{ [key: string]: string } | undefined>();

  const fetchData = async () => {
    setLoading(true);

    const variables = {
      id: scheduleId,
      clientId,
      startKey,
      pageSize: 100,
    };

    const { data } = await client.query({
      query: SCHEDULED_TASK_RESULTS,
      variables,
      fetchPolicy: 'network-only',
    });

    const items = data?.schedule?.scheduledTaskResults?.items;
    if (items) {
      setDataSource([...dataSource, ...items]);
    }

    const lastEvaluatedKey = data?.schedule?.scheduledTaskResults?.lastEvaluatedKey;
    setStartKey(lastEvaluatedKey);

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [scheduleId, clientId]);

  const handleScroll = async () => {
    if (startKey) {
      await fetchData();
    }
  };

  const handleReload = async () => {
    setStartKey(undefined);
    setDataSource([]);

    fetchData();
  };

  return (
    <>
      <NuPageHeader level={5} title="Completed Runs" actions={<NuButton onClick={handleReload} icon={<Reload />} />} />
      <InfiniteTable
        loading={loading}
        dataSource={dataSource}
        lastId={dataSource[dataSource.length - 1]?.id}
        debug
        columns={[
          {
            title: 'Scheduled Run Time',
            dataIndex: 'scheduledTime',
            render: renderDateTime,
          },
          {
            title: 'Time Completed',
            dataIndex: 'completedAt',
            render: renderDateTime,
          },
          {
            title: 'Status',
            dataIndex: 'ok',
            render: (ok) => {
              return <Badge status={ok ? 'success' : 'error'} text={ok ? 'Success' : 'Error'} />;
            },
          },
          {
            title: 'Summary',
            dataIndex: 'summary',
          },
        ]}
        onFetch={handleScroll}
        scroll={{
          x: true as true,
          y: 750,
        }}
        rowKey="id"
      />
    </>
  );
}

function formatDate(date: Date) {
  return date
    .toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, // Set to true for 12-hour format with AM/PM
    })
    .replace(',', ' -');
}

export function getNextRunTime(tasks?: IScheduledTask[]) {
  if (!tasks?.length) {
    return '--';
  }

  const sorted = [...tasks].sort((a, b) => {
    if (a.scheduledTime > b.scheduledTime) {
      return 1;
    }
    if (b.scheduledTime < a.scheduledTime) {
      return -1;
    }

    return 0;
  });

  const next = sorted[0].scheduledTime;
  const nextRunDate = new Date(next);

  return formatDate(nextRunDate);
}
const ScheduleListItemViewRoot = styled.div`
  padding-bottom: 1rem;

  .ant-descriptions-row > th {
    padding-bottom: 8px;
  }
`;
function ScheduleListItemView(props: { schedule: ISchedule }) {
  const {
    schedule: { id: scheduleId, action, scheduledTasks, enabled, cron },
  } = props;

  const nextRunTime = getNextRunTime(scheduledTasks);

  return (
    <ScheduleListItemViewRoot>
      <NuCard>
        <NuCardContent style={{ paddingTop: '16px' }}>
          <Descriptions layout="vertical" title="Scheduled Tasks">
            {action && <Descriptions.Item label="Action">{action.name}</Descriptions.Item>}

            <Descriptions.Item label="Schedule">
              <Typography.Text
                type="secondary"
                style={{
                  flex: '1',
                }}
              >
                {cronstrue.toString(cron)}
              </Typography.Text>
            </Descriptions.Item>

            <Descriptions.Item label="Status">
              <Typography.Text type={enabled ? 'success' : 'danger'} strong style={{ flex: '1' }}>
                {enabled ? 'Enabled' : 'Disabled'}
              </Typography.Text>
            </Descriptions.Item>

            <Descriptions.Item label="Next Run">{nextRunTime}</Descriptions.Item>
          </Descriptions>
          <Divider />
          <ScheduledTaskResults scheduleId={scheduleId} />
        </NuCardContent>
      </NuCard>
    </ScheduleListItemViewRoot>
  );
}

export function ScheduleListItem(props: { scheduleId: string }) {
  const { scheduleId } = props;
  const { clientId } = useClientContext();

  const { data, loading } = useQuery<IScheduleQuery>(SCHEDULE_LIST_ITEM, { variables: { clientId, id: scheduleId } });

  const schedule = data?.schedule;

  if (schedule) {
    return <ScheduleListItemView schedule={schedule} />;
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  return null;
}

function ConnectionSchedules(props: { connection: ClientConnection }) {
  const {
    connection: { publicConnectionData },
  } = props;

  if (publicConnectionData?.schedules?.length) {
    return (
      <>
        {publicConnectionData.schedules.map((schedule) => {
          return <ScheduleListItem key={schedule.id} scheduleId={schedule.id} />;
        })}
      </>
    );
  }

  return null;
}

export default ConnectionSchedules;
