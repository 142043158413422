import { automationsPath as automationsSettingsPath } from 'components/settings/paths';

type AutomationPathArgs = { clientId: string };

export const automationsPath = ({ clientId }: AutomationPathArgs) => {
  return automationsSettingsPath({ clientId });
};

export const exploreAutomationsPath = (a: AutomationPathArgs) => `${automationsPath(a)}/explore`;

export const automationDefinitionDetailsPath = (args: AutomationPathArgs & { slug: string }) =>
  `${automationsPath(args)}/def/${args.slug}`;

export const automationListPath = (args: AutomationPathArgs) => `${automationsPath(args)}/instances`;

export const automationDetailPath = (args: AutomationPathArgs & { id: string; new?: boolean }) =>
  `${automationsPath(args)}/instances/${args.id}${args.new ? '?new=true' : ''}`;

export const createAutomationPath = (args: AutomationPathArgs & { slug: string; connectionId?: string }) => {
  const { connectionId, slug } = args;

  let path = `${automationsPath(args)}/add?def=${slug}`;

  if (connectionId) {
    path += `&cid=${connectionId}`;
  }

  return path;
};
