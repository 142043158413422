import { gql, useQuery, useMutation } from '@apollo/client';
import { Modal, Form, Typography, Select, Result } from 'antd';
import { NuButton } from 'components/nuspire';
import { Formik, FormikContextType } from 'formik';
import ActionFormField from 'components/cyberx/actions/action-form/action-form-field';
import { ChangeHandler } from 'components/widgets/widget-editor/widget-editor';
import { useState } from 'react';
import { SCHEDULE_TASK } from 'components/admin/schedules/scheduled-tasks/scheduled-tasks';
import { TOGGLE_SCHEDULE } from 'components/reports/scheduled-reports';
import { GraphQLFormattedError } from 'graphql';
import baseTheme from 'components/theme';
import styled from 'styled-components';

const GET_SCHEDULE_TASK_DETAILS = gql`
  query GetScheduleTaskDetails($id: String!, $clientId: String) {
    action(id: $id, clientId: $clientId) {
      id
      name
      fields {
        key
        label
        type
        jsonSchema
        required
        editable
        inputType
        parameters
        inputType
      }
    }

    schedules(clientId: $clientId, executableSlug: $id) {
      id
      name
      description
      action {
        name
      }
      executableSlug
      clientId
      client {
        id
        name
      }
      cron
      enabled
      scheduledTasks {
        id
      }
    }
  }
`;

const RetryLink = styled(Typography.Text)`
  display: block;
  text-align: center;
  color: ${baseTheme.color.nuspireBlue};
  margin-top: 1rem;

  &:hover {
    cursor: pointer;
  }
`;

export function ScheduleTaskModal(props: {
  taskSlug: string;
  clientId?: string;
  open: boolean;
  onCancel: () => void;
  showTitle?: boolean;
  initialValues?: { [key in string]: any };
}) {
  const { taskSlug, clientId, open, onCancel, showTitle = true, initialValues } = props;
  const { data, loading } = useQuery(GET_SCHEDULE_TASK_DETAILS, {
    variables: { id: taskSlug, clientId },
  });
  const [scheduleTask, { loading: scheduleTaskLoading }] = useMutation(SCHEDULE_TASK);
  const [toggleSchedule, { loading: toggleScheduleLoading }] = useMutation(TOGGLE_SCHEDULE);
  const [form] = Form.useForm();

  const [showStatus, setShowStatus] = useState<{
    show: boolean;
    status?: 'error' | 'success';
    errors?: GraphQLFormattedError[];
  }>({
    show: false,
  });

  const [configuration, setConfiguration] = useState<any>({});
  const handleChange = (newConfiguration: any) => setConfiguration(newConfiguration);

  const [_formikContext, setFormikContext] = useState<FormikContextType<any> | undefined>(undefined);
  const handleFormikContext = (ctx: FormikContextType<any>) => setFormikContext(ctx);

  const onFinish = async (values) => {
    try {
      // we need to remove any scheduled tasks for this schedule before we can run now
      const { errors: toggleScheduleErrors = [] } = await toggleSchedule({
        variables: {
          id: values.schedule,
          input: {
            enabled: false,
          },
        },
      });
      const { errors: scheduleTaskErrors = [] } = await scheduleTask({
        variables: {
          scheduleId: values.schedule,
          configuration,
          runNow: true,
          scheduleNext: true,
        },
      });

      const errors = [...scheduleTaskErrors, ...toggleScheduleErrors];

      if (errors?.length) {
        console.log({ errors });
        setShowStatus({
          show: true,
          status: 'error',
          errors: [...errors],
        });
      } else {
        form.resetFields();
        setShowStatus({
          show: true,
          status: 'success',
        });
      }
    } catch (error) {
      setShowStatus({
        show: true,
        status: 'error',
        errors: [error],
      });
    }
  };

  return (
    <Modal
      title="Schedule Task"
      okText="Run Now"
      open={open}
      footer={false}
      onCancel={() => {
        onCancel();
        setShowStatus({
          show: false,
        });
      }}
    >
      {!loading && !showStatus.show ? (
        <Formik initialValues={initialValues ?? {}} onSubmit={async (_values, _helpers) => {}}>
          {(formik) => {
            return (
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={{ schedule: data?.schedules[0]?.id, ...initialValues }}
              >
                <ChangeHandler handleFormikContext={handleFormikContext} onChange={handleChange} />
                <Form.Item name="schedule" label="Schedule">
                  <Select>
                    {data?.schedules.map((schedule) => (
                      <Select.Option
                        value={schedule.id}
                      >{`${schedule?.name ?? schedule?.action?.name} (${schedule?.client?.name})`}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                {data?.action?.fields?.length ? (
                  <div>
                    {showTitle && <Typography.Title level={4}>{data?.action?.name} configuration</Typography.Title>}
                    {data?.action?.fields?.map((f) => (
                      <ActionFormField field={f} formik={formik} supportOperators={false} key={f.key} />
                    ))}
                  </div>
                ) : null}
                <NuButton
                  type="primary"
                  htmlType="submit"
                  loading={loading || scheduleTaskLoading || toggleScheduleLoading}
                >
                  Run Task
                </NuButton>
              </Form>
            );
          }}
        </Formik>
      ) : null}

      {showStatus.show ? (
        <Result status={showStatus?.status}>
          {showStatus.status === 'success' ? (
            <>
              <Typography.Text>
                Task Scheduled successfully. The results may take a few minutes to show up in the results table.
              </Typography.Text>
            </>
          ) : (
            <>
              <Typography.Text>Failed to schedule task. Errors:</Typography.Text>
              <ul>{showStatus.errors?.map((error) => <li>{error.message}</li>)}</ul>
              <RetryLink
                onClick={() => {
                  setShowStatus({ show: false });
                }}
              >
                Retry Request
              </RetryLink>
            </>
          )}
        </Result>
      ) : null}
    </Modal>
  );
}
