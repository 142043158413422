import { AliasToken, OverrideToken } from 'antd/es/theme/interface';
import * as NuIcon from 'components/nuspire/nu-icon';
import { createGlobalStyle } from 'styled-components';
import merge from 'lodash.merge';

export const themeModes = ['light', 'dark', 'pdi-light', 'pdi-dark'] as const;
export type ThemeMode = (typeof themeModes)[number];

export const GlobalStyle = createGlobalStyle`
  body {
    
    font-family: ${(p) => {
      if (['pdi-light', 'pdi-dark'].includes(p.theme.mode)) return `Arial, Verdana, sans-serif;`;

      return `Helvetica Neue, Verdana, sans-serif;`;
    }}
    margin: 0;
  }

  a {
    color: ${(p) => p.theme.token.colorText};
    &:hover {
      color: ${(p) => p.theme.color.nuspireBlue};
    }
  }

  .ant-layout {
    min-height: 100vh !important;
    min-width: 0;
  }

  .ant-layout-header {
    padding: 0 !important;
    line-height: normal;
    height: auto;
  }

  .ant-layout-footer {
    padding: 0 !important;
  }

  .ant-layout.ant-layout-has-sider > .ant-layout,
  .ant-layout.ant-layout-has-sider > .ant-layout-content {
    overflow-x: initial !important;
  }

  .ant-notification {
    max-width: calc(100vw - 48px);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    &,
    &.ant-typography {

      ${(p) => {
        if (['pdi-light', 'pdi-dark'].includes(p.theme.mode)) {
          return `font-family: DM Sans, Arial, Verdana, sans-serif`;
        }
        return ``;
      }}
    }
  }
`;

// export const headingText1 = '#232A4E';
export const headingText2 = '#363636';
export const headingText3 = '#696969';

// nuspire color palette according to Feb 2022 Brand Guide
const brandColors = {
  black: '#000',
  nuspireBlue: '#3b78e6',
  slate: '#4b5e77',
  jade: '#00c46f',
  indigo: '#07003d',
  breeze: '#c6d6f4',
  iceGrey: '#f2f2f2',
  sunYellow: '#f9e26d',
  orange: '#ed8c00',
  alertRed: '#eb3d2e',
} as const;

/*
 * this is where we override antd theme variables
 * @see {@link https://ant.design/docs/react/customize-theme}
 */
const token: Partial<AliasToken> = {
  fontFamily: 'Helvetica Neue, Verdana, sans-serif',

  colorPrimary: brandColors.nuspireBlue, // #c6d6f4

  colorPrimaryHover: brandColors.slate,
  colorError: brandColors.alertRed,
  colorErrorHover: brandColors.slate,
  colorSuccess: brandColors.jade,
  colorWarning: brandColors.orange,
  colorInfo: brandColors.nuspireBlue,
  colorLink: brandColors.nuspireBlue,
};

const componentOverrides: OverrideToken = {
  Typography: {
    titleMarginBottom: '0.5em',
  },
  Segmented: {
    itemSelectedBg: brandColors.nuspireBlue,
  },
};

export const baseTheme = {
  color: {
    primary: brandColors.nuspireBlue,
    primaryGray: brandColors.iceGrey,
    success: brandColors.jade,
    error: brandColors.alertRed,
    slate: brandColors.slate,
    breeze: brandColors.breeze,
    indigo: brandColors.indigo,

    headingText1: '#30475E',
    headingText2,

    // primary palette
    primaryBlue: brandColors.nuspireBlue,
    black: '#000',

    // secondary palette
    nuspireBlue: brandColors.nuspireBlue,
    nuspireBlue300: '#90B4E0',
    teal: '#18B0B7',
    teal300: '#74CFD3',

    // tertiary:
    slateBlue: '#30475E',
    slateBlue300: '#5982AA',
    slateBlue100: '#9AB3CC',
    softYellow: '#F7E05F',
    softYellow300: '#9AB3CC',
    green: brandColors.jade,
    green300: '#8FDEA1',
    orange: brandColors.orange,
    red: brandColors.alertRed,
    riverBlue: '#3498db',
    yellow: brandColors.sunYellow,
    mediumBlue: '#7fafea',

    purple: '#531DAB',
    purple300: '#8241EC',

    midnight: '#2c3e50',
    midnight300: '#34495e',

    // grays
    gray1: '#f2f4f8',
    gray2: '#e4e9f0',
    gray3: '#d9dee9',
    gray4: '#c8c4db',
    gray5: '#a1a1c2',
    gray6: '#30475E',
  },
  palette: {
    // palette only contains 4 colors in order of priority
    [`priority`]: [brandColors.alertRed, brandColors.orange, brandColors.sunYellow, brandColors.jade],
    [`common-chart`]: [
      brandColors.nuspireBlue,
      brandColors.slate,
      brandColors.breeze,
      brandColors.jade,
      brandColors.orange,
      brandColors.indigo,
      brandColors.sunYellow,
      'rgb(215,0,217)',
      'rgb(69,162,255)',
      'rgb(81,130,255)',
      'rgb(43,6,160)',
      'rgb(175,142,239)',
      'rgb(94,227,234)',
      'rgb(79,89,98)',
      'rgb(149,158,161)',
      'rgb(198,202,203)',
      'rgb(81,130,155)',
      'rgb(200,250,150)',
      'rgb(150,50,90)',
      'rgb(100,150,150)',
      'rgb(150,90,150)',
      'rgb(60,60,255)',
      'rgb(255,90,150)',
      'rgb(100,130,255)',
      'rgb(70,80,90)',
      'rgb(53,150,150)',
      'rgb(255,130,255)',
      'rgb(100,150,100)',
      'rgb(100,100,255)',
      'rgb(0,0,0)',
      'rgb(200,100,45)',
      'rgb(34,200,67)',
      'rgb(78,100,170)',
      'rgb(110,42,240)',
      'rgb(200,40,200)',
    ],
  },
  bp: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1600px',
  },
  bpInt: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
  },
  token,
  componentOverrides,
} as const;

export const lightModeTheme = merge({}, baseTheme, {
  token: {
    colorBgElevated: baseTheme.color.gray1,
  } satisfies Partial<AliasToken>,
  componentOverrides: {
    Segmented: {
      colorBgLayout: baseTheme.color.gray2,
      itemSelectedColor: 'white',
    },
  } satisfies OverrideToken,
});

export const darkModeTheme = merge({}, baseTheme, {
  token: {} satisfies Partial<AliasToken>,
});

const pdiColors = {
  // primary
  indigo: '#07003D',
  indigo300: '#1c154b',

  // secondary (use in combination with primary color)
  sky: '#29B5E8',
  sky300: '#00d2f4',

  plum: '#A1007D',
  ocean: '#1226AA',
  grass: '#029F50',

  // product colors (https://developer.pdisoftware.com/v14/foundation/color)
  action: '#0F65F0', // blue
  success: '#00C759',
  alert: '#C70F2B',
  sun: '#FFB71B',
  orange: '#FF5100',

  // consumer brands pallette (accent color)
  coral: '#EE5340',
  teal: '#0097A9',
  berry: '#D2295A',
  mint: '#00B482',

  // neutrals
  coolGray: '#D6D8D6',
  slate: '#8E9C9C',
  darkSlate: '#686F6F',
  granite: '#323E48',
  sand: '#F7F0E7',
  white: '#FFFFFF',

  indigoFooter: '#122048',
  // special accent colors
  // storm: '#249EDC',

  containerLightBg: '#fff',
  containerDarkBg: '#f7f9fb',
} as const;

const pdiComponentOverrides: OverrideToken = {
  Typography: {
    titleMarginBottom: '0.5em',
  },
  Segmented: {
    itemSelectedBg: brandColors.nuspireBlue,
  },
  Layout: {
    siderBg: pdiColors.indigo,
  },
  Menu: {
    darkItemBg: pdiColors.indigo,
    darkItemSelectedBg: 'rgb(255 255 255 / 10%)',
    darkItemSelectedColor: pdiColors.sky300,
    darkItemColor: pdiColors.white,
    darkSubMenuItemBg: pdiColors.indigo300,
  },
};

/*Helvetica Neue,
 * this is where we override antd theme variables
 * @see {@link https://ant.design/docs/react/customize-theme}
 */
const pdiToken: Partial<AliasToken> = {
  fontFamily: 'Arial, Verdana, sans-serif',
  colorPrimary: pdiColors.action,
  colorPrimaryHover: pdiColors.slate,
  colorError: pdiColors.alert,
  colorErrorHover: pdiColors.slate,
  colorSuccess: pdiColors.success,
  colorWarning: pdiColors.orange,
  colorInfo: pdiColors.action,
  colorLink: pdiColors.action,
};

export const pdiBaseTheme = {
  color: {
    primary: pdiColors.action,
    primaryGray: pdiColors.coolGray,
    success: pdiColors.success,
    error: pdiColors.alert,
    slate: pdiColors.slate,

    // check to see where these are being used.
    breeze: brandColors.breeze,
    indigo: brandColors.indigo,

    headingText1: pdiColors.slate,
    headingText2,

    // primary palette
    primaryBlue: pdiColors.action,

    black: '#000',

    // secondary palette
    nuspireBlue: pdiColors.action, // legacy...
    nuspireBlue300: '#90B4E0',
    teal: pdiColors.sky,
    teal600: '#0a97eb',
    teal300: '#74CFD3',

    // tertiary:
    slateBlue: pdiColors.granite,
    slateBlue300: '#5982AA',
    slateBlue100: '#9AB3CC',
    softYellow: '#F7E05F',
    softYellow300: '#9AB3CC',
    green: pdiColors.grass,
    green300: '#8FDEA1',
    orange: pdiColors.orange,
    red: pdiColors.alert,
    riverBlue: '#3498db',
    yellow: pdiColors.sun,
    mediumBlue: '#7fafea',

    purple: '#531DAB',
    purple300: '#8241EC',

    midnight: '#2c3e50',
    midnight300: '#34495e',

    // grays
    gray1: '#f2f4f8',
    gray2: '#e4e9f0',
    gray3: '#d9dee9',
    gray4: '#c8c4db',
    gray5: '#a1a1c2',
    gray6: '#30475E',
  },
  palette: {
    // palette only contains 4 colors in order of priority
    [`priority`]: [
      pdiColors.alert,
      pdiColors.orange,
      pdiColors.coral,
      pdiColors.sun,
      pdiColors.plum,
      pdiColors.indigo,
      pdiColors.granite,
    ],
    [`common-chart`]: [
      pdiColors.action,
      pdiColors.slate,
      pdiColors.sky,
      pdiColors.success,
      pdiColors.orange,
      pdiColors.indigo,
      pdiColors.sun,
      'rgb(215,0,217)',
      'rgb(69,162,255)',
      'rgb(81,130,255)',
      'rgb(43,6,160)',
      'rgb(175,142,239)',
      'rgb(94,227,234)',
      'rgb(79,89,98)',
      'rgb(149,158,161)',
      'rgb(198,202,203)',
      'rgb(81,130,155)',
      'rgb(200,250,150)',
      'rgb(150,50,90)',
      'rgb(100,150,150)',
      'rgb(150,90,150)',
      'rgb(60,60,255)',
      'rgb(255,90,150)',
      'rgb(100,130,255)',
      'rgb(70,80,90)',
      'rgb(53,150,150)',
      'rgb(255,130,255)',
      'rgb(100,150,100)',
      'rgb(100,100,255)',
      'rgb(0,0,0)',
      'rgb(200,100,45)',
      'rgb(34,200,67)',
      'rgb(78,100,170)',
      'rgb(110,42,240)',
      'rgb(200,40,200)',
    ],
  },
  bp: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1600px',
  },
  bpInt: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
  },
  token: pdiToken,
  componentOverrides: pdiComponentOverrides,
} as const;

export const pdiLightModeTheme = merge({}, pdiBaseTheme, {
  token: {
    colorBgElevated: pdiBaseTheme.color.gray1,
  } satisfies Partial<AliasToken>,
  componentOverrides: {
    Segmented: {
      colorBgLayout: pdiBaseTheme.color.gray2,
      itemSelectedColor: 'white',
    },
    Layout: {
      bodyBg: pdiColors.containerDarkBg,
    },
    Typography: {
      colorTextHeading: pdiColors.granite,
    },
  } satisfies OverrideToken,
});

export const pdiDarkModeTheme = merge({}, pdiBaseTheme, {
  token: {
    colorBgBase: '#0e0e13',
    colorBgContainer: '#1E1E2D',
  } satisfies Partial<AliasToken>,
  componentOverrides: {
    Layout: {
      bodyBg: '#151521',
    },
  } satisfies OverrideToken,
});

interface ProductBrand {
  slug: string;
  name: string;
  color: string;
  icon: (props: NuIcon.CustomIconComponentProps) => JSX.Element;
}

export const productBrands: {
  [key: string]: ProductBrand;
} = {
  nsp: {
    name: 'Nuspire Security Program',
    slug: 'nsp',
    color: baseTheme.color.primary,
    icon: NuIcon.NspIcon,
  },
  tmt: {
    name: 'Threats',
    slug: 'tmt',
    color: baseTheme.color.red,
    icon: NuIcon.ThreatIcon,
  },
  'case-management': {
    name: 'Case Management',
    slug: 'case-management',
    color: baseTheme.color.green,
    icon: NuIcon.CaseManagementIcon,
  },
  cxp: {
    name: 'Cyber X Platform',
    slug: 'cxp',
    color: baseTheme.color.orange,
    icon: NuIcon.CxpIcon,
  },
  insights: {
    name: 'Insights',
    slug: 'insights',
    color: baseTheme.color.slateBlue,
    icon: NuIcon.InsightsIcon,
  },
};
