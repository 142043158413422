import { theme as antdTheme } from 'antd';
import { ReactNode } from 'react';
import { ThemeProvider as BaseThemeProvider } from 'styled-components';
import { ThemeMode, baseTheme } from './theme';

const { useToken } = antdTheme;

interface ThemeProviderProps {
  themeMode: ThemeMode;
  children: ReactNode;

  // temporary, once we transition to pdi themes we will not have two separate
  // base themes. if theme === `pdi-light` or `pdi-dark` this allows us to load
  // the pdi base theme and override props in our old baseTheme.
  base?: object;
}

/*
 * We want to access antd theme token in styled-components.
 * @see {@link https://ant.design/docs/react/customize-theme}
 * This component facilitates that by:
 *
 * 1. Get the current antd theme token.
 * 2. Augment our theme with the antd token and pass it to ThemeProvider.
 *
 * We also inject the theme mode here to conveniently access it in any styled component.
 */
export function ThemeProvider(props: ThemeProviderProps) {
  const { themeMode, children, base = {} } = props;
  const { token } = useToken();

  const theme = {
    ...baseTheme,
    ...base,
    token,
    mode: themeMode,
  };

  return <BaseThemeProvider theme={theme}>{children}</BaseThemeProvider>;
}
