import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloudServerOutlined,
  DeleteOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { gql, useSubscription } from '@apollo/client';
import { Collapse, Descriptions, Drawer, Empty, Table, Tabs, type CollapseProps, type TableColumnsType } from 'antd';
import { createContext, useContext, useEffect, useState, type FC, type PropsWithChildren } from 'react';
import { useTheme } from 'styled-components';
import type { IUser } from '../types';
import type {
  DebugPayload,
  DebugRequestsSubscription,
  DebugRequestsSubscriptionVariables,
} from '../types/graph-codegen/graph-types';
import { useAuthContext } from './auth-context';
import { NuButton, Spacer } from './nuspire';
import { CopyToClipboardIcon } from './shared-components';

export const DEBUG_SUBSCRIPTION = gql`
  subscription DebugRequests($requestIds: [String!]!) {
    debugRequests(requestIds: $requestIds) {
      request {
        body
        headers
        method
        origin
        pathname
        search
      }
      requestHash
      requestId
      response {
        body
        headers
        statusCode
      }
    }
  }
`;

type CreateDebugRequestIdArgs = {
  prefix?: string;
  suffix?: string;
  user?: IUser;
};

export const createDebugRequestId = (args: CreateDebugRequestIdArgs): string => {
  let requestId: string = '';

  if (args.prefix) {
    requestId += args.prefix;
  }

  if (args.user?.id) {
    if (requestId.length) requestId += ':';
    requestId += args.user.id;
  }

  if (requestId.length) requestId += ':';
  requestId += `${Math.random().toString(32).substring(2, 10)}`;

  if (args.suffix) {
    if (requestId.length) requestId += ':';
    requestId += args.suffix;
  }

  return requestId;
};

type CreateLabelFromRequestIdArgs = {
  requestId: string;
  user?: IUser;
};

const renderHeaders = (headers: Record<string, any>): JSX.Element => (
  <>
    {Object.entries(headers).map(([key, value]) => (
      <p key={`${key}:${value}`}>
        <strong>{key}:</strong> {value}
      </p>
    ))}
  </>
);

const renderBody = (body: string): string => {
  try {
    const parsed = JSON.parse(body);
    return JSON.stringify(parsed, null, 2);
  } catch (err) {
    //
  }

  return body;
};

const RequestInfo: FC<{ request: DebugPayload['request'] }> = (props) => {
  const Headers = props.request?.headers ? renderHeaders(props.request.headers) : undefined;
  const Body = props.request?.body ? <code>{renderBody(props.request.body)}</code> : undefined;

  return (
    <Descriptions
      bordered
      items={[
        {
          key: 'search',
          label: (
            <>
              <span style={{ marginRight: '0.25rem' }}>Search</span>{' '}
              <CopyToClipboardIcon copyText={props.request?.search ?? ''} />
            </>
          ),
          span: 3,
          children: props.request?.search,
        },
        {
          key: 'headers',
          label: 'Headers',
          span: 3,
          children: Headers,
        },
        {
          key: 'body',
          label: (
            <>
              <span style={{ marginRight: '0.25rem' }}>Body</span>
              <CopyToClipboardIcon copyText={props.request?.body ?? ''} />
            </>
          ),
          span: 3,
          children: Body,
        },
      ]}
    />
  );
};

const ResponseInfo: FC<{ response: DebugPayload['response'] }> = (props) => {
  const Headers = props.response?.headers ? renderHeaders(props.response.headers) : undefined;
  const Body = props.response?.body ? <code>{renderBody(props.response.body)}</code> : undefined;

  return (
    <Descriptions
      bordered
      items={[
        {
          key: 'headers',
          label: 'Headers',
          span: 3,
          children: Headers,
        },
        {
          key: 'body',
          label: (
            <>
              <span style={{ marginRight: '0.25rem' }}>Body</span>
              <CopyToClipboardIcon copyText={props.response?.body ?? ''} />
            </>
          ),
          span: 3,
          children: Body,
        },
      ]}
    />
  );
};

type RequestTableProps = {
  requestId: string;
  payloads?: DebugPayload[];
};

const RequestTable: FC<RequestTableProps> = (props) => {
  const theme = useTheme();

  const expandedRowRender = (record: DebugPayload) => {
    return (
      <Tabs
        defaultActiveKey="request"
        items={[
          {
            key: 'request',
            label: 'Request',
            children: <RequestInfo request={record.request} />,
          },
          {
            key: 'response',
            label: 'Response',
            children: <ResponseInfo response={record.response} />,
          },
        ]}
      />
    );
  };

  const columns: TableColumnsType<DebugPayload> = [
    {
      key: 'request',
      dataIndex: 'request',
      title: 'Request',
      render(request?: DebugPayload['request']) {
        if (!request?.origin) {
          return 'Unknown';
        }

        const url = new URL(`${request.origin}${request.pathname}`);

        return (
          <span>
            <strong>{request.method}</strong> {url.toString()}
          </span>
        );
      },
    },
    {
      key: 'response',
      dataIndex: 'response',
      title: 'Response',
      render(response?: DebugPayload['response']) {
        if (!response?.statusCode) {
          return 'Unknown';
        }

        if (response.statusCode >= 400) {
          return (
            <>
              <CloseCircleOutlined style={{ color: theme.color.error }} />
              <span style={{ marginLeft: '0.25rem' }}>{response.statusCode}</span>
            </>
          );
        }

        return (
          <>
            <CheckCircleOutlined style={{ color: theme.color.success }} />
            <span style={{ marginLeft: '0.25rem' }}>{response.statusCode}</span>
          </>
        );
      },
    },
  ];

  return (
    <Table
      rowKey="requestHash"
      columns={columns}
      expandable={{ expandedRowRender, expandRowByClick: true }}
      dataSource={props.payloads}
      pagination={false}
    />
  );
};

const Content: FC = () => {
  const debugCtx = useDebugRequests();
  const [payloads, setPayloads] = useState(new Map<string, DebugPayload[]>());
  const { data } = useSubscription<DebugRequestsSubscription, DebugRequestsSubscriptionVariables>(DEBUG_SUBSCRIPTION, {
    skip: debugCtx.requestIds.length === 0,
    variables: {
      requestIds: debugCtx.requestIds,
    },
  });

  useEffect(() => {
    if (!data?.debugRequests) {
      return;
    }

    const newPayloads = new Map(payloads);

    const existing = newPayloads.get(data.debugRequests.requestId);

    let entry: DebugPayload[];
    if (!existing) {
      // New entry
      entry = [data.debugRequests];
    } else {
      // Update existing entry

      const idx = existing.findIndex((i) => i.requestHash === data.debugRequests?.requestHash);
      if (idx === -1) {
        entry = [...existing, data.debugRequests];
      } else {
        existing[idx] = {
          request: data.debugRequests.request ?? existing[idx]?.request,
          requestHash: data.debugRequests.requestHash,
          requestId: data.debugRequests.requestId,
          response: data.debugRequests.response ?? existing[idx]?.response,
        };
        entry = [...existing];
      }
    }

    newPayloads.set(data.debugRequests.requestId, entry);

    setPayloads(new Map(newPayloads));
  }, [data]);

  const removeAll = (): void => {
    setPayloads(new Map<string, DebugPayload[]>());

    debugCtx.removeAllRequestIds();
  };

  const resetAll = (): void => {
    const newPayloads = new Map(payloads);
    for (let [rId, _] of newPayloads.entries()) {
      newPayloads.set(rId, []);
    }
    setPayloads(newPayloads);
  };

  const resetOne = (id: string): void => {
    if (!payloads.get(id)) {
      return;
    }

    const newPayloads = new Map(payloads);
    newPayloads.set(id, []);
    setPayloads(newPayloads);
  };

  const deleteOne = (id: string): void => {
    const newPayloads = new Map(payloads);
    newPayloads.delete(id);
    setPayloads(newPayloads);

    debugCtx.removeRequestId(id);
  };

  const items: CollapseProps['items'] = Array.from(
    debugCtx.requestIds.map((rId) => ({
      children: <RequestTable requestId={rId} payloads={payloads.get(rId)} />,
      extra: (
        <>
          <NuButton
            type="primary"
            danger
            icon={<SyncOutlined />}
            onClick={(e) => {
              // Prevent collapse.
              e.stopPropagation();

              resetOne(rId);
            }}
            disabled={!payloads.get(rId) || payloads.get(rId)?.length === 0}
          >
            Reset
          </NuButton>
          <NuButton
            type="default"
            danger
            icon={<DeleteOutlined />}
            onClick={(e) => {
              // Prevent collapse.
              e.stopPropagation();

              deleteOne(rId);
            }}
            style={{ marginLeft: '0.25rem' }}
          >
            Remove
          </NuButton>
        </>
      ),
      key: rId,
      label: rId,
    })),
  );

  return (
    <>
      <NuButton type="primary" danger icon={<SyncOutlined />} onClick={resetAll} disabled={payloads.size === 0}>
        Reset all
      </NuButton>
      <NuButton
        type="default"
        danger
        icon={<DeleteOutlined />}
        onClick={removeAll}
        disabled={items.length === 0}
        style={{ marginLeft: '0.25rem' }}
      >
        Remove all
      </NuButton>
      <Spacer />

      {items.length ? <Collapse items={items} /> : <Empty description="No requests to debug" />}
    </>
  );
};

export const DebugDrawerAndButton: FC = () => {
  const { isDrawerOpen, setIsDrawerOpen } = useDebugRequests();

  return (
    <>
      <NuButton
        type="text"
        onClick={() => setIsDrawerOpen(true)}
        icon={<CloudServerOutlined />}
        shape="circle"
        size="large"
      />

      <Drawer
        title="Debug Requests"
        placement="bottom"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        size="large"
        height="75%"
        forceRender
      >
        <Content />
      </Drawer>
    </>
  );
};

type DebugRequestsContextParams = {
  addRequestId: (id?: string) => void;
  canDebugRequests: boolean;
  hasRequestId: (id?: string) => boolean;
  isDrawerOpen: boolean;
  requestIds: string[];
  removeAllRequestIds: () => void;
  removeRequestId: (id?: string) => void;
  setIsDrawerOpen: (value: boolean) => void;
};

export const DebugRequestsContext = createContext<DebugRequestsContextParams>({
  addRequestId: (_) => {},
  canDebugRequests: false,
  hasRequestId: (_) => false,
  isDrawerOpen: false,
  requestIds: [],
  removeAllRequestIds: () => {},
  removeRequestId: (_) => {},
  setIsDrawerOpen: (_) => {},
});

export const DebugRequestsProvider: FC<PropsWithChildren> = (props) => {
  const { isCompanyEmail } = useAuthContext();
  const [requestIds, setRequestIds] = useState<string[]>([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const addRequestId = (id?: string): void => {
    if (typeof id === 'undefined') {
      return;
    }

    // Handle duplicates
    const found = requestIds.findIndex((i) => i === id);
    if (found >= 0) {
      return;
    }

    const newIds: string[] = [...requestIds, id];
    setRequestIds(newIds);
  };

  const removeRequestId = (id?: string): void => {
    if (typeof id === 'undefined') {
      return;
    }

    const filtered: string[] = requestIds.filter((i) => i !== id);
    setRequestIds(filtered);
  };

  const ctx: DebugRequestsContextParams = {
    addRequestId,
    canDebugRequests: isCompanyEmail,
    hasRequestId(id): boolean {
      if (typeof id === 'undefined') {
        return false;
      }
      return requestIds.includes(id);
    },
    isDrawerOpen,
    requestIds,
    removeAllRequestIds() {
      setRequestIds([]);
    },
    removeRequestId,
    setIsDrawerOpen,
  };

  return <DebugRequestsContext.Provider value={ctx}>{props.children}</DebugRequestsContext.Provider>;
};

export const useDebugRequests = () => useContext(DebugRequestsContext);
