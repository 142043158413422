import { useMutation } from '@apollo/client';
import { useOktaAuth } from '@okta/okta-react';
import { Alert, Collapse, Form, Result, Typography } from 'antd';
import { NuButton } from 'components/nuspire';
import { NuspireIcon } from 'components/nuspire/nu-icon';
import { FormikInput } from 'components/shared-components';
import baseTheme from 'components/theme';
import { Formik } from 'formik';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router';
import styled from 'styled-components';
import * as yup from 'yup';
import { MAKE_CONNECTION_MUTATION } from '..';
import { ConnectionInstructionsWrapper } from '../index';
import { decodeOAuthState } from '../utils/oauth';
import connector from './prisma-cloud';

const validationSchema = yup.object().shape({
  accessKeyId: yup.string().required(),
  secretKey: yup.string().required(),
  baseURL: yup.string().url().required(),
  name: yup.string().required(),
});

interface IAuthFormValues {
  accessKeyId: string;
  secretKey: string;
  baseURL: string;
  name: string;
  testConnection: boolean;
}

interface IPrismaCloudAuthFormProps {
  onSubmit: (args: {
    values: IAuthFormValues;
    setSubmitting: (isSubmitting: boolean) => void;
    setError: (value: string) => void;
  }) => Promise<void>;
}

function PrismaCloudAuthForm({ onSubmit }: IPrismaCloudAuthFormProps) {
  const [error, setError] = useState<string | undefined>();

  return (
    <Formik
      initialValues={{
        accessKeyId: '',
        secretKey: '',
        baseURL: '',
        name: '',
        testConnection: true,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit({ values, setSubmitting, setError });
      }}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting, errors, dirty }) => (
        <Form
          layout="vertical"
          onFinish={() => {
            submitForm();
          }}
        >
          {error ? <Alert type="error" message={error} /> : null}

          <FormikInput
            name="name"
            label="API Client Name"
            required
            tooltip="This will be used in myNuspire when referencing this API client."
          />
          <FormikInput name="accessKeyId" label="Access Key ID" required tooltip="Access Key ID" />
          <FormikInput name="secretKey" label="Secret Key" required tooltip="Secret Key" />
          <FormikInput
            name="baseURL"
            label="Instance URL"
            required
            tooltip="Ex: https://api2.prismacloud.io"
            placeholder="Ex: https://api2.prismacloud.io"
          />

          <FormikInput name="testConnection" checkbox>
            Test credentials before creation
          </FormikInput>
          <Form.Item>
            <NuButton
              type="primary"
              htmlType="submit"
              disabled={!dirty || Object.keys(errors).length > 0 || isSubmitting}
              loading={isSubmitting}
            >
              Submit
            </NuButton>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
}

function PrismaCloudConnectionInstructions() {
  return (
    <Collapse style={{ marginBottom: '32px' }} defaultActiveKey="instructions">
      <Collapse.Panel key="instructions" header="API Token Instructions">
        <ConnectionInstructionsWrapper>
          <Typography.Paragraph>
            myNuspire leverages the Prisma Cloud REST APIs which are authenticated with a Access Key ID and a Secret
            Key. Follow the instructions below in order to generate a Access Key ID and Secret Key within the Prisma
            Cloud Hub in order to create a Connection.
          </Typography.Paragraph>

          <Typography.Title level={4}>Generating your Auth Information</Typography.Title>

          <Typography.Paragraph>
            To generate a new API Client, login to your Prisma Cloud dashboard. Navigate to Settings -&gt; Access Keys
            -&gt; Add Access Key. Enter the information for the new client, and click create. Prisma Cloud will present
            you with the Access Key ID and Secret Key. To get the instance URL grab the url from the dashboard up to .io
            and replace &quot;app&quot; with &quot;api&quot; (example: https://app2.prismacloud.io -&gt;
            https://api2.prismacloud.io). The role associated with these credentials must be a system admin, account
            group admin or account and cloud provisioning admin for the account.
          </Typography.Paragraph>
        </ConnectionInstructionsWrapper>
      </Collapse.Panel>
    </Collapse>
  );
}

const Layout = styled.div``;

const Header = styled.div`
  padding: 16px 32px;
  border-bottom: 1px solid ${(p) => p.theme.token.colorBorder};
`;

const Content = styled.div`
  padding: 16px 32px;
`;

export default function PrismaCloudRedirect() {
  const location = useLocation();
  const search = location.search;
  const { authState: oktaAuthState } = useOktaAuth();

  const [makeConnection] = useMutation(MAKE_CONNECTION_MUTATION);
  const [success, setSuccess] = useState<boolean>(false);

  const stateString = new URLSearchParams(search).get('state');
  const authState = decodeOAuthState(stateString);

  if (!oktaAuthState?.isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return (
    <Layout>
      <Header>
        <Typography.Title level={2} style={{ marginBottom: 0 }}>
          <NuspireIcon style={{ color: baseTheme.color.primaryBlue, marginRight: '8px' }} />
          Connect to Prisma Cloud
        </Typography.Title>
      </Header>
      <Content>
        {!success ? (
          <>
            <PrismaCloudConnectionInstructions />

            <Typography.Title level={3}>Create Connection</Typography.Title>
            <PrismaCloudAuthForm
              onSubmit={async ({ values, setSubmitting, setError }) => {
                const { testConnection, ...payload } = values;
                const { clientId } = authState;

                if (!clientId) {
                  throw new Error('No client id was passed');
                }

                const { data, errors } = await makeConnection({
                  variables: {
                    connectorSlug: connector.slug,
                    payloadJson: JSON.stringify(payload),
                    clientId,
                    testConnection,
                  },
                });

                setSubmitting(false);

                if (errors?.length) {
                  setError(errors[0].message);
                  return;
                }

                const newConnection = data?.makeConnection;

                if (newConnection && !errors) {
                  const newEvent = new CustomEvent(`new-connection-${connector.slug}`, {
                    detail: {
                      connection: newConnection,
                    },
                  });

                  window?.opener?.dispatchEvent(newEvent);

                  setSuccess(true);
                }
              }}
            />
          </>
        ) : (
          <Result status="success" title="Connection was successfully created!" />
        )}
      </Content>
    </Layout>
  );
}
