import { SearchOutlined } from '@ant-design/icons';
import { App, Checkbox, Typography, message } from 'antd';
import { Features, isMyNuspireDev, useFeatureFlag } from 'components/feature-flags';
import { NuButton } from 'components/nuspire';
import { useState } from 'react';
import { DataTypeInput, useDataExplorerContext } from './data-explorer';
import { DATA_EXPLORER_BYPASS_SIZE_WARNING_KEY } from '../../localstorage';
import { useAuthContext } from 'components/auth-context';
import { config } from 'config';

export function BypassWarning() {
  const bypass = localStorage.getItem(DATA_EXPLORER_BYPASS_SIZE_WARNING_KEY);
  const [isBypassing, setIsBypassing] = useState<boolean>(bypass === 'true');

  const handleChange = (e) => {
    const checked = e.target.checked;
    localStorage.setItem(DATA_EXPLORER_BYPASS_SIZE_WARNING_KEY, `${checked}`);
    setIsBypassing(checked);
  };

  return (
    <Checkbox onChange={handleChange} checked={isBypassing}>
      Please do not remind me again.
    </Checkbox>
  );
}

export function SearchButton(props: { onClick?: () => void }) {
  const dataExplorerContext = useDataExplorerContext();
  const { modal } = App.useApp();

  const searchFunction = dataExplorerContext?.searchFunction;
  const dataExplorerLoading = dataExplorerContext?.loading;
  const authContext = useAuthContext();
  const bypassEnabled = config.isDev || isMyNuspireDev({ user: authContext?.user });

  const handleClick = () => {
    const bypassWarning = localStorage.getItem(DATA_EXPLORER_BYPASS_SIZE_WARNING_KEY) === 'true';
    // see if any filters have been applied (other than time).
    const filters = Object.keys(dataExplorerContext?.searchParamsInterface?.parsed ?? {}).filter(
      (key) => key !== 'time',
    );

    if (!bypassWarning && !filters?.length) {
      modal.confirm({
        title: 'Unfiltered Search Warning',
        content: (
          <>
            <Typography.Paragraph>
              Be careful when searching without using filters, as results with more than 50k records will likely perform
              poorly. Use the count widget as a guide to know when to refine your search.
            </Typography.Paragraph>
            {bypassEnabled && <BypassWarning />}
          </>
        ),
        okText: 'Search',
        onOk: () => {
          search();
        },
      });
    } else {
      search();
    }
  };

  type IMissingRequiredInput = {
    [key: string]: DataTypeInput[number];
  };

  const search = (): void => {
    try {
      // i do not understand the naming, but i think filter, input, and filter input all effectively mean the same thing?
      // it is the definition of an input (which is exposed as "Filters" to the user)
      const inputValues = dataExplorerContext?.searchParamsInterface?.parsed ?? {};
      const inputs = dataExplorerContext?.dataType?.filterInputs ?? [];

      // which inputs are required and missing a value?
      const missingRequiredInputs = inputs.reduce((accumulator, input) => {
        if (input.required && !inputValues[input.key]) {
          accumulator[input.key] = input;
        }
        return accumulator;
      }, {} as IMissingRequiredInput);

      const requiredInputs = Object.values(missingRequiredInputs) ?? [];
      if (requiredInputs.length > 0) {
        requiredInputs.forEach((input) => {
          message.warning(`Missing "${input?.label}". Please fill out the required filters and try again.`);
        });

        dataExplorerContext?.setFilterDropdownVisible(true);
        return;
      }

      // this triggers the search for whatever data type is selected
      searchFunction?.();

      // this fires the callback on the search button so we can do other stuff
      props.onClick?.();
    } catch (err) {
      console.error(err); // this won't throw, as the client.query call is made over in data-explorer.tsx#
      message.error(err.message);
    }
  };

  return (
    <NuButton
      type="primary"
      size="large"
      onClick={handleClick}
      disabled={!searchFunction}
      loading={dataExplorerLoading}
      icon={<SearchOutlined />}
    >
      Search
    </NuButton>
  );
}
