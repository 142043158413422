import { Divider, Space } from 'antd';
import { useAuthContext } from 'components/auth-context';
import { CenteredContent } from 'components/centered-content';
import { useClientContext } from 'components/client-context-provider';
import { EmptyState } from 'components/nuspire';
import PageHeader from 'components/nuspire/nu-page-header';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { Navigate } from 'react-router';
import { Content } from '../layouts/content';
import CaseManagementSection from './case-management-section/case-management-section';
import KnowledgeBaseSection from './knowledge-section/knowledge-section';
import ReportingAndAnalysisSection from './reporting-and-analysis-section/reporting-and-analysis-section';
import ServicesSection from './services-section/services-section';
import SettingsSection from './settings-section';
import { useMyNuspireThemeContext } from 'components/theme';

export default function Home() {
  const { user, loading } = useAuthContext();
  const { client, clientId } = useClientContext();
  const { theme } = useMyNuspireThemeContext();

  const title = (() => {
    let str = `Hi ${user?.firstName},`;
    if (['light', 'dark'].includes(theme)) str += ` Welcome to myNuspire`;

    return str;
  })();

  const subTitle = client ? `You are viewing the Client ${client.name}.` : undefined;

  // The Home route is not useful to nusiem clients so redirect them to their default dashboard
  const siem = client?.clientIdentifiers?.find((ci) => ci.type === 'siem')?.value;
  if (siem === 'nusiem') {
    if (!client?.serviceOfferings?.length) {
      return <Navigate to={`/${clientId}/threats/threat-brief`} replace />;
    }

    return <Navigate to={`/${clientId}/reporting-and-analysis/dashboards`} replace />;
  }

  if (user) {
    return (
      <Content>
        <CenteredContent styles={{ column: { maxWidth: '1100px' } }}>
          <PageHeader title={title} subHeading={subTitle} />

          <Space style={{ width: '100%' }} direction="vertical" size={30}>
            <CaseManagementSection clientId={clientId ?? ''} />
            <Divider />

            <ReportingAndAnalysisSection clientId={clientId ?? ''} />
            <Divider />

            <KnowledgeBaseSection clientId={clientId ?? ''} />
            <Divider />

            <ServicesSection clientId={clientId ?? ''} />
            <Divider />

            <SettingsSection clientId={clientId ?? ''} />
          </Space>
        </CenteredContent>
      </Content>
    );
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  return <EmptyState>Failed to load User. Please contact support</EmptyState>;
}
