import { ReactNode } from 'react';
import { Select, Form, Input, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { NuButton } from 'components/nuspire';
import { IAccountContact } from 'types';

const { Option } = Select;

const WatchListSelect = (props: {
  loading?: boolean;
  filterOption?;
  watchListDropdownItems: IAccountContact[];
  setWatchListDropdownItems: Function;
  value?: string[];
  onChange?: (value: string[]) => void;
}) => {
  const { loading, filterOption, watchListDropdownItems, setWatchListDropdownItems, value, onChange } = props;

  const addNewItemToWatchList = (values: any) => {
    const { newItem } = values;

    setWatchListDropdownItems([
      ...watchListDropdownItems,
      {
        id: newItem,
        email: newItem,
        name: newItem,
      },
    ]);
  };

  const watchListDropdownRender = (menu: ReactNode) => {
    return (
      <>
        {menu}
        <Divider style={{ margin: `4px 0` }} />
        <Form onFinish={addNewItemToWatchList} style={{ padding: `0.5rem` }} layout="inline">
          <Form.Item
            name="newItem"
            rules={[{ required: true, message: `An email is required!` }]}
            style={{ width: `50%` }}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <NuButton type="primary" htmlType="submit">
              <PlusOutlined /> Add email
            </NuButton>
          </Form.Item>
        </Form>
      </>
    );
  };

  return (
    <Select
      style={{ width: '100%' }}
      mode="multiple"
      disabled={loading}
      dropdownRender={watchListDropdownRender}
      filterOption={filterOption}
      value={value}
      onChange={onChange}
    >
      {watchListDropdownItems.map((c) => (
        <Option key={c.id} value={c.id}>
          {c.name} &lt;{c.email}&gt;
        </Option>
      ))}
    </Select>
  );
};

export default WatchListSelect;
