import { Col, Row, Typography } from 'antd';
import { useAuthContext } from 'components/auth-context';
import { AuthorizeFunc, useAuthorize } from 'components/authorization';
import { BreadcrumbBar } from 'components/bread-crumb-bar';
import { useClientContext } from 'components/client-context-provider';
import { NuPageHeader } from 'components/nuspire';
import LibrarySearchResult from 'components/nuspire/library-search-result';
import { Helmet } from 'react-helmet';
import { Access, IUser } from 'types';
import { Features, useFeatureFlag } from '../feature-flags';
import { Content } from '../layouts/content';
import { adminReleaseNotesPath } from './release-notes/paths';

type AdminPageListItem = {
  canViewPage: (args: {
    authorize: AuthorizeFunc;
    isCompanyEmail: boolean;
    user: IUser | undefined;
  }) => ReturnType<typeof args.authorize> | { authorized: boolean };
  description: string;
  pathname: string;
  title: string;
};

export const adminPages: AdminPageListItem[] = [
  {
    title: 'Clients',
    pathname: '/admin/clients',
    description: 'Manage Nuspire clients',
    canViewPage: (args) =>
      args.authorize({
        requiredPermissions: { allClients: Access.all },
      }),
  },
  {
    title: 'Users',
    pathname: '/admin/users',
    description: 'Manage Nuspire users',
    canViewPage: (args) =>
      args.authorize({
        requiredPermissions: { allUsers: Access.all },
      }),
  },
  {
    title: 'System Roles & User Groups',
    pathname: '/admin/rbac',
    description: 'Manage myNuspire System Roles and User Groups',
    canViewPage(args) {
      return {
        authorized: !!args.user?.isSuperUser && args.isCompanyEmail,
      };
    },
  },
  {
    title: 'Superusers',
    pathname: '/admin/superusers',
    description: 'View myNuspire users with superuser privileges',
    canViewPage: (args) =>
      args.authorize({
        requiredPermissions: { allUsers: Access.all },
      }),
  },
  {
    title: 'Audit Logs',
    pathname: '/admin/audit-logs',
    description: 'Manage Nuspire audit logs',
    canViewPage: (args) =>
      args.authorize({
        requiredPermissions: { allAuditLogs: Access.read },
      }),
  },
  {
    title: 'Task Handler',
    pathname: `/admin/task-handler`,
    description: 'Manage Task handler Errors, Tasks, Schedules, etc..',
    canViewPage: (args) => args.authorize({ requiredPermissions: { allSchedules: Access.write } }),
  },
  {
    title: 'Schedules',
    pathname: '/admin/schedules',
    description: 'Manage Nuspire Schedules',
    canViewPage: (args) =>
      args.authorize({
        requiredPermissions: { allSchedules: Access.write },
      }),
  },
  {
    title: 'Cache',
    pathname: '/admin/cache',
    description: 'Manage myNuspire Cache',
    canViewPage: (args) => ({ authorized: !!args.user?.isSuperUser }),
  },
  {
    title: 'Dashboards',
    pathname: '/admin/dashboards',
    description: 'Manage Nuspire Dashboards',
    canViewPage: (args) => args.authorize({ requiredPermissions: { allDashboards: Access.all } }),
  },
  {
    title: 'Health Checks',
    pathname: '/admin/health-checks',
    description: 'View the health of myNuspire integrations',
    canViewPage: (args) => ({ authorized: args.user?.isSuperUser ?? false }),
  },
  {
    title: 'Notifications',
    pathname: '/admin/notifications',
    description: 'Monitor and Publish myNuspire Notifications',
    canViewPage: (args) => ({ authorized: args.user?.isSuperUser ?? false }),
  },
  {
    title: 'Feature Management',
    pathname: '/admin/features',
    description: 'Control stage and lifecycle of Feature Flags.',
    canViewPage: (args) =>
      args.authorize({
        requiredPermissions: {
          featureFlags: Access.all,
        },
      }),
  },
  {
    title: 'Compliance Framework',
    pathname: '/admin/compliance-framework',
    description: 'Manage compliance frameworks',
    canViewPage: (args) =>
      args.authorize({
        requiredPermissions: {
          compliance: Access.all,
        },
      }),
  },
  {
    title: 'External News',
    pathname: '/admin/external-news',
    description: 'Manage External News Sources & Posts',
    canViewPage: (args) =>
      args.authorize({ requiredPermissions: { externalNewsArticles: Access.all, externalNewsSources: Access.all } }),
  },
  {
    title: 'Technology Source Parsers',
    pathname: '/admin/parsers',
    description: 'Manage how we parse fortisiem devices into myNuspire',
    canViewPage: (args) =>
      args.authorize({
        requiredPermissions: {
          technologySourceParsers: Access.all,
        },
      }),
  },
  {
    title: 'Release Notes',
    pathname: adminReleaseNotesPath(),
    description: 'Manage Creation and Editing of Release Notes for Users',
    canViewPage: (args) =>
      args.authorize({
        requiredPermissions: {
          releaseNotes: Access.write,
        },
      }),
  },
];

const nspAdminPages: AdminPageListItem[] = [
  {
    title: 'Industries',
    pathname: '/admin/industries',
    description: 'Manage Nuspire industries',
    canViewPage: (args) =>
      args.authorize({
        requiredPermissions: { industries: Access.read },
      }),
  },
  {
    title: 'Recommendations',
    pathname: '/admin/recommendations',
    description: 'Manage Nuspire recommendations',
    canViewPage: (args) =>
      args.authorize({
        requiredPermissions: { recommendations: Access.read },
      }),
  },
  {
    title: 'Domains',
    pathname: '/admin/domains',
    description: 'Manage Nuspire domains',
    canViewPage: (args) =>
      args.authorize({
        requiredPermissions: { domains: Access.read },
      }),
  },
  {
    title: 'Controls',
    pathname: '/admin/controls',
    description: 'Manage Nuspire controls',
    canViewPage: (args) =>
      args.authorize({
        requiredPermissions: { controls: Access.read },
      }),
  },
  {
    title: 'Capabilities',
    pathname: '/admin/capabilities',
    description: 'Manage Nuspire capabilities',
    canViewPage: (args) =>
      args.authorize({
        requiredPermissions: { capabilities: Access.read },
      }),
  },
  {
    title: 'Industry Controls',
    pathname: '/admin/industry-controls',
    description: 'Manage Nuspire industry controls',
    canViewPage: (args) =>
      args.authorize({
        requiredPermissions: { industryControls: Access.read },
      }),
  },
];

export const AdminDashboard = () => {
  const { client } = useClientContext();
  const effectivePermissions = client?.effectivePermissions;
  const authorize = useAuthorize({ effectivePermissions });
  const { isCompanyEmail, user } = useAuthContext();
  const isNECESEnabled = useFeatureFlag(Features.NECES);

  const generalAdminPages = [...adminPages];
  if (isNECESEnabled) {
    generalAdminPages.push({
      title: 'NECES',
      description: 'Nuspire Enterprise Cybersecurity Enablement System',
      pathname: '/admin/neces',
      canViewPage: (args) => args.authorize({ requiredPermissions: { allNeces: Access.read } }),
    });
  }

  return (
    <Content>
      <Helmet title="Admin" />
      <BreadcrumbBar
        items={[
          { text: 'Home', to: '/' },
          { text: 'Admin', to: '/admin' },
        ]}
      />
      <NuPageHeader title="Admin" />
      <Typography.Title level={4}>Clients & Users</Typography.Title>
      <Row gutter={24}>
        {generalAdminPages.map((a) => {
          const { authorized } = a.canViewPage({ authorize, isCompanyEmail, user });

          return (
            <Col span={24} md={12} key={a.title}>
              <LibrarySearchResult
                title={a.title}
                description={a.description}
                pathname={a.pathname}
                emptyIcon={false}
                disabled={!authorized}
              />
            </Col>
          );
        })}
      </Row>
      <Typography.Title level={4}>Nuspire Security Program</Typography.Title>
      <Row gutter={24}>
        {nspAdminPages.map((a) => {
          const { authorized } = a.canViewPage({ authorize, isCompanyEmail, user });

          return (
            <Col span={24} md={12} key={a.title}>
              <LibrarySearchResult
                title={a.title}
                description={a.description}
                pathname={a.pathname}
                emptyIcon={false}
                disabled={!authorized}
              />
            </Col>
          );
        })}
      </Row>
    </Content>
  );
};
