import { gql, useQuery } from '@apollo/client';
import { Col, Row, Tag } from 'antd';
import { WidgetDefinition, WidgetRendererProps } from 'components/dashboard/widget-definitions/types';
import { EmptyState, NuCardContent } from 'components/nuspire';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import styled, { useTheme } from 'styled-components';
import WidgetFilter, { timeRange } from '../widget-filter';

const FlexCol = styled(Col)`
  display: flex !important;
`;

const LgTag = styled(Tag)`
  font-size: 16px !important;
  padding: 10px 16px !important;
  margin: auto !important;
`;

export const ALERTS_BY_SEVERITY_QUERY = gql`
  query AlertsBySeverity($connectionId: String!, $input: AlertCountsByPolicyInput) {
    clientConnection(id: $connectionId) {
      id
      publicConnectionDataJson
      alertCountsByPolicy(input: $input) {
        alertCount
        alertRules
        alertId
        firstSeen
        id
        lastSeen
        policy {
          cloudType
          severity
          policyType
          rule
          name
        }
        resource
        riskDetail
        saveSearchId
        status
        triggeredBy
      }
    }
  }
`;

const labels = ['Anomaly', 'Audit Event', 'Network', 'Config', 'Data', 'IAM'];
const options = {
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

function AlertsBySeverityWidgetComponent(props: WidgetRendererProps) {
  const theme = useTheme();
  const [timeFilter, setTimeFilter] = useState(2);
  const [statusFilter, setStatusFilter] = useState('open');
  const connectionId = props?.configuration?.connectionId ?? null;
  const { data, loading } = useQuery(ALERTS_BY_SEVERITY_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      connectionId,
      input: {
        filters: [
          {
            name: 'alert.status',
            operator: '=',
            value: statusFilter,
          },
        ],
        timeRange: {
          type: 'relative',
          value: {
            amount: timeRange[timeFilter].value,
            unit: timeRange[timeFilter].unit,
          },
        },
      },
    },
  });
  const alerts = data?.clientConnection?.alertCountsByPolicy;

  const low: any[] = [];
  const medium: any[] = [];
  const high: any[] = [];

  alerts?.forEach((a) => {
    const {
      policy: { severity },
    } = a;
    if (severity === 'low') {
      low.push(a);
    } else if (severity === 'medium') {
      medium.push(a);
    } else if (severity === 'high') {
      high.push(a);
    }
  });

  const barData = {
    labels,
    datasets: [
      {
        label: 'Low',
        data: labels.map(
          (label) =>
            low
              ?.filter((l) => l?.policy?.policyType === label.toLowerCase())
              ?.reduce((acc, cur) => acc + cur?.alertCount, 0) ?? 0,
        ),
        backgroundColor: theme.color.softYellow,
      },
      {
        label: 'Medium',
        data: labels.map(
          (label) =>
            medium
              ?.filter((m) => m?.policy?.policyType === label.toLowerCase())
              ?.reduce((acc, cur) => acc + cur?.alertCount, 0) ?? 0,
        ),
        backgroundColor: theme.color.orange,
      },
      {
        label: 'High',
        data: labels.map(
          (label) =>
            high
              ?.filter((h) => h?.policy?.policyType === label.toLowerCase())
              ?.reduce((acc, cur) => acc + cur?.alertCount, 0) ?? 0,
        ),
        backgroundColor: theme.color.red,
      },
    ],
  };

  if (!alerts && !loading) {
    return (
      <NuCardContent>
        <EmptyState>Unable to fetch Alerts.</EmptyState>
      </NuCardContent>
    );
  }

  return (
    <NuCardContent>
      <WidgetFilter
        timeFilter={timeFilter}
        setTimeFilter={setTimeFilter}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
      />
      {loading ? (
        <SpinContainer>
          <Spin />
        </SpinContainer>
      ) : (
        <div style={{ marginTop: '14px' }}>
          <Row style={{ margin: '10px 0' }}>
            <FlexCol span={8}>
              <LgTag color={theme.color.softYellow}>Low: {low.reduce((acc, cur) => acc + cur.alertCount, 0)}</LgTag>
            </FlexCol>
            <FlexCol span={8}>
              <LgTag color={theme.color.orange}>Medium: {medium.reduce((acc, cur) => acc + cur.alertCount, 0)}</LgTag>
            </FlexCol>
            <FlexCol span={8}>
              <LgTag color={theme.color.red}>High: {high.reduce((acc, cur) => acc + cur.alertCount, 0)}</LgTag>
            </FlexCol>
          </Row>
          <Bar data={barData} options={options} />
        </div>
      )}
    </NuCardContent>
  );
}

const AlertsBySeverityWidget: WidgetDefinition = {
  name: 'Prisma Cloud Alerts By Severity',
  slug: 'prisma-cloud-alerts-by-severity',
  connectorSlug: 'prisma-cloud',
  component: AlertsBySeverityWidgetComponent,
  description: 'Prisma Cloud Alert counts by severity',
};

export default AlertsBySeverityWidget;
