import { gql, useQuery } from '@apollo/client';
import { Flex, Space, Spin, Tooltip, Typography } from 'antd';
import { NuButton, NuCard, NuCardContent, NuCardTitle } from 'components/nuspire';
import { NuEmptyState } from 'components/nuspire/nu-empty-state/nu-empty-state.stories';
import { SpinContainer } from 'components/nuspire/spin';
import dayjs from 'dayjs';
import { Link } from 'react-router';
import { Unpacked } from 'types';
import { RecentKnowledgeBaseArticlesQuery } from 'types/graph-codegen/graph-types';
import { TypographyLink } from '../../typography-link';

const RECENT_KNOWLEDGE_BASE_CARD = gql`
  query RecentKnowledgeBaseArticles($filters: KnowledgeFilters) {
    knowledge(filters: $filters) {
      knowledge {
        sys_id
        category
        number
        published
        short_description
      }
    }
  }
`;

type KnowledgeBaseArticle = Unpacked<
  NonNullable<NonNullable<RecentKnowledgeBaseArticlesQuery['knowledge']>['knowledge']>
>;

function KnowledgeBaseArticleListItem(props: { clientId: string; article: KnowledgeBaseArticle }) {
  const {
    clientId,
    article: { number, short_description, published },
  } = props;

  return (
    <Flex vertical>
      <TypographyLink to={`/${clientId}/knowledge/${number}`}>{short_description}</TypographyLink>
      <Flex>
        <Typography.Text style={{ fontSize: '12px' }}>{number}</Typography.Text>
        <Typography.Text
          style={{
            margin: '0 4px',
            fontSize: '12px',
          }}
        >
          ••
        </Typography.Text>
        <Tooltip title={dayjs.utc(published).local().format('MMMM Do YYYY, h:mm:ss a')}>
          <Typography.Text style={{ fontSize: '12px' }}>
            Published {dayjs.utc(published).local().fromNow()}
          </Typography.Text>
        </Tooltip>
      </Flex>
    </Flex>
  );
}

function KnowlegeBaseArticlesCardContent(props: { clientId: string }) {
  const { clientId } = props;

  const { data, loading } = useQuery<RecentKnowledgeBaseArticlesQuery>(RECENT_KNOWLEDGE_BASE_CARD, {
    variables: {
      filters: {
        limit: 10,
        sortBy: 'published',
        sortOrder: 'desc',
      },
    },
  });

  const articles = data?.knowledge?.knowledge;

  if (articles) {
    return (
      <Space direction="vertical">
        {articles.map((article) => (
          <KnowledgeBaseArticleListItem clientId={clientId} key={article.sys_id} article={article} />
        ))}
      </Space>
    );
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  return <NuEmptyState>No Knowledge Base Articles could be found.</NuEmptyState>;
}

export default function KnowledgeBaseArticlesCard(props: { clientId: string }) {
  const { clientId } = props;

  return (
    <NuCard fullHeight>
      <NuCardTitle
        title="Recent Knowledge Base Articles"
        actions={
          <Link to={`/${clientId}/knowledge`}>
            <NuButton type="link">View All</NuButton>
          </Link>
        }
      />
      <NuCardContent>
        <KnowlegeBaseArticlesCardContent {...props} />
      </NuCardContent>
    </NuCard>
  );
}
