export default () => (
  <svg fill="none" height="1em" viewBox="0 0 24 24" width="1em" xmlns="http://www.w3.org/2000/svg">
    <path d="m0 0h24v24h-24z" fill="transparent" />
    <g fill="currentColor">
      <path d="m2 2h5v5h-5z" />
      <path d="m9.5 2h5v5h-5z" />
      <path d="m17 2h5v5h-5z" />
      <path d="m2 9.5h5v5h-5z" />
      <path d="m9.5 9.5h5v5h-5z" />
      <path d="m17 9.5h5v5h-5z" />
      <path d="m2 17h5v5h-5z" />
      <path d="m9.5 17h5v5h-5z" />
      <path d="m17 17h5v5h-5z" />
    </g>
  </svg>
);
