import { toRelativeUrl } from '@okta/okta-auth-js';
import { Security as OktaAuthProvider } from '@okta/okta-react';
import { Layout } from 'antd';
import MyNuspireSVG from 'components/nuspire/nu-icon/svgs/my-nuspire-logo';
import { FC, PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components';
import { config } from '../../config';
import { oktaAuth } from '../../utils/okta';
import { TypographyLink } from '../typography-link';

const StyledFooter = styled(Layout.Footer)`
  margin-top: auto;
  padding-bottom: 3rem;
  padding-top: 3rem;
  margin-bottom: 2.5rem;

  & ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    & li {
      margin: 0 14px;
    }
  }
`;

export const UnauthenticatedLayout: FC<PropsWithChildren<{ showAuthActions?: boolean }>> = (props) => {
  const { children, showAuthActions = true } = props;
  const navigate = useNavigate();
  const { boot } = useIntercom();

  boot(); // start intercom

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const titleTemplate = `${config.titleEnv} myNuspire Portal | %s`.trimStart();

  return (
    <OktaAuthProvider
      oktaAuth={oktaAuth}
      restoreOriginalUri={(_oktaAuth, originalUri) => {
        if (!originalUri) {
          return;
        }

        const url = toRelativeUrl(originalUri, window.location.origin);
        if (url) {
          navigate(url, { replace: true });
        }
      }}
    >
      <Helmet titleTemplate={titleTemplate} title="myNuspire Portal" />
      <Layout>
        <Layout.Header
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            background: 'none',
            margin: '2rem',
            marginBottom: '3rem',
          }}
        >
          <MyNuspireSVG />

          {showAuthActions && (
            <div>
              <TypographyLink to="/login">Sign In</TypographyLink>
              <span>&nbsp;or&nbsp;</span>
              <TypographyLink to="/sign-up">Sign Up</TypographyLink>
            </div>
          )}
        </Layout.Header>
        <Layout.Content>
          <div style={{ maxWidth: '1200px', margin: '0 auto' }}>{children}</div>
        </Layout.Content>
        <StyledFooter>
          <ul>
            <li>
              <a href="#" onClick={() => openInNewTab('https://www.nuspire.com/terms/')}>
                Terms of Use
              </a>
            </li>
            <li>
              <a href="#" onClick={() => openInNewTab('https://www.nuspire.com/privacy-policy/')}>
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="#" onClick={() => openInNewTab('https://www.nuspire.com/about-us/')}>
                About Us
              </a>
            </li>
            <li>
              <a href="#" onClick={() => openInNewTab('https://www.nuspire.com/support/contact-us/')}>
                Contact Us
              </a>
            </li>
          </ul>
          <div style={{ textAlign: 'center' }}>&copy; {new Date().getFullYear()} Nuspire LLC. All rights reserved.</div>
        </StyledFooter>
      </Layout>
    </OktaAuthProvider>
  );
};
