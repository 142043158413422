import { IConnector } from '..';
import QualysAuthRedirect from './qualys-redirect';
import { QualysShield as QualysIcon } from 'components/nuspire/nu-icon';

const qualysConnectorDefinition: IConnector = {
  slug: 'qualys',
  name: 'Qualys',
  description: 'Qualys Description...',
  connectionTypes: ['action'], // I think we can deprecate this.
  icon: <QualysIcon />,
  handleAddConnection: (args) => {
    const clientId = args?.clientId;
    const sp = new URLSearchParams();
    if (clientId) {
      sp.set('clientId', clientId);
    }

    window.open(
      `${window.location.origin}/redirects/connections/qualys/auth?${sp.toString()}`,
      'Qualys Auth',
      'height=1000,width=800',
    );
  },

  redirects: [{ path: 'auth', component: QualysAuthRedirect }],
  updatableAuthFields: [
    { key: 'Username', value: 'username' },
    { key: 'Password', value: 'password' },
    { key: 'Qualys Api Server Url', value: 'qualysApiServerUrl', inputType: 'qualysApiServerUrl' },
  ],
};

export default qualysConnectorDefinition;
