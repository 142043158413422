import { Modal, Table, Tooltip, message } from 'antd';
import { IDataExplorerContext, useDataExplorerContext } from 'components/data-explorer/data-explorer';
import { Link, NuButton } from 'components/nuspire';
import EmptyState from 'components/nuspire/nu-empty-state';
import { Filter, InsightsIcon } from 'components/nuspire/nu-icon';
import { queryDataTypePath } from 'components/reporting-and-analysis/paths';
import objectPath from 'object-path';
import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { WidgetComponentProps, WidgetConfig } from '..';
import { LineFilters, WidgetFilters } from './line';
import { renderComponentsMap } from './render-components';

import { OnRowModalConfig } from './row-modals/types';
import { ROW_MODALS } from './row-modals/row-modals';
import { ColumnsType } from 'antd/es/table';
import { TableProps } from 'antd/lib';

export interface ColumnConfig {
  title: string;
  key: string;
  dataIndex?: string | string[];
  csvDataIndex?: string | string[];
  renderComponent?: string;
  filterInputs?: {
    key: string;
    dataIndex?: string;
  }[];
  // nested columns.
  children?: ColumnConfig[];
}

interface TableConfig extends WidgetConfig {
  columns?: ColumnConfig[];
  options?: {
    disablePagination?: boolean;
    size?: 'small' | 'middle' | 'large';
  };
  filters?: LineFilters[];
  linkText?: string;
  link?: string;
  onRowModal?: OnRowModalConfig;
}

interface TableData {
  tableData: any[]; // rows.
}

export const FilterButtonRoot = styled.div`
  display: flex;
`;

export const FilterButtonValue = styled.div`
  margin-right: 8px;

  .is-inspect & {
    display: none;
  }
`;

export const FilterButtonWrap = styled.div`
  opacity: 0;
  transition: opacity ease 0.2s;

  .fob-root:hover &,
  .is-inspect & {
    opacity: 1;
  }
`;

function DataExplorerFilterButton(props: {
  record: any;
  dataExplorerContext: IDataExplorerContext;
  filterInputs: {
    key: string;
    dataIndex?: string;
  }[];
  column: ColumnConfig;
}) {
  const {
    record,
    dataExplorerContext: { dataExplorerFilters },
    filterInputs,
    column,
  } = props;

  // apply filters.
  const handleClick = () => {
    const changes = filterInputs.reduce((acc: { key: string; value: any }[], filter) => {
      const dataIndex = filter.dataIndex ?? column.dataIndex;
      // find value from dot notation.
      const pathValue = objectPath.get(record, dataIndex);

      if (pathValue) {
        acc.push({
          key: filter.key,
          value: pathValue,
        });
      }

      return acc;
    }, []);

    // we need to do something here to intercept and update with dataExploerFilters

    if (changes.length) {
      dataExplorerFilters.updateFilters(changes);
    }
  };

  return (
    <NuButton onClick={handleClick} size="small" type="link">
      <Filter />
    </NuButton>
  );
}

function buildDataExplorerPath(args: {
  clientId: string;
  record: any;
  column: ColumnConfig;
  dataTypeKey: string;
  filterInputs: {
    key: string;
    dataIndex?: string;
  }[];
}) {
  const { clientId, record, column, dataTypeKey, filterInputs } = args;

  const searchParams = new URLSearchParams();

  filterInputs.forEach((filter) => {
    const dataIndex = filter.dataIndex ?? column.dataIndex;
    const pathValue = objectPath.get(record, dataIndex);
    if (pathValue) {
      searchParams.set(filter.key, pathValue);
    }
  });

  const path = `${queryDataTypePath({ clientId, dataType: dataTypeKey })}?${searchParams.toString()}`;

  return path;
}

function DataExplorerLinkButton(props: {
  clientId: string;
  record: any;
  column: ColumnConfig;
  dataTypeKey: string;
  filterInputs: {
    key: string;
    dataIndex?: string;
  }[];
  isInspect: boolean;
}) {
  const path = buildDataExplorerPath(props);
  const { isInspect } = props;

  return (
    <Link to={path}>
      <Tooltip title="View in Data Explorer" placement="right">
        <NuButton type="link" size="small">
          {isInspect ? <InsightsIcon /> : <Filter />}
        </NuButton>
      </Tooltip>
    </Link>
  );
}

function DataExplorerFilter(props: {
  clientId: string;
  record: any;
  value: JSX.Element | React.ReactNode;
  column: ColumnConfig;
  dataTypeKey: string;
  isInspect: boolean;
  filterInputs: {
    key: string;
    dataIndex?: string;
  }[];
  dataExplorerContext?: IDataExplorerContext;
}) {
  const { clientId, isInspect, record, value, dataTypeKey, column, filterInputs, dataExplorerContext } = props;
  const button = dataExplorerContext ? (
    <DataExplorerFilterButton
      record={record}
      column={column}
      dataExplorerContext={dataExplorerContext}
      filterInputs={filterInputs}
    />
  ) : (
    <DataExplorerLinkButton
      clientId={clientId}
      record={record}
      column={column}
      filterInputs={filterInputs}
      dataTypeKey={dataTypeKey}
      isInspect={isInspect}
    />
  );
  return (
    <FilterButtonRoot className={`fob-root ${isInspect ? 'is-inspect' : undefined}`}>
      <FilterButtonValue>{value}</FilterButtonValue>
      <FilterButtonWrap>{button}</FilterButtonWrap>
    </FilterButtonRoot>
  );
}

export function transformColumn(args: {
  clientId: string;
  dataTypeKey?: string;
  dataExplorerContext?: IDataExplorerContext;
}) {
  const { clientId, dataExplorerContext, dataTypeKey } = args;

  return (column: ColumnConfig) => {
    const { key, filterInputs, renderComponent } = column;

    const transformed = {
      ...column,
      render: (val, record) => {
        let value = val;

        //import list of renderComponents to match on string
        if (renderComponent) {
          //render with value and record
          const RenderComponent = renderComponentsMap[renderComponent];

          if (RenderComponent) {
            return <RenderComponent value={value} record={record} />;
          }
        }

        const isInspect = key === 'inspect';

        if (isInspect) {
          value = '';
        }

        // supports
        if (dataTypeKey && filterInputs?.length) {
          return (
            <DataExplorerFilter
              isInspect={isInspect}
              clientId={clientId}
              record={record}
              column={column}
              value={value}
              dataTypeKey={dataTypeKey}
              filterInputs={filterInputs}
              dataExplorerContext={dataExplorerContext}
            />
          );
        }
        return value;
      },
    };

    return transformed;
  };
}

function getColumns(args: {
  clientId: string;
  dataTypeKey?: string; // slug of data explorer type.
  configuration: TableConfig;
  dataExplorerContext?: IDataExplorerContext;
}) {
  const { clientId, configuration, dataExplorerContext, dataTypeKey } = args;
  const { columns, onRowModal } = configuration;

  return columns?.map(transformColumn({ clientId, dataExplorerContext, dataTypeKey }));
}

function RowModal(props: {
  config: OnRowModalConfig;
  record: any;
  onClose: () => void;
  widgetProps: WidgetComponentProps<TableConfig, TableData>;
}) {
  const { config, record, onClose } = props;
  const { name } = config;
  const rowModal = ROW_MODALS.find((e) => e.slug === name);

  if (!rowModal) {
    console.warn(`could not find modal: ${name}`);
    return null;
  }

  const RowModalComponent = rowModal.component;

  return (
    <Modal
      open
      title={rowModal.title}
      onCancel={onClose}
      width={rowModal.width}
      footer={false}
      styles={{
        body: {
          height: rowModal.height,
        },
      }}
    >
      <RowModalComponent {...props} />
    </Modal>
  );
}

function TableWidget(props: WidgetComponentProps<TableConfig, TableData>) {
  const { clientId, dataTypeKey, configuration, data, isReportWidget, setSubAction, onFetch } = props;
  const { link, linkText, filters } = configuration;
  const hasFilters = filters && filters.length > 0;
  const [datasets, setDatasets] = useState(data);
  const tableContainerRef = useRef<HTMLDivElement | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const [rowModalRecord, setRowModalRecord] = useState<any>(null);

  const onRowModal = configuration.onRowModal;

  const openRowModal = onRowModal
    ? (record: any, _idx: number | undefined) => {
        setRowModalRecord(record);
      }
    : undefined;

  const handleCloseRowModal = () => setRowModalRecord(null);

  useEffect(() => {
    if (setSubAction && link && linkText) {
      const subAction = (
        <Link to={`/${clientId}${link}`} style={{ marginRight: '1rem' }}>
          {linkText}
        </Link>
      );
      setSubAction(subAction);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      const tableBody = tableContainerRef.current?.querySelector('.ant-table-content');

      if (tableBody) {
        tableBody.scrollLeft = tableBody.scrollWidth;
      }
    }, 0); // delay to ensure DOM is painted

    return () => clearTimeout(timer);
  }, [datasets?.tableData]); // run when data changes

  const rowKey = useMemo(() => {
    if (datasets?.tableData?.[0] && 'sys_id' in datasets?.tableData?.[0]) {
      return 'sys_id';
    }

    return 'id';
  }, [datasets?.tableData]);

  // Check to see if widget is being rendered within Data explorer context.
  const dataExplorerContext = useDataExplorerContext();

  const columns: ColumnsType | undefined = getColumns({ clientId, dataTypeKey, configuration, dataExplorerContext });
  if (!columns) {
    return <EmptyState>No columns defined for data type.</EmptyState>;
  }

  // add button if table has row modal
  if (onRowModal) {
    columns?.unshift({
      key: 'rowModal',
      title: '',
      width: '20px',
      render: () => (
        <NuButton
          style={{
            paddingInlineStart: '4px',
            paddingInlineEnd: '4px',
          }}
          icon={<InsightsIcon />}
          shape="round"
          type="link"
        />
      ),
    });
  }

  const handleTableChange: TableProps['onChange'] = async (_pagination, _filters, sorter) => {
    setLoading(true);

    try {
      if (onFetch) {
        const results = await onFetch({
          variables: {
            sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
            sortColumnKey: Array.isArray(sorter) ? undefined : sorter.columnKey,
            sortField: Array.isArray(sorter) ? undefined : sorter.field,
          },
        });

        // update state
        setDatasets(results);
      }
    } catch (err) {
      console.error(err);
      message.error('Failed to fetch widget data.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {hasFilters ? (
        <WidgetFilters filters={filters} onFetch={onFetch} setDatasets={setDatasets} style={{ marginBottom: '1rem' }} />
      ) : null}
      <div ref={tableContainerRef}>
        <Table
          rowKey={rowKey}
          columns={columns}
          loading={loading}
          dataSource={datasets?.tableData}
          pagination={
            isReportWidget || configuration?.options?.disablePagination ? false : { position: ['bottomRight'] }
          }
          onRow={(record, idx) => {
            return {
              onClick: openRowModal ? (_e) => openRowModal(record, idx) : undefined,
            };
          }}
          onChange={handleTableChange}
          scroll={{ x: 'max-content' }}
          size={configuration?.options?.size ?? 'middle'}
        />
      </div>

      {rowModalRecord && onRowModal && (
        <RowModal widgetProps={props} config={onRowModal} onClose={handleCloseRowModal} record={rowModalRecord} />
      )}
    </>
  );
}

export default TableWidget;
