import { useState } from 'react';
import { ISchedule } from '../schedule-details';
import styled from 'styled-components';
import { EmptyState, NuButton, NuPaper } from 'components/nuspire';
import { message, Modal, Typography } from 'antd';
import { gql, useMutation } from '@apollo/client';

type IScheduledTask = { __typename?: 'ScheduledTask'; id: string; scheduledTime: string } | null | undefined;

const StliContent = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
`;

const StliCol = styled.div`
  margin-right: 32px;
`;

function ScheduledTaskListItem(props: { scheduledTask: IScheduledTask }) {
  const { scheduledTask } = props;

  if (!scheduledTask) {
    return null;
  }

  const { scheduledTime } = scheduledTask;
  const date = new Date(scheduledTime);
  const readableDate = date.toLocaleString();

  return (
    <NuPaper styles={{ root: { marginBottom: '64px' } }}>
      <StliContent>
        <StliCol>
          <Typography.Text type="secondary" strong>
            Scheduled
          </Typography.Text>
        </StliCol>
        <StliCol>{readableDate}</StliCol>
      </StliContent>
    </NuPaper>
  );
}

function ScheduledTasksList(props: { schedule: ISchedule }) {
  const {
    schedule: { scheduledTasks },
  } = props;

  return <>{scheduledTasks?.map((scheduledTask) => <ScheduledTaskListItem scheduledTask={scheduledTask} />)}</>;
}

/**
 * Schedule task.
 */

export const SCHEDULE_TASK = gql`
  mutation ScheduleTask($scheduleId: String!, $runNow: Boolean!, $configuration: JSONObject, $scheduleNext: Boolean) {
    updateSchedule(id: $scheduleId, input: { enabled: true }) {
      id
      enabled
    }
    scheduleTask(scheduleId: $scheduleId, runNow: $runNow, configuration: $configuration, scheduleNext: $scheduleNext) {
      id
      scheduledTime
    }
  }
`;

function ScheduleTaskButton(props: { scheduleId: string }) {
  const { scheduleId } = props;

  // state
  const [visible, setVisible] = useState<boolean>(false);
  const handleOpen = () => setVisible(true);
  const handleClose = () => setVisible(false);

  // graphql
  const [scheduleTask] = useMutation(SCHEDULE_TASK, {
    refetchQueries: ['ScheduleDetails'],
  });

  const createTask = async (args: { runNow: boolean }) => {
    const { runNow } = args;
    try {
      const results = await scheduleTask({
        variables: { runNow, scheduleId },
      });
      message.success('Successfully scheduled Task.');
    } catch (err: any) {
      console.error(err);
      message.error('Failed to schedule Task');
    }
  };

  return (
    <>
      <NuButton type="primary" onClick={handleOpen}>
        Schedule Task
      </NuButton>

      <Modal
        open={visible}
        footer={[
          <NuButton key="cancel" onClick={handleClose}>
            Cancel
          </NuButton>,
          <NuButton key="schedule-next" onClick={() => createTask({ runNow: false })} type="primary">
            Schedule Cron
          </NuButton>,
          <NuButton key="run-now" onClick={() => createTask({ runNow: true })} type="primary">
            Run Now
          </NuButton>,
        ]}
      >
        <p
          style={{
            marginTop: '24px',
          }}
        >
          Schedules are set up to run on a cron. Would you like to schedule a task to run on the next cron cycle or to
          run now?
        </p>
      </Modal>
    </>
  );
}

export function ScheduledTasks(props: { schedule: ISchedule }) {
  const {
    schedule,
    schedule: { scheduledTasks, id: scheduleId },
  } = props;

  if (scheduledTasks?.length) {
    return <ScheduledTasksList schedule={schedule} />;
  }

  return (
    <EmptyState actions={<ScheduleTaskButton scheduleId={scheduleId} />}>No upcoming Tasks are Scheduled</EmptyState>
  );
}
