import { List } from 'antd';
import { ComponentProps, ReactNode, forwardRef, Ref, CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { RenderArgs } from './sortable-list';

interface DraggableBoxItemProps extends RenderArgs {
  listItemProps?: ComponentProps<typeof List.Item>;
  className?: string;
  children?: ReactNode;
}

const StyledListItem = styled(List.Item)<{ $isOverlay: boolean }>`
  cursor: grab;
  && {
    ${(p) => {
      if (['pdi-light', 'pdi-dark'].includes(p.theme.mode)) {
        return `color: ${p.theme.token.colorTextLightSolid};`;
      }
      return '';
    }}
    padding: 12px 16px;
    margin: 8px 0;
    border-radius: 8px;
    border: 1px solid ${(p) => p.theme.token.colorBorder};
    border-block-end: 1px solid ${(p) => p.theme.token.colorBorder};

    box-shadow: 0 2px 7px -3px rgba(34, 33, 81, 0.15);
    transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0);
    transition: transform 250ms ease;

    &:last-child {
      border-block-end: 1px solid ${(p) => p.theme.token.colorBorder};
    }

    ${(p) =>
      p.$isOverlay &&
      css`
        animation: pop 250ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
        animation-fill-mode: forwards;
      `}

    @keyframes pop {
      0% {
        transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0) scale(1);
      }
      100% {
        transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0) scale(1.05);
        box-shadow: 0 8px 10px 3px rgba(34, 33, 81, 0.15);
      }
    }
  }
`;

export const DraggableBoxItem = forwardRef((props: DraggableBoxItemProps, ref) => {
  const { children, listItemProps, transform, attributes, listeners, className, isOverlay = false } = props;

  const style = {
    ...props.style,
    '--translate-x': `${transform?.x ?? 0}px`,
    '--translate-y': `${transform?.y ?? 0}px`,
  } as CSSProperties;

  return (
    <StyledListItem
      ref={ref as any}
      style={style}
      className={className}
      $isOverlay={isOverlay}
      {...attributes}
      {...listeners}
      {...listItemProps}
    >
      {children}
    </StyledListItem>
  );
});

DraggableBoxItem.displayName = 'DraggableBoxItem';
