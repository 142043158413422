import { gql, useQuery } from '@apollo/client';
import { Drawer } from 'antd';
import classNames from 'classnames';
import { useClientContext } from 'components/client-context-provider';
import { EmptyState } from 'components/nuspire';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { useState } from 'react';
import styled from 'styled-components';
import { DataTypeColumnsWidgetsQuery } from 'types/graph-codegen/graph-types';
import { useDebugRequests } from '../debug';
import { IDataExplorerContext } from './data-explorer';
import { DataExplorerTable } from './data-explorer-table';
import { DataTypeWidgets } from './data-type-detail-widgets';

const Root = styled.div`
  position: relative;
  overflow: hidden;
`;

// const Grid = styled.div`
//   /* display: grid; */
//   /* grid-template-columns: 1fr 550px; */
//   grid-template-areas: 'de-table de-widgets';
// `;

const TableArea = styled.div`
  /* grid-area: de-table; */
  width: 100%;
  height: 100vh; // or '100vh' for full viewport height
`;

const WidgetsArea = styled.div`
  /* grid-area: de-widgets; */
  transition: background-color 0.25s ease;
  background-color: ${(p) => p.theme.token.colorFillContent};

  .has-item-selected & {
    background-color: ${(p) => p.theme.token.colorPrimaryBg};
  }
`;

const DATA_TYPE_COLUMNS_AND_WIDGETS = gql`
  query DataTypeColumnsWidgets($dataTypeSlug: String!, $clientId: String) {
    dataType(slug: $dataTypeSlug) {
      id
      name
      rowKey
      columns(clientId: $clientId) {
        renderComponent
        dataIndex
        key
        title
        defaultSortOrder
        sorter

        filterInputs {
          key
          dataIndex
        }
      }

      # render widgets when no row has been selected
      summaryWidgets {
        id
        name
        description
        type
      }

      # Render widgets when a row has been selected.
      detailWidgets {
        id
        name
        type
      }
    }
  }
`;

export type IDataType = NonNullable<DataTypeColumnsWidgetsQuery['dataType']>;
export type IBackendColumn = {
  __typename?: 'TableColumn';
  dataIndex?: string | null;
  key: string;
  title?: string | null;
  defaultSortOrder?: 'ascend' | 'descend';
  sorter?: boolean;
};

/**
 * Component displaying the results table along side detail and summary widgets for the selected data type.
 */
function DataExplorerResultsAndWidgets(props: {
  dataTypeSlug: string;
  debugRequestId?: string;
  isDrawerOpen: boolean;
  setIsDrawerOpen: (isOpen: boolean) => void;
  dataExplorerContext: IDataExplorerContext;
}) {
  const { dataTypeSlug, setIsDrawerOpen, dataExplorerContext } = props;
  const { clientId } = useClientContext();
  const { requestIds } = useDebugRequests();

  /**
   * Each Datatype defines its own set of columns so we need to query them at some point
   */
  const { data, loading } = useQuery<DataTypeColumnsWidgetsQuery>(DATA_TYPE_COLUMNS_AND_WIDGETS, {
    variables: {
      dataTypeSlug,
      clientId,
    },
  });

  const dataType = data?.dataType;
  const rowKey = dataType?.rowKey ?? 'id';

  // ========== Row Selection ==========
  const [selectedItem, setSelectedItem] = useState<any>(undefined);
  const toggleSelectedItem = (selected: any) => {
    if (selectedItem && selected[rowKey] === selectedItem[rowKey]) {
      setSelectedItem(undefined);
    } else {
      setSelectedItem(selected);
    }

    setIsDrawerOpen(true);
  };

  const rootClasses = classNames({
    'has-item-selected': Boolean(selectedItem),
  });

  if (dataType) {
    const ResultsTable = (
      <TableArea>
        <DataExplorerTable
          dataExplorerContext={dataExplorerContext}
          dataType={dataType}
          debugRequestId={props.debugRequestId}
          selectedItem={selectedItem}
          toggleSelectedItem={toggleSelectedItem}
        />
        <Drawer
          onClose={() => {
            props.setIsDrawerOpen(false);
            setSelectedItem(undefined);
          }}
          open={props.isDrawerOpen}
          placement="right"
          size="large"
          title={selectedItem ? 'Detail Widget' : 'Summary Widgets'}
          getContainer={false}
          styles={{ wrapper: { height: '67.5%' } }}
        >
          <WidgetsArea>
            <DataTypeWidgets
              selectedItem={selectedItem}
              dataType={dataType}
              dataExplorerFilters={dataExplorerContext.dataExplorerFilters}
            />
          </WidgetsArea>
        </Drawer>
      </TableArea>
    );

    return <Root className={rootClasses}>{ResultsTable}</Root>;
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  return <EmptyState>There was a problem searching.</EmptyState>;
}

/**
 * Ensure that a datatype is selected before rendering any form of table.
 * @param props
 * @returns
 */
export function DataExplorerResults(props: {
  dataTypeSlug?: string;
  debugRequestId?: string;
  isDrawerOpen: boolean;
  setIsDrawerOpen: (isOpen: boolean) => void;
  dataExplorerContext: IDataExplorerContext;
}) {
  const { dataTypeSlug, dataExplorerContext } = props;

  if (!dataTypeSlug) {
    return (
      <EmptyState>
        Select a data type, choose your filters, click <b>Search</b>.
      </EmptyState>
    );
  }

  return (
    <DataExplorerResultsAndWidgets
      dataTypeSlug={dataTypeSlug}
      debugRequestId={props.debugRequestId}
      isDrawerOpen={props.isDrawerOpen}
      setIsDrawerOpen={props.setIsDrawerOpen}
      dataExplorerContext={dataExplorerContext}
    />
  );
}
