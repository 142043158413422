import { NuButton } from 'components/nuspire';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { UserAdd as UserAddIcon } from 'components/nuspire/nu-icon';
import { UserGroupApi } from '../../user-group-detail';
import { gql, useQuery, useMutation } from '@apollo/client';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { Input, message, Space, Table } from 'antd';
import EmptyState from 'components/nuspire/nu-empty-state';
import Modal from 'antd/es/modal/Modal';
import debounce from 'lodash.debounce';
import { useClientContext } from '../../../../client-context-provider';

const ADD_USER_GROUP_MODAL = gql`
  query AddUserGroupModal($clientId: String!) {
    getUsersByClientId(id: $clientId) {
      id
      email
      firstName
      lastName
    }
  }
`;
export const ADD_MEMBER = gql`
  mutation AddUserGroupMember($clientId: String!, $userGroupId: String!, $userId: String!) {
    addUserGroupMember(clientId: $clientId, userGroupId: $userGroupId, userId: $userId) {
      id
    }
  }
`;
interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}
interface GetUsersByClientData {
  getUsersByClientId: User[];
}

type Maybe<T> = T | null;
function getUsersNotInGroup(usersArg: Maybe<User[]> = [], membersArg: Maybe<User[]> = []) {
  const users = usersArg ?? [];
  const members = membersArg ?? [];
  const memberIds = members.map((m) => m.id);

  return users.filter((u) => !memberIds.includes(u.id));
}

function AddUserGroupModal(props: { userGroupApi: UserGroupApi; onCloseModal: () => void }) {
  const {
    userGroupApi: {
      userGroup: { clientId, members, id: userGroupId },
      refetchUserGroup,
    },
    onCloseModal,
  } = props;

  /**
   * GQL
   */
  const { data, loading } = useQuery<GetUsersByClientData>(ADD_USER_GROUP_MODAL, { variables: { clientId } });
  const clientUsers = data?.getUsersByClientId ?? null;
  const elligibleUsers = getUsersNotInGroup(clientUsers, members);

  const [addMember] = useMutation(ADD_MEMBER);

  const addMembers = async (userIds: string[]): Promise<void> => {
    const addMemberResults = await Promise.all(
      userIds.map(async (userId) => {
        try {
          const variables = {
            userId,
            clientId,
            userGroupId,
          };

          const { data: addMemberData, errors } = await addMember({ variables });

          if (addMemberData?.addUserGroupMember) {
            return {
              ok: true,
            };
          }

          if (errors && errors.length) {
            errors.forEach((e) =>
              console.error(`An error occurred while adding user ${userId} to the user group.`, { message: e.message }),
            );
          }

          return {
            ok: false,
          };
        } catch (err) {
          console.log('THERE WAS AN ERROR ADDING USER...', { userId });
          return { ok: false };
        }
      }),
    );

    refetchUserGroup();

    /**
     * summarize Results;
     */
    const { successCount, failedCount } = addMemberResults.reduce(
      (
        acc: {
          successCount: number;
          failedCount: number;
        },
        result,
      ) => {
        if (result.ok) {
          acc.successCount += 1;
        } else {
          acc.failedCount += 1;
        }

        return acc;
      },
      {
        successCount: 0,
        failedCount: 0,
      },
    );

    const successSummary =
      successCount > 0 ? `${successCount} member${successCount !== 1 ? 's' : ''} were successfully added. ` : false;

    const failedSummary =
      failedCount > 0 ? `Failed to add ${failedCount} member${failedCount !== 1 ? 's' : ''}` : false;

    if (successSummary) {
      message.success(successSummary);
    }
    if (failedSummary) {
      message.error(failedSummary);
    }
  };

  /**
   * State
   */
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const canSubmit = selectedRowKeys.length > 0;

  const handleSubmit = async () => {
    await addMembers(selectedRowKeys);
  };

  const [searchText, setSearchText] = useState<string>('');
  const [selectedKeys, setSelectedKeys] = useState<string>('');

  const handleClearFilter = () => setSelectedKeys('');

  // wait until user pauses typing before refiltering
  const debouncedSearch = useCallback(
    debounce((search: string) => {
      setSearchText(search);
    }, 500),
    [],
  );

  useEffect(() => {
    debouncedSearch(selectedKeys);
  }, [selectedKeys]);

  const searchResults = useMemo(() => {
    if (searchText.length === 0) {
      return elligibleUsers;
    }

    const lowerSearch = searchText.toLowerCase();

    return elligibleUsers.filter((user) => {
      const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
      return user.email.toLowerCase().includes(lowerSearch) || fullName.includes(lowerSearch);
    });
  }, [searchText, elligibleUsers]);

  const showTable = Boolean(elligibleUsers?.length);
  const showLoading = !showTable && loading;

  return (
    <Modal
      open
      title="Add Members to Group"
      okButtonProps={{
        disabled: !canSubmit,
        title: 'Add',
      }}
      width={800}
      onCancel={onCloseModal}
      onOk={handleSubmit}
    >
      {showTable && (
        <div style={{ height: '600px' }}>
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Search Elligible Users"
              value={selectedKeys}
              onChange={(e) => {
                setSelectedKeys(e.target.value);
              }}
              style={{ marginRight: '8px', width: '300px' }}
            />
            <Space>
              <NuButton onClick={handleClearFilter}>Clear</NuButton>
            </Space>
          </div>

          <Table
            rowKey="id"
            pagination={false}
            scroll={{
              x: true,
              y: 500,
            }}
            dataSource={searchResults}
            columns={[
              {
                title: 'User',
                key: 'user',
                render: (_value, { firstName, lastName }) => `${firstName} ${lastName}`,
              },
              {
                title: 'Email',
                key: 'email',
                dataIndex: 'email',
              },
            ]}
            rowSelection={{
              selectedRowKeys,
              onSelect: (user, selected) => {
                const newSelected = [...selectedRowKeys].filter((key) => key !== user.id);
                if (selected) {
                  newSelected.push(user.id);
                }

                setSelectedRowKeys(newSelected);
              },
            }}
          />
        </div>
      )}

      {showLoading && (
        <SpinContainer>
          <Spin tip="Loading Account Users...">
            <div className="content" />
          </Spin>
        </SpinContainer>
      )}

      {!showTable && !showLoading && <EmptyState>No users available.</EmptyState>}
    </Modal>
  );
}

function AddUsersToGroupBtn(props: { userGroupApi: UserGroupApi; disabled?: boolean }) {
  const { disabled = false, userGroupApi } = props;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  // patch this object to contain a clientId...
  // https://sd-at-nuspire.atlassian.net/browse/MYN-4787
  const { clientId } = useClientContext();
  userGroupApi.userGroup = { ...userGroupApi.userGroup, clientId: clientId! };

  return (
    <>
      <NuButton type="primary" icon={<UserAddIcon />} onClick={handleOpenModal} disabled={disabled}>
        Add Members
      </NuButton>
      {isModalOpen && <AddUserGroupModal onCloseModal={handleCloseModal} userGroupApi={userGroupApi} />}
    </>
  );
}

export default AddUsersToGroupBtn;
