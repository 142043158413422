import React from 'react';
import styled, { css } from 'styled-components';
import { Button, Col, Row, Tooltip, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Maybe } from 'types';
import { NuDivider } from 'components/nuspire';

/**
 * NuCardBody
 */
export interface NuCardBodyProps {
  header?: Maybe<string>;
  subHeader?: Maybe<string>;
  tooltip?: Maybe<string>;
  divider?: boolean;
  children: React.ReactNode;
}

const GradientDiv = styled.div`
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;

  ${(p) =>
    ['light', 'pdi-light'].includes(p.theme.mode)
      ? css`
          background: linear-gradient(180deg, ${p.theme.color.primaryGray} 50%, rgba(255, 255, 255, 0) 100%);
          border-color: ${p.theme.color.primaryGray};
          border-top: 2px solid ${p.theme.color.gray2};
        `
      : css`
          background: linear-gradient(180deg, ${p.theme.token.colorBgElevated} 50%, transparent 100%);
          border-top: 2px solid ${p.theme.token.colorBorder};
        `}
`;

// TEST IDS
export const ROOT_TEST_ID = 'nu-card-body';
export const HEADER_TEST_ID = `${ROOT_TEST_ID}-header`;
export const SUB_HEADER_TEST_ID = `${ROOT_TEST_ID}-sub-header`;
export const TOOLTIP_BUTTON_TEST_ID = `${ROOT_TEST_ID}-tooltip-button`;

/**
 * Nuspire Card body with Gradient
 */
function NuCardBody(props: NuCardBodyProps) {
  const { header, subHeader, tooltip, divider = false, children } = props;

  return (
    <GradientDiv data-testid={ROOT_TEST_ID}>
      <Row>
        <Col span={20}>
          {header && (
            <Typography.Title style={{ marginBottom: 0 }} level={4} data-testid={HEADER_TEST_ID}>
              {header}
            </Typography.Title>
          )}
          {subHeader && (
            <Typography.Text type="secondary" data-testid={SUB_HEADER_TEST_ID}>
              {subHeader}
            </Typography.Text>
          )}
        </Col>
        {tooltip && (
          <Col span={4}>
            <Tooltip placement="left" title={tooltip}>
              <Button
                data-testid={TOOLTIP_BUTTON_TEST_ID}
                size="small"
                type="text"
                shape="circle"
                style={{ float: 'right' }}
                icon={<QuestionCircleOutlined />}
              />
            </Tooltip>
          </Col>
        )}
      </Row>
      {divider && <NuDivider />}
      {children}
    </GradientDiv>
  );
}

export default NuCardBody;
