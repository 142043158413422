import { gql, useMutation, useQuery } from '@apollo/client';
import { Badge, Popover, Tabs, theme, Tooltip, Typography } from 'antd';
import classNames from 'classnames';
import { useAuthContext } from 'components/auth-context';
import { EmptyState, Link, NuButton } from 'components/nuspire';
import { ChevronRight, Info as InfoIcon, NotificationBell, SettingsIcon } from 'components/nuspire/nu-icon';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import baseTheme from 'components/theme';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Unpacked } from 'types';
import {
  EntityNotificationsByTypeIdQuery,
  EntityNotificationUpdatedSubscription,
  NestedEntityNotificationsQuery,
  TypeNotificationCreatedSubSubscription,
  TypeNotificationsQuery,
  UserNotificationCountQuery,
  UserNotificationCountUpdatedSubscription,
  UserTypeNotificationsQuery,
} from 'types/graph-codegen/graph-types';

import { ArrowsAltOutlined } from '@ant-design/icons';
import { ENTITY_NOTIFICATION_FIELDS, TYPE_NOTIFICATION_FIELDS, USER_NOTIFICATION_FIELDS } from './graphql';
import { notificationSettingsRoute, notificationsRoute } from './routes';

const { useToken } = theme;

const USER_NOTIFICATIONS_BY_ENTITY = gql`
  ${USER_NOTIFICATION_FIELDS}

  query NestedEntityNotifications($entityNotificationId: String!) {
    userNotificationsByEntity(entityNotificationId: $entityNotificationId) {
      ...UserNotificationFields
    }
  }
`;

export type TypeNotifications = NonNullable<TypeNotificationsQuery['typeNotifications']>;
export type TypeNotification = Unpacked<TypeNotifications>;
export type EntityNotification = Unpacked<TypeNotification['entityNotifications']>;
export type UserNotification = Unpacked<EntityNotification['notifications']>;
export type NotificationEntity = NonNullable<UserNotification['entity']>;

const NlicRoot = styled.div`
  padding: 12px 14px;
  display: flex;
  column-gap: 10px;

  &.clickable {
    cursor: pointer;

    &:hover {
      background-color: ${(p) => p.theme.token.colorFillAlter};
    }
  }
`;

const NlicCollapseContainer = styled.div<{
  expanded?: boolean;
}>`
  width: 26px;
  display: flex;
  justify-content: center;

  .collapse-arrow {
    transition: transform 0.3s ease;
  }
  .expanded & .collapse-arrow {
    transform: rotate(90deg);
  }
`;
const NlicMain = styled.div`
  flex: 1;
  display: flex;
  column-gap: 14px;
`;
const NlicContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const NlicMeta = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const NlicTitle = styled.div<{
  type?: 'primary' | 'secondary';
}>`
  font-weight: 700;

  color: ${(p) => {
    if (p.type === 'secondary') {
      return p.theme.token.colorTextSecondary;
    }
    return p.theme.token.colorText;
  }};
`;

const NlicSecondary = styled.div``;

const NlicRelativeTimeRoot = styled.div`
  color: ${(p) => p.theme.token.colorTextTertiary};
`;

function NlicRelativeTime({ timestamp }: { timestamp: string }) {
  const fromNow = dayjs(timestamp).fromNow();

  return (
    <Tooltip title={new Date(timestamp).toLocaleString()}>
      <NlicRelativeTimeRoot>{fromNow}</NlicRelativeTimeRoot>
    </Tooltip>
  );
}

export function NotificationListItemCard(props: {
  title: React.ReactNode;

  secondary?: React.ReactNode; // use for Entity + View More?

  type?: 'primary' | 'secondary';

  // expandable list items with arrow...
  expandable?: boolean; // false by default.
  expanded?: boolean;

  // TypeNotification Cards with more than one EntityNotification will expand.
  onClick?: () => void;

  // EntityNotification + UserNotification Cards should have links to navigate to..
  link?: string; // should link users deep into the app

  timestamp?: string;
  read?: boolean;
  onMarkRead?: (markRead: boolean) => void;

  styles?: {
    root?: React.CSSProperties;
  };
}) {
  const { expandable, expanded, title, timestamp, secondary, styles = {}, onClick, link } = props;
  const clickable = Boolean(link || onClick);
  const classes = classNames({ clickable, expanded });

  const card = (
    <NlicRoot style={styles.root} onClick={onClick} className={classes}>
      <NlicCollapseContainer expanded={expanded}>
        {expandable && <ChevronRight className="collapse-arrow" />}
      </NlicCollapseContainer>
      <NlicMain>
        <NlicContent>
          <NlicTitle>{title}</NlicTitle>
          {secondary && <NlicSecondary>{secondary}</NlicSecondary>}
        </NlicContent>
        <NlicMeta>{timestamp && <NlicRelativeTime timestamp={timestamp} />}</NlicMeta>
      </NlicMain>
    </NlicRoot>
  );

  if (link) {
    // Links are currently broken. These need to be rel links or else we need to use a tags.
    return (
      <div
        onClick={(e) => {
          e.preventDefault();
          const newTab = e.metaKey || e.ctrlKey;
          window.open(link, newTab ? '_blank' : '_self');
        }}
      >
        {card}
      </div>
    );
  }

  return card;
}

const ENTITY_NOTIFICATIONS_BY_TYPE_ID = gql`
  ${ENTITY_NOTIFICATION_FIELDS}

  query EntityNotificationsByTypeId($typeNotificationId: String!) {
    entityNotificationsByTypeId(typeNotificationId: $typeNotificationId) {
      ...EntityNotificationFields
    }
  }
`;

const ENTITY_NOTIFICATION_UPDATED_SUB = gql`
  ${ENTITY_NOTIFICATION_FIELDS}

  subscription EntityNotificationUpdated($typeNotificationId: String!) {
    entityNotificationUpdated(typeNotificationId: $typeNotificationId) {
      ...EntityNotificationFields
    }
  }
`;

export function NestedEntityNotificationsByTypeQuery(props: { typeNotification: TypeNotification }) {
  const {
    typeNotification: { id },
  } = props;

  const { data, loading, subscribeToMore } = useQuery<EntityNotificationsByTypeIdQuery>(
    ENTITY_NOTIFICATIONS_BY_TYPE_ID,
    {
      variables: {
        typeNotificationId: id,
      },
    },
  );

  // Subscribe to updates
  useEffect(() => {
    subscribeToMore<EntityNotificationUpdatedSubscription>({
      document: ENTITY_NOTIFICATION_UPDATED_SUB,
      variables: { typeNotificationId: id },
      updateQuery: (prev, { subscriptionData }) => {
        console.log('ENTITY_NOTIFICATION_UPDATED_SUB', { prev, subscriptionData });

        // find the entity notification that needs to be updated;
        const updatedEntityNotification = subscriptionData.data.entityNotificationUpdated;
        if (!updatedEntityNotification) return prev;

        const updated = {
          entityNotificationsByTypeId: prev.entityNotificationsByTypeId.map((i) =>
            i.id === updatedEntityNotification.id ? updatedEntityNotification : i,
          ),
        };

        console.log({ updated });
        return updated;
      },
    });
  }, []);

  // Render
  const entityNotifications = data?.entityNotificationsByTypeId;

  if (entityNotifications) {
    return (
      <>
        {entityNotifications.map((n) => (
          <EntityNotificationCard key={n.id} entityNotification={n} />
        ))}
      </>
    );
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  return <div>nested Entity Notifications Query</div>;
}

const NotificationGroup = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.token.colorBorder};

  &:last-child {
    border-bottom: none;
  }
`;

/**
 * TypeNotificationListItem
 *
 * should expand if more than 1 Entity Notification
 * @param props
 */
export function ExpandableTypeNotificationListItem(props: { typeNotification: TypeNotification }) {
  const {
    typeNotification,
    typeNotification: { count, timestamp, pluralTitle },
  } = props;
  const title = `${count} ${pluralTitle}`;

  const [expanded, setExpanded] = useState<boolean>(false);

  const classes = classNames({ expanded });

  const handleClick = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <NotificationGroup className={classes}>
      <NotificationListItemCard
        title={title}
        timestamp={timestamp}
        expandable
        expanded={expanded}
        onClick={handleClick}
        read={false}
        onMarkRead={() => {
          console.log('mark as read...');
        }}
      />

      {expanded && (
        <>
          <NestedEntityNotificationsByTypeQuery typeNotification={typeNotification} />
        </>
      )}
    </NotificationGroup>
  );
}

function EntityLabel(props: { entity: NotificationEntity }) {
  const {
    entity: { title, status, link },
  } = props;

  return (
    <Typography.Text type="secondary">
      <InfoIcon
        style={{
          color: baseTheme.color.nuspireBlue,
          marginRight: '4px',
        }}
      />
      {title}
    </Typography.Text>
  );
}

const EntityContentRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

export function EntityContent(props: {
  entity?: NotificationEntity;
  count?: number;
  expanded?: boolean;
  onExpand: React.MouseEventHandler<HTMLButtonElement>;
}) {
  const { entity, count, expanded, onExpand } = props;

  const moreText = count && count > 1 ? `+${count - 1} more` : null;

  return (
    <EntityContentRoot>
      {entity && <EntityLabel entity={entity} />}
      {!expanded && moreText && <Typography.Link onClick={onExpand}>{moreText}</Typography.Link>}
    </EntityContentRoot>
  );
}

export function NestedUserNotificationsList({ userNotifications }: { userNotifications: UserNotification[] }) {
  return (
    <>
      {userNotifications.map((n) => (
        <NotificationListItemCard
          key={n.id}
          title={n.title}
          type="secondary"
          onMarkRead={(markRead) => {
            console.log({ markRead });
          }}
          read={false}
          timestamp={n.timestamp}
          link={n.entity?.link?.url}
        />
      ))}
    </>
  );
}

export function NestedUserNotificationsQuery(props: { entityNotification: EntityNotification }) {
  const {
    entityNotification: { id },
  } = props;

  const { data, loading } = useQuery<NestedEntityNotificationsQuery>(USER_NOTIFICATIONS_BY_ENTITY, {
    variables: {
      entityNotificationId: id,
    },
  });

  const nestedNotifications = data?.userNotificationsByEntity;

  if (nestedNotifications) {
    return <NestedUserNotificationsList userNotifications={nestedNotifications} />;
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  return <EmptyState>Failed to fetch notifications.</EmptyState>;
}

export function EntityNotificationCard(props: { entityNotification: EntityNotification }) {
  const {
    entityNotification,
    entityNotification: { notifications, count },
  } = props;
  const firstNotification = notifications[0];

  const [expanded, setExpanded] = useState<boolean>(false);
  const { token } = useToken();

  if (firstNotification) {
    const { title, timestamp, entity } = firstNotification;
    const url = entity?.link?.url;

    return (
      <>
        <NotificationListItemCard
          title={title}
          timestamp={timestamp}
          read={false}
          onMarkRead={() => {
            console.log('mark as read...');
          }}
          link={url}
          secondary={
            <EntityContent
              entity={entity}
              count={count}
              expanded={expanded}
              onExpand={(e) => {
                setExpanded(true);
                e.stopPropagation();
              }}
            />
          }
        />
        {expanded && (
          <>
            <NestedUserNotificationsQuery entityNotification={entityNotification} />
            <NotificationListItemCard
              read={false}
              title={
                <Typography.Link style={{ fontWeight: 300 }} onClick={() => setExpanded(false)}>
                  Show less
                </Typography.Link>
              }
              styles={{
                root: {
                  borderBottom: `1px solid ${token.colorBorder}`,
                },
              }}
            />
          </>
        )}
      </>
    );
  }

  // bug
  return null;
}

export function SingleTypeNotificationListItem(props: { typeNotification: TypeNotification }) {
  // Return first nested EntityNotification
  const {
    typeNotification: { entityNotifications },
  } = props;
  const entityNotification = entityNotifications?.[0];

  if (entityNotification) {
    return (
      <NotificationGroup>
        <EntityNotificationCard entityNotification={entityNotification} />
      </NotificationGroup>
    );
  }

  // bug
  return null;
}

export function TypeNotificationListItem(props: { typeNotification: TypeNotification }) {
  const {
    typeNotification,
    typeNotification: { count },
  } = props;

  if (count && count > 1) {
    return <ExpandableTypeNotificationListItem {...props} />;
  }

  return <SingleTypeNotificationListItem {...props} />;
}
// end TypeNotificationListItem

const NotificationList = (props: { typeNotification: TypeNotification[] }) => {
  const { typeNotification } = props;
  return <>{typeNotification?.map((item) => <TypeNotificationListItem key={item.id} typeNotification={item} />)}</>;
};

const NotificationPopoverContentRoot = styled.div`
  width: 512px;
`;

/**
 * Graphql
 */
const GET_TYPE_NOTIFICATIONS = gql`
  ${TYPE_NOTIFICATION_FIELDS}

  query TypeNotifications {
    typeNotifications {
      ...TypeNotificationFields
    }
  }
`;

const GET_NON_FEED_ITEM_TYPE_NOTIFICATIONS = gql`
  ${TYPE_NOTIFICATION_FIELDS}

  query UserTypeNotifications {
    queryUserTypeNotifications(size: 100, isFeedItem: false) {
      items {
        ...TypeNotificationFields
      }
    }
  }
`;

const TYPE_NOTIFICATION_CREATED_SUB = gql`
  ${TYPE_NOTIFICATION_FIELDS}

  subscription TypeNotificationCreatedSub($userId: String!) {
    typeNotificationCreated(userId: $userId) {
      ...TypeNotificationFields
    }
  }
`;

export function NotificationsGroupedListQuery() {
  const { data, subscribeToMore, loading } = useQuery<UserTypeNotificationsQuery>(GET_NON_FEED_ITEM_TYPE_NOTIFICATIONS);
  const { user } = useAuthContext();
  const userId = user?.id;

  /**
   * Subscription
   */
  useEffect(() => {
    if (!userId) return;

    // Type Notification was created. Add to top of list.
    subscribeToMore<TypeNotificationCreatedSubSubscription>({
      document: TYPE_NOTIFICATION_CREATED_SUB,
      variables: { userId },
      updateQuery: (prev, { subscriptionData }) => {
        const typeNotification = subscriptionData.data?.typeNotificationCreated;
        if (!typeNotification) return prev;

        const updated = {
          queryUserTypeNotifications: {
            ...prev.queryUserTypeNotifications,
            items: [typeNotification, ...prev.queryUserTypeNotifications.items],
          },
        };

        // update the cache.
        return updated;
      },
    });
  }, [userId]);

  const typeNotifications = data?.queryUserTypeNotifications.items;

  if (typeNotifications) {
    return <NotificationList typeNotification={typeNotifications} />;
  }
  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  return null;
}

const NotificationsPopoverHeaderRoot = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 8px;
`;
const NotificationsPopoverHeaderActions = styled.div`
  display: flex;
  column-gap: 4px;
`;

const NotificationsListViewLink = () => (
  <Link to={notificationsRoute()} target="_blank">
    <Tooltip title="View List View" placement="bottom">
      <NuButton icon={<ArrowsAltOutlined />} shape="circle" type="link" />
    </Tooltip>
  </Link>
);

const NotificationsSettingsLink = () => (
  <Link to={notificationSettingsRoute()} target="_blank">
    <Tooltip title="Notification Settings" placement="bottom">
      <NuButton icon={<SettingsIcon />} shape="circle" type="link" />
    </Tooltip>
  </Link>
);

function NotificationsPopoverHeader() {
  return (
    <NotificationsPopoverHeaderRoot>
      <Typography.Title level={4}>Notifications</Typography.Title>

      <NotificationsPopoverHeaderActions>
        <NotificationsListViewLink />
      </NotificationsPopoverHeaderActions>
    </NotificationsPopoverHeaderRoot>
  );
}

function NotificationsPopoverContent() {
  return (
    <NotificationPopoverContentRoot>
      <NotificationsPopoverHeader />

      <Tabs
        items={[
          {
            key: 'direct',
            label: 'Direct',
            children: <NotificationsGroupedListQuery />,
          },
        ]}
      />
    </NotificationPopoverContentRoot>
  );
}

const USER_NOTIFICATION_COUNT = gql`
  query UserNotificationCount {
    currentAuthenticatedUser {
      id
      notificationCount
    }
  }
`;

const USER_UPDATED_SUB = gql`
  subscription UserNotificationCountUpdated($id: String!) {
    userUpdated(id: $id) {
      id
      notificationCount
    }
  }
`;

function NotificationButtonWithCount() {
  // Get query count for badge.
  const { data, subscribeToMore } = useQuery<UserNotificationCountQuery>(USER_NOTIFICATION_COUNT);
  const notificationCount = data?.currentAuthenticatedUser?.notificationCount ?? 0;

  // current user id
  const { user } = useAuthContext();
  const userId = user?.id;

  useEffect(() => {
    if (!userId) return; // can't subscribe without a user id.

    subscribeToMore<UserNotificationCountUpdatedSubscription>({
      document: USER_UPDATED_SUB,
      variables: { id: userId },
      updateQuery: (prev, { subscriptionData }) => {
        const updatedUser = subscriptionData.data.userUpdated;
        if (!updatedUser) return prev;

        return {
          currentAuthenticatedUser: updatedUser,
        };
      },
    });
  }, [userId]);

  const btn = (
    <NuButton type="text" shape="circle" size="large">
      <NotificationBell />
    </NuButton>
  );

  if (notificationCount) {
    return <Badge count={notificationCount}>{btn}</Badge>;
  }

  return btn;
}

const UPDATE_NOTIFICATION_COUNT = gql`
  mutation UpdateUserNotificationCount($userId: String!, $notificationCount: Int) {
    updateUser(userId: $userId, notificationCount: $notificationCount) {
      id
      notificationCount
    }
  }
`;

export function NotificationsButton() {
  const { user } = useAuthContext();
  const userId = user?.id;

  const [updateUserCount] = useMutation(UPDATE_NOTIFICATION_COUNT);

  const handlePopoverClick = async (visible: boolean) => {
    if (visible) {
      await updateUserCount({
        variables: {
          userId,
          notificationCount: 0,
        },

        optimisticResponse: {
          updateUser: {
            id: userId,
            notificationCount: 0,
            __typename: 'User',
          },
        },
      });
    }
  };

  return (
    <Popover
      trigger={['click']}
      onOpenChange={handlePopoverClick}
      placement="bottomRight"
      destroyTooltipOnHide
      content={<NotificationsPopoverContent />}
    >
      <div>
        <NotificationButtonWithCount />
      </div>
    </Popover>
  );
}
