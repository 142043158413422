import { Space, Typography } from 'antd';
import { Chart } from 'chart.js';
import { useClientContext } from 'components/client-context-provider';
import { useDashboardContext } from 'components/dashboard/dashboard-context';
import { EmptyState } from 'components/nuspire';
import { ColoredCircle } from 'components/shared-components';
import { useEffect, useRef } from 'react';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { Link, useNavigate } from 'react-router';
import styled, { css, useTheme } from 'styled-components';
import { WidgetComponentProps } from '../..';
import useElementSize from '../../../../../utils/react-hooks/use-element-size';

type Priority = '1' | '2' | '3' | '4';
type RemediationsData = {
  [K in Priority]: number;
};

export type Data = {
  remediationsData: RemediationsData;
  type: 'cases' | 'incidents';
};

type WidgetSizeMode = 'sm' | 'md';

const priorityMap = {
  1: '1 - Critical',
  2: '2 - High',
  3: '3 - Moderate',
  4: '4 - Low',
} as const;

function buildFilterSearchParams(args: { priority: number; time?: string }) {
  const { priority, time } = args;

  const priorityString: string = priorityMap[priority];
  const searchParamsArr = [['priorities', priorityString]];

  if (time) {
    searchParamsArr.push(['time', time]);
  }

  return new URLSearchParams(searchParamsArr);
}

interface BarGraphProps {
  data: Data;
}

function BarGraph(props: BarGraphProps) {
  const {
    data: { type, remediationsData: data },
  } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const { clientId } = useClientContext();
  const { time } = useDashboardContext();
  const chartRef = useRef<Chart<'bar'>>(null);

  const chartData = {
    labels: ['Priority 1', 'Priority 2', 'Priority 3', 'Priority 4'],
    datasets: [
      {
        data: [data['1'], data['2'], data['3'], data['4']],
        backgroundColor: theme.color.nuspireBlue,
      },
    ],
  };

  const onBarClick = (event: any) => {
    if (!chartRef.current) return;

    const dataset = getElementAtEvent(chartRef.current, event);
    const barElement = dataset[0];
    if (!barElement) return;
    const { index } = barElement;

    let path = `/${clientId}/case-management`;
    if (type === 'cases') {
      path = `${path}/cases`;
    } else {
      path = `${path}/incidents`;
    }

    const searchParams = buildFilterSearchParams({
      priority: index + 1,
      time,
    });

    navigate(`${path}?${searchParams}`);
  };

  return (
    <Bar
      ref={chartRef}
      data={chartData}
      onClick={onBarClick}
      options={{
        scales: {
          x: { stacked: true },
          y: { stacked: true },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        maintainAspectRatio: false,
      }}
    />
  );
}

const FlexSpace = styled(Space)`
  display: flex;
  width: 100%;
`;

const ItemSpace = styled(FlexSpace)`
  justify-content: space-between;
  padding: 0 1.5rem;
  height: 100%;
`;

interface PriorityListItemProps {
  priority: Priority;
  count: number;
  link: string;
}

function PriorityListItem(props: PriorityListItemProps) {
  const { priority, count, link } = props;
  const theme = useTheme();
  const priorityColorMap = {
    '1': theme.color.error,
    '2': theme.color.orange,
    '3': theme.color.yellow,
    '4': theme.color.green,
  };

  return (
    <Link to={link}>
      <ItemSpace>
        <Space>
          <ColoredCircle color={priorityColorMap[priority]} />
          <Typography.Text>Priority {priority}</Typography.Text>
        </Space>
        <Typography.Text>{count ?? 0}</Typography.Text>
      </ItemSpace>
    </Link>
  );
}

const PriorityList = styled(FlexSpace).attrs({})`
  flex: 0;
  justify-content: center;
  flex-wrap: wrap;
  > .ant-space-item {
    width: 48%;
    background-color: ${(p) => p.theme.token.colorFillAlter};
    height: 50px;
  }
`;

const Wrapper = styled.div<{
  sizeMode: WidgetSizeMode;
}>`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 24px;
  align-items: stretch;

  padding-bottom: 8px;

  flex-direction: column;

  ${(p) =>
    p.sizeMode === 'md' &&
    css`
      flex-direction: row;
      ${PriorityList} {
        flex: 0.4;
        flex-wrap: none;
        padding: 1.5rem 0;
        flex-direction: column;

        > .ant-space-item {
          width: 100%;
          height: 60px;
        }
      }
    `};
`;

interface ServiceNowRemediationsByPriorityProps {
  data: Data;
  clientId?: string;
}

export function ServiceNowRemediationsByPriority(props: ServiceNowRemediationsByPriorityProps) {
  const { data, clientId } = props;
  const { time } = useDashboardContext();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const { width } = useElementSize(wrapperRef);
  const sizeMode: WidgetSizeMode = width > 768 ? 'md' : 'sm';

  const priorityList = [...Array(4)].map((_, i) => {
    const priority = i + 1;
    const searchParams = buildFilterSearchParams({ priority, time });
    const link = `/${clientId!}/case-management/${data.type}?${searchParams}`;
    return {
      priority: priority.toString() as Priority,
      count: data.remediationsData[priority],
      link,
    };
  });

  return (
    <Wrapper ref={wrapperRef} sizeMode={sizeMode}>
      <div style={{ flex: 1, position: 'relative', minWidth: 0, minHeight: 0 }}>
        <BarGraph data={data} />
      </div>
      <PriorityList>
        {priorityList.map((v) => (
          <PriorityListItem key={v.priority} {...v} />
        ))}
      </PriorityList>
    </Wrapper>
  );
}

function ServiceNowRemediationsByPriorityWidget(props: WidgetComponentProps<any, Data>) {
  const { data, setSubAction } = props;
  const { client, clientId } = useClientContext();

  const params = new URLSearchParams({});
  if (client?.isMultiTenancyEnabled) {
    params.append('includeChildren', 'true');
  }

  useEffect(() => {
    if (setSubAction) {
      if (data.type === 'cases') {
        setSubAction(
          <Link to={`/${clientId}/case-management/cases?${params.toString()}`} style={{ marginRight: '1rem' }}>
            View All Cases
          </Link>,
        );
      } else {
        setSubAction(
          <Link to={`/${clientId}/case-management/incidents?${params.toString()}`} style={{ marginRight: '1rem' }}>
            View All Incidents
          </Link>,
        );
      }
    }
  }, []);

  if (!data) return <EmptyState>No Data Found.</EmptyState>;

  return <ServiceNowRemediationsByPriority data={data} clientId={clientId} />;
}

export const ServiceNowRemediationsByPriorityBarGraph = BarGraph;
export default ServiceNowRemediationsByPriorityWidget;
