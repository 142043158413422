import { Badge, Descriptions, Space, Typography } from 'antd';
import { useClientContext } from 'components/client-context-provider';
import { CONNECTORS } from 'components/connections/connectors';
import { Link, NuButton, NuCardContent, NuPaper } from 'components/nuspire';
import { ConnectionsIcon } from 'components/nuspire/nu-icon';
import styled from 'styled-components';
import { WorkflowListItemFieldsFragment as WorkflowListItemFieldsFragment } from 'types/graph-codegen/graph-types';
import { createAutomationPath, automationDetailPath } from './routes';
import { getNextRunTime } from 'components/connections/connector-detail/connection-detail/connection-schedules/connection-schedules';

const DsliRoot = styled.div`
  margin-bottom: 24px;
`;
const DsliContent = styled.div`
  padding: 16px 24px;
  display: flex;
  align-items: center;
`;
const DsliIcon = styled.div`
  width: 32px;
  height: 32px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`;
const EmptyIcon = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${(p) => p.theme.token.colorFillContent};
  border-radius: 8px;
`;
const DsliName = styled.div`
  padding-right: 16px;
  margin-top: 2px;
  flex: 2;
`;

const FlexCol = styled.div`
  flex: 1;
`;
const DsliEnabled = styled.div`
  flex: 1;
`;

export type Connector = { __typename?: 'SystemConnector'; id: string; name: string; slug: string };
export type DataSourceDefinition = {
  __typename?: 'DataSourceDefinition';
  id: string;
  name: string;
  description?: string | undefined;
  canEnableDisable?: boolean | undefined;
};

export type AutomationConnection = NonNullable<WorkflowListItemFieldsFragment['connection']>;

const ConnectionNameIcon = styled.div`
  width: 16px;
  height: 16px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
`;

export function AutomationConnectionName(props: { connection?: AutomationConnection; connectorSlug?: string }) {
  const { connection, connectorSlug } = props;
  if (!connection) {
    return null;
  }

  const { name } = connection;

  const frontendConnector = connectorSlug ? CONNECTORS.find((e) => e.slug === connectorSlug) ?? null : null;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {frontendConnector ? (
        <ConnectionNameIcon>{frontendConnector?.icon ?? <EmptyIcon />}</ConnectionNameIcon>
      ) : (
        <ConnectionsIcon style={{ marginRight: '4px' }} />
      )}

      <Typography.Text type="secondary">
        <Typography.Text>{name}</Typography.Text>
      </Typography.Text>
    </div>
  );
}

export function AutomationListItem(props: {
  connector: Connector;
  automation?: WorkflowListItemFieldsFragment;
  automationDefinition: DataSourceDefinition;
  connectionId?: string;
  showConnectionName?: boolean;
}) {
  const {
    // automation: { id, name, dataSourceDefinition, enabled = false, status },
    automationDefinition,
    automation,
    connector,
    connectionId,
    showConnectionName = true,
  } = props;
  const connectorSlug = connector.slug ?? null;
  const defName = automationDefinition?.name ?? null;
  const frontEndConnector = connectorSlug ? CONNECTORS.find((c) => c.slug === connectorSlug) ?? null : null;

  const { clientId } = useClientContext();
  if (!clientId) {
    return null;
  }

  const path = automation
    ? automationDetailPath({ clientId, id: automation.id })
    : createAutomationPath({ clientId, slug: automationDefinition.id, connectionId });

  const connection = automation?.connection;

  const scheduledTasks = automation?.schedule?.scheduledTasks;
  const nextRunTime = getNextRunTime(scheduledTasks);

  return (
    <DsliRoot>
      <Link to={path}>
        <NuPaper hoverable>
          <DsliContent>
            <DsliIcon>{frontEndConnector?.icon ?? <EmptyIcon />}</DsliIcon>

            <DsliName>
              <Space direction="vertical" size="small">
                <Typography.Title
                  level={4}
                  style={{
                    marginBottom: 0,
                  }}
                >
                  {defName}
                </Typography.Title>
              </Space>
            </DsliName>

            {automation ? (
              <>
                <FlexCol>
                  <Badge
                    status={automation.status === 'healthy' ? 'success' : 'error'}
                    size="default"
                    text={
                      automation.status
                        ? automation.status.charAt(0).toUpperCase() + automation.status.slice(1)
                        : 'Unknown'
                    }
                  />
                </FlexCol>

                {showConnectionName && (
                  <FlexCol style={{ flex: 2 }}>
                    <Descriptions.Item label="Connection">
                      <AutomationConnectionName connection={connection} />
                    </Descriptions.Item>
                  </FlexCol>
                )}
                <FlexCol
                  style={{
                    flex: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    paddingTop: '12px',
                  }}
                >
                  <Typography.Text type="secondary">Next Run</Typography.Text>
                  <Typography.Paragraph>{nextRunTime}</Typography.Paragraph>
                </FlexCol>
              </>
            ) : (
              <>
                <NuButton type="primary">Setup</NuButton>
              </>
            )}
          </DsliContent>
        </NuPaper>
      </Link>
    </DsliRoot>
  );
}
