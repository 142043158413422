import { Dropdown, Space } from 'antd';
import { useClientContext } from 'components/client-context-provider';
import { ClearFiltersButton, ComplexSearchSelector, ComplexSearchSelectorOverflow } from 'components/complex-search';
import { ComplexSearchFilterButton, IInput, SearchInputRoot } from 'components/data-explorer/data-explorer';
import { FilterDropdownRoot, FilterInputListItem, FilterListRoot } from 'components/data-explorer/filter-dropdown';
import { FilterDefTag, FilterTag, FilterTagsRoot } from 'components/data-explorer/filter-tags';
import { Input } from 'components/data-explorer/search-input';
import PageHeader from 'components/nuspire/nu-page-header';
import React, { useEffect, useRef, useState } from 'react';
import useSearchParams, { SearchParamsInterface } from 'utils/react-hooks/useSearchParams';

import { UserOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { useAuthContext } from 'components/auth-context';
import { ClientTaskCard } from 'components/client-tasks/client-task-card';
import { TECHNOLOGY_OPTIONS } from 'components/client-tasks/create-client-task';
import { EmptyState, NuButton } from 'components/nuspire';
import Breadcrumb from 'components/nuspire/nu-breadcrumb';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { Link, useNavigate } from 'react-router';
import styled from 'styled-components';
import { ActionFormFieldType } from 'types/graph-codegen/graph-types';
import { client } from 'utils/graphql';
import { useTimeMixpanelEvent } from 'utils/mixpanel/mixpanel-page-timer';
import { Content } from '../../layouts/content';

const filterInputs: IInput[] = [
  {
    key: 'status',
    label: 'Status',
    type: 'array' as any,
    inputType: 'select',
    defaultString: 'todo,in_progress,review,open',
    parameters: {
      placeholder: 'Select Task Status',
      mode: 'multiple',
      options: [
        {
          key: 'todo',
          label: 'To Do',
          value: 'todo',
        },
        {
          key: 'in_progress',
          label: 'In Progress',
          value: 'in_progress',
        },
        {
          key: 'review',
          label: 'Ready for Review',
          value: 'review',
        },
        {
          key: 'completed',
          label: 'Completed',
          value: 'completed',
        },
        {
          key: 'suppressed',
          label: 'Suppressed',
          value: 'suppressed',
        },
        {
          key: 'archived',
          label: 'Archived',
          value: 'archived',
        },
        {
          key: 'open',
          label: 'Open',
          value: 'open',
        },
        {
          key: 'closed',
          label: 'Closed',
          value: 'closed',
        },
      ],
    },
  },
  {
    key: 'members',
    label: 'Members',
    type: 'array' as any,
    inputType: 'user_select',
    parameters: {
      placeholder: 'Select Member(s)',
      mode: 'multiple',
    },
  },
  {
    key: 'tags',
    label: 'Tags',
    type: 'array' as any,
    inputType: 'multi_input_text',
  },
  {
    key: 'technology',
    label: 'Technology',
    type: ActionFormFieldType.Array,
    inputType: 'select',
    parameters: {
      options: TECHNOLOGY_OPTIONS,
    },
  },
];
export const SEARCH = 'search';

const TaskListControlsRoot = styled.div`
  margin-bottom: 32px;
  display: flex;
`;
const ComplexSearchRoot = styled.div`
  flex: 1;
  margin-right: 24px;
`;
const QuickFiltersRoot = styled.div`
  display: flex;

  > * {
    margin-right: 8px;

    &:last-child {
      margin-right: 0px;
    }
  }
`;

/**
 * FILTER DROPDOWN
 */
export function FilterDropdown(props: {
  filterInputs: IInput[];
  handleAddFilter: (key: string, value: string) => void;
  handleRemoveFilter: (key: string) => void;
}) {
  const { handleAddFilter, handleRemoveFilter } = props;

  return (
    <FilterDropdownRoot onClick={(e) => e.stopPropagation()}>
      <FilterListRoot>
        {props.filterInputs?.map((filterInput) => (
          <FilterInputListItem
            key={`filter-input-${filterInput.key}`}
            filterInput={filterInput}
            handleAddFilter={handleAddFilter}
            handleRemoveFilter={handleRemoveFilter}
          />
        ))}
      </FilterListRoot>
    </FilterDropdownRoot>
  );
}

/**
 * FILTER TAGS
 */
export function FilterTags(props: { filterInputs?: IInput[] }) {
  const { parsed: params, setParameter } = useSearchParams();

  const handleRemoveFilter = (key: string) => {
    setParameter(key, null);
  };

  const searchValue = params[SEARCH];

  return (
    <FilterTagsRoot>
      {props.filterInputs?.map((def) => (
        <FilterDefTag
          key={def.key}
          filterDefinition={def}
          searchParams={params}
          handleRemove={() => handleRemoveFilter(def.key)}
        />
      ))}

      {Boolean(searchValue !== null && searchValue !== undefined) && (
        <FilterTag label="Search" onRemove={() => handleRemoveFilter(SEARCH)} color="purple" />
      )}
    </FilterTagsRoot>
  );
}

/**
 * TASK LIST CONTROLS
 */
function TaskListControls(props: { searchParams: SearchParamsInterface }) {
  const { searchParams } = props;
  // ========== Refs ===========
  const inputRef = useRef<HTMLInputElement>(null);

  const onClick = ({ target }) => {
    if (target !== inputRef.current) {
      const isIE = (document.body.style as any).msTouchAction !== undefined;
      if (isIE) {
        setTimeout(() => {
          inputRef?.current?.focus();
        });
      } else {
        inputRef?.current?.focus();
      }
    }
  };

  // ========== State ===========
  const [filterDropdownVisible, setFilterDropdownVisible] = useState<boolean>(false);
  const handleOpenSearch = () => setFilterDropdownVisible(true);
  const handleCloseSearch = () => {
    setFilterDropdownVisible(false);
  };

  // ============ Filters ===========
  const { parsed: params, setParameter, clearAll } = searchParams;
  const handleClearFilters = () => {
    clearAll();
  };

  const handleAddFilter = (key: string, value: string) => {
    console.log('handleFilter', { key, value });
    setParameter(key, value);
  };
  const handleRemoveFilter = (key: string) => {
    setParameter(key, null);
  };

  // ========== Search Input =========
  const [searchValue, setSearchValue] = useState<string>('');

  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const {
      target: { value },
    } = event;

    setSearchValue(value);
  };

  const updateSearchTag = () => {
    if (searchValue.length) {
      setParameter(SEARCH, searchValue);
      setSearchValue('');
    }
  };

  const deleteLastTag = () => {
    if (params[SEARCH]?.length) {
      // remove search tag.
      setParameter(SEARCH, null);
    } else {
      const tags = filterInputs?.filter((f) => params[f.key]?.length) ?? [];
      const lastTag = tags[tags.length - 1];

      if (lastTag) {
        setParameter(lastTag.key, null);
      }
    }
  };

  // ========== Input Node ==========
  const handleInputKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    const { key } = event;

    if (key === 'Enter') {
      updateSearchTag();
    }

    if (key === 'Backspace' && searchValue.length === 0) {
      deleteLastTag();
    }
  };

  // =========== Quick Filters =========
  const { user } = useAuthContext();
  const isMyTasks = user?.email && params?.members === user?.email;
  const handleMyTasksClick = () => {
    if (isMyTasks) {
      setParameter('members', null);
    } else {
      console.log('setting parameter', user?.email);
      setParameter('members', user?.email ?? null);
    }
  };

  const inputNode = (
    <SearchInputRoot>
      <Input ref={inputRef} value={searchValue} onChange={onInputChange} onKeyDown={handleInputKeyDown} />
    </SearchInputRoot>
  );

  return (
    <TaskListControlsRoot className="complex-search">
      <ComplexSearchRoot>
        <Dropdown
          trigger={['click']}
          open={filterDropdownVisible}
          overlay={
            <FilterDropdown
              filterInputs={filterInputs}
              handleAddFilter={handleAddFilter}
              handleRemoveFilter={handleRemoveFilter}
            />
          }
          onOpenChange={(open) => {
            if (!open) {
              handleCloseSearch();
            }
          }}
        >
          <ComplexSearchSelector onClick={onClick}>
            <ComplexSearchSelectorOverflow>
              <ComplexSearchFilterButton onClick={handleOpenSearch} />

              <FilterTags filterInputs={filterInputs} />

              {inputNode}
            </ComplexSearchSelectorOverflow>
            <ClearFiltersButton onClick={handleClearFilters} />
          </ComplexSearchSelector>
        </Dropdown>
      </ComplexSearchRoot>

      <QuickFiltersRoot>
        <NuButton
          icon={<UserOutlined />}
          size="large"
          onClick={handleMyTasksClick}
          type={isMyTasks ? 'primary' : 'default'}
        >
          My Tasks
        </NuButton>
      </QuickFiltersRoot>
    </TaskListControlsRoot>
  );
}

export const SEARCH_CLIENT_TASKS = gql`
  query SearchClientTasks(
    $clientId: String!
    $filters: [QueryDataTypeFilter]
    $next: String
    $sort: [SortTasksInput]
    $size: Int
  ) {
    searchClientTasks(clientId: $clientId, filters: $filters, next: $next, sort: $sort, size: $size) {
      items {
        id
        type
        shortId
        clientId
        label
        status
        startDate
        endDate
        createdAt
        summary
        tags
        priority
        members
      }
      total
      next
    }
  }
`;

type Filter = {
  key: string;
  value: string;
};

function parseFilters(parsed: { [key: string]: any }) {
  const filters: Filter[] = Object.keys(parsed).map((key) => ({
    key,
    value: parsed[key],
  }));

  return filters;
}

export function TaskListQuery(props: { searchParams: SearchParamsInterface }) {
  const {
    searchParams,
    searchParams: { parsed },
  } = props;
  const { clientId } = useClientContext();

  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState<any[]>([]);
  const [next, setNext] = useState<string | undefined>(undefined);

  // ========== Get filters from search params ==========
  // adding parsed because useEffect doesn't like an array change
  // const { filters, parsed } = useSearchParamFilters();
  const filters = parseFilters(parsed);

  const fetchData = async (args?: { newSearch?: boolean }) => {
    if (searchParams.initializing) {
      return;
    }

    setLoading(true);

    const isNewSearch = args?.newSearch;

    const variables = {
      clientId,
      filters,
      next: !isNewSearch ? next : undefined,
      sort: [
        {
          field: '@timestamp',
          order: 'desc',
        },
      ],
    };

    const { data } = await client.query({
      query: SEARCH_CLIENT_TASKS,
      variables,
      fetchPolicy: 'network-only',
    });

    const items = data?.searchClientTasks?.items;
    const nextIdx = data?.searchClientTasks?.next;
    const total = data?.searchClientTasks?.total;

    if (items) {
      const newItems = args?.newSearch ? [...items] : [...tasks, ...items];

      setTasks(newItems);

      if (nextIdx && total > newItems.length) {
        setNext(nextIdx);
      } else {
        setNext(undefined);
      }
    }

    setLoading(false);
  };

  // refetch data when vars change.
  useEffect(() => {
    fetchData({ newSearch: true });
  }, [clientId, parsed, searchParams.initializing]);

  // request to load more data
  const handleLoadMore = async () => {
    if (next) {
      await fetchData();
    }
  };

  if (tasks?.length) {
    return (
      <Space direction="vertical" size="large" style={{ display: 'flex' }}>
        {tasks.map((task) => (
          <ClientTaskCard key={task.id} {...task} />
        ))}
        {next && (
          <div style={{ display: 'flex' }}>
            <NuButton type="primary" style={{ margin: 'auto' }} onClick={() => handleLoadMore()} loading={loading}>
              Load More...
            </NuButton>
          </div>
        )}
      </Space>
    );
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin tip="Loading Tasks...">
          <div className="content" />
        </Spin>
      </SpinContainer>
    );
  }

  return <EmptyState>No matching Tasks were found.</EmptyState>;
}

export function TaskList() {
  const { clientId } = useClientContext();
  const navigate = useNavigate();
  const authContext = useAuthContext();
  const isCompanyEmail: boolean = authContext?.isCompanyEmail ?? false;

  // ============ Filters ===========
  const searchParamsApi = useSearchParams(
    filterInputs
      .filter((f) => f.defaultString)
      .map((f) => ({
        key: f.key,
        defaultValue: f.defaultString,
      })),
  );

  // Time user is on this page.
  useTimeMixpanelEvent('client_task_list_view', {
    clientId,
  });

  return (
    <Content>
      <Breadcrumb
        items={[
          {
            key: `${clientId}-case-management-tasks`,
            title: <Link to={`/${clientId}/case-management?activeTab=tasks`}>Case Management</Link>,
          },
          {
            key: 'tasks',
            title: 'Tasks',
          },
        ]}
        target="case-management"
      />

      <PageHeader
        title="Tasks"
        actions={
          isCompanyEmail && (
            <NuButton type="primary" onClick={() => navigate(`/${clientId}/case-management/tasks/create`)}>
              Create Task
            </NuButton>
          )
        }
      />

      <TaskListControls searchParams={searchParamsApi} />

      <TaskListQuery searchParams={searchParamsApi} />
    </Content>
  );
}
