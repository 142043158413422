import { EyeOutlined, SyncOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { Badge, Drawer } from 'antd';
import { useClientContext } from 'components/client-context-provider';
import { NuButton } from 'components/nuspire';
import InfiniteTable from 'components/nuspire/infinite-table';
import PageHeader from 'components/nuspire/nu-page-header';
import { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { client } from 'utils/graphql';
import { TaskLogs } from './task-logs';

const SCHEDULE_TASKS = gql`
  query ScheduleTasks($scheduleId: String!, $clientId: String!, $startKey: JSONObject, $pageSize: Int) {
    schedule(id: $scheduleId, clientId: $clientId) {
      id

      # Tasks that have not yet started
      scheduledTasks {
        id
        scheduledTime
      }

      scheduledTaskResults(startKey: $startKey, pageSize: $pageSize) {
        count
        lastEvaluatedKey
        items {
          id
          scheduledTime
          startedAt
          completedAt
          taskStatus
          ok
          summary
          error
        }
      }
    }
  }
`;
interface IScheduledTask {
  id: string;
  scheduledTime: string;
  startedAt?: string;
}
interface IScheduledTaskResult {
  id: string;
  ok: boolean;
  scheduledTime: string;
  startedAt?: string;
  completedAt: string;
  summary?: string;
  taskStatus?: 'success' | 'failed' | 'running' | 'rescheduled';
}
interface IPaginatedScheduledTaskResults {
  count: number;
  lastEvaluatedKey?: any;
  items: IScheduledTaskResult[];
}
interface ISchedule {
  id: string;
  cron: string;
  enabled: boolean;

  scheduledTasks?: IScheduledTask[];

  scheduledTaskResults?: IPaginatedScheduledTaskResults;
}

interface IScheduleQuery {
  schedule?: ISchedule;
}

export function renderDateTime(timeString: string) {
  const date = new Date(timeString);

  return `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`;
}

const ScheduleTasksRoot = styled.div`
  position: relative;
  height: 750px;
`;

export function ScheduleTasks(props: { scheduleId: string }) {
  const theme = useTheme();
  const { clientId } = useClientContext();
  const { scheduleId } = props;

  /**
   * State
   */
  const [dataSource, setDataSource] = useState<IScheduledTaskResult[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  // start key to load more items.
  const [startKey, setStartKey] = useState<{ [key: string]: string } | undefined>();

  const fetchData = async (args?: { newSearch?: boolean }) => {
    setLoading(true);

    const variables = {
      scheduleId,
      clientId,
      startKey,
      pageSize: 100,
    };

    // fetch data
    const { data } = await client.query<IScheduleQuery>({
      query: SCHEDULE_TASKS,
      variables,
      fetchPolicy: 'network-only',
    });
    const items = data?.schedule?.scheduledTaskResults?.items;

    if (items) {
      if (args?.newSearch) {
        setDataSource(items);
      } else {
        setDataSource([...dataSource, ...items]);
      }
    }

    const lastEvaluatedKey = data?.schedule?.scheduledTaskResults?.lastEvaluatedKey;
    setStartKey(lastEvaluatedKey);

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [scheduleId, clientId]);

  const handleScroll = async () => {
    if (startKey) {
      await fetchData();
    }
  };

  const [logsId, setLogsId] = useState<undefined | string>();
  const handleOpenLogs = (taskId: string) => setLogsId(taskId);
  const handleCloseLogs = () => setLogsId(undefined);

  const handleSync = () => {
    setStartKey(undefined);
    fetchData({ newSearch: true });
  };

  return (
    <ScheduleTasksRoot>
      <PageHeader
        level={3}
        title="Task Results"
        actions={
          <NuButton onClick={handleSync}>
            <SyncOutlined />
          </NuButton>
        }
      />
      <InfiniteTable
        loading={loading}
        dataSource={dataSource}
        lastId={dataSource[dataSource.length - 1]?.id}
        debug
        columns={[
          {
            title: 'Scheduled Run Time',
            dataIndex: 'scheduledTime',
            render: renderDateTime,
          },
          {
            title: 'Time Completed',
            dataIndex: 'completedAt',
            render: renderDateTime,
          },
          {
            title: 'Status',
            dataIndex: 'ok',
            render: (ok) => {
              return <Badge status={ok ? 'success' : 'error'} text={ok ? 'Success' : 'Error'} />;
            },
          },
          {
            title: 'Summary',
            dataIndex: 'summary',
          },
          {
            dataIndex: 'id',
            render: (id) => {
              return (
                <NuButton
                  color={theme.color.primary}
                  type="text"
                  icon={<EyeOutlined />}
                  onClick={() => handleOpenLogs(id)}
                >
                  Logs
                </NuButton>
              );
            },
          },
        ]}
        onFetch={handleScroll}
        scroll={{
          x: true as true,
          y: 750,
        }}
        rowKey="id"
      />
      <Drawer
        title="Logs"
        placement="right"
        // closable={false}
        onClose={handleCloseLogs}
        open={Boolean(logsId)}
        getContainer={false}
        rootStyle={{ position: 'absolute' }}
        width={1000}
      >
        {logsId && Boolean(logsId) && <TaskLogs taskId={logsId} />}
      </Drawer>
    </ScheduleTasksRoot>
  );
}
