import { ApolloError } from '@apollo/client';
import { Industry } from 'components/industries';
import { NuButtonProps } from 'components/nuspire';
import {
  CaseManagementFilterItem,
  ClientIdentifierType as ClientIdentifierTypeGraph,
  ServiceNowClientServiceOffering,
} from 'types/graph-codegen/graph-types';
import { ClientTree } from '../components/organization/client-tree-view';

export type Maybe<T> = T | null;

/**
 * Used to pull the type out from the items of a typed array.
 */
export type Unpacked<T> = T extends (infer U)[] ? U : T;

interface ISoftDeleted {
  deletedAt?: string;
}

export type ClientIdentifierType = `${ClientIdentifierTypeGraph}`;

export type ClientIdentifier = {
  type: ClientIdentifierType;
  value: string;
};

export type Application = {
  _links: any;
  created: string;
  id: string;
  label: string;
  lastUpdated: string;
  settings: any;
  signOnMode: string;
  status: string;
};

export enum ClientType {
  direct = 'direct',
  program = 'program',
  partner = 'partner',
}

export const clientTypeLabels = {
  [ClientType.direct]: 'Direct',
  [ClientType.program]: 'Program',
  [ClientType.partner]: 'Partner',
};

export type PartnerDetails = {
  additionalInfo?: string;
  businessCountry: string;
  businessEmail: string;
  businessPhone: string;
  businessPhoneExt?: string;
  businessSalesRepresentative?: string;
  businessType: string;
  businessWebsite?: string;
  isApproved?: boolean;
  partnerType: string;
};

export type ClientSAMLIdP = {
  childrenInheritSSO?: boolean;
  clientId?: string;
  createdAt?: string;
  id?: string;
  idpId?: string; // Okta IdP ID
  inheritedFromClientId?: string; // Parents/grandparents/etc can pass their IdP down the tree.
};

export interface IClient extends ISoftDeleted {
  apiKeyId?: string;
  createdAt?: string;
  clientIdentifiers?: ClientIdentifier[];
  clientTree?: Maybe<ClientTree>;
  customTags?: string;
  description?: string;
  effectivePermissions?: IPermissions;
  id: string;
  industry?: Industry;
  isMultiTenancyEnabled?: boolean;
  isSearchMatch?: boolean;
  name: string;
  oktaAppIds?: string[];
  oktaGroupId?: string;
  owner?: IUser;
  ownerId?: string;
  parent?: Pick<IClient, 'name'>;
  parentId?: string | null;
  partnerDetails?: PartnerDetails | null;
  path?: string;
  samlIdp?: ClientSAMLIdP;
  serviceOfferings?: ServiceNowClientServiceOffering[];
  settings?: any;
  stats?: {
    userCount?: number | string;
  };
  systemTags?: string;
  type?: ClientType | null;
  updatedAt?: string;
  logoUrl?: string;
}

export type CaseManagementFilters = {
  cases?: CaseManagementFilterItem;
  incidents?: CaseManagementFilterItem;
};

export enum UserAuthenticationType {
  EMAIL_AND_PASSWORD = 'OKTA', // email/password
  FEDERATED = 'FEDERATION', // ex: SAML
}

export interface IUser extends ISoftDeleted {
  id: string;
  createdAt?: string;
  clientId: string;
  client?: IClient;
  email: string;
  firstName?: string;
  lastName?: string;
  login?: string;
  mobilePhoneNumber?: string;
  mobilePhoneNumberCountry?: string;
  persona?: string;
  phoneNumber?: string;
  phoneNumberCountry?: string;
  phoneNumberExt?: string;
  customTags?: string;
  systemTags?: string;
  title?: string;
  isSuperUser?: boolean;
  settings?: any;
  oktaUserId?: string;
  serviceNowUserId?: string;
  status?: string;
  favoriteDashboard?: {
    id?: string;
    shortId?: string;
  };
  caseManagementFilters?: CaseManagementFilters;
  authenticationType?: UserAuthenticationType;
  managedClientIds?: string[];
  managedClients?: IClient[];
  enabledFeatures?: { [key: string]: boolean };
  acceptedLicenseAgreement?: boolean;
  lastViewedReleaseNoteDate?: string;
}

export type RefreshAuthContextArgs = {
  redirectToFn?: (newClientId: string) => string;
};

export interface IAuthContext {
  error?: ApolloError;
  isMyNuspireDev: boolean;
  isCompanyEmail: boolean;
  loading?: boolean;
  logout: () => void;
  refresh: (args?: RefreshAuthContextArgs) => void;
  user?: IUser;
}

export interface IAuthorizationRequest {
  clientId?: string; // optional for viewing other client's data (superusers, soc analysts, etc)
  requireSuperUser?: boolean;
  requireAdmin?: boolean;
  requireSocAnalyst?: boolean;
  requiredPermissions?: IPermissions; // the next iteration of this
  effectivePermissions?: IPermissions;
}

export interface IUserAuthorizationRequest extends IAuthorizationRequest {
  user?: IUser;
}

export interface IAuthorizationResponse {
  authorized: boolean;
  failedPermissions?: IPermissions;
  message?: string;
}

export enum Access {
  none = 0, // 0000 in binary, meaning no permissions.
  read = 1 << 0, // 0001 in binary, granting read permissions.
  write = (1 << 1) | read, // 0011 in binary, granting write permissions, including read permissions.
  execute = (1 << 2) | write, // 0111 in binary, granting execute permissions, including write and read permissions.
  all = read | write | execute, // 0111 in binary, explicitly granting all permissions.
}

export const humanReadableAccessValues = ['all', 'execute', 'none', 'read', 'write'] as const;
export type HumanReadableAccess = (typeof humanReadableAccessValues)[number];

export const permissionTypes = [
  'actions',
  'allAuditLogs',
  'allClients',
  'allDashboards',
  'allNeces',
  'allSchedules',
  'allUsers',
  'apiKeys',
  'auditLogs',
  'automations',
  'blockList',
  'capabilities',
  'caseManagement',
  'chat',
  'clients',
  'compliance',
  'connections',
  'controls',
  'controlScoreChanges',
  'controlScores',
  'dashboards',
  'dataSources',
  'domains',
  'domainScoreChanges',
  'domainScores',
  'externalNewsArticles',
  'externalNewsSources',
  'externalNewsTags',
  'events',
  'featureFlags',
  'industries',
  'industryControls',
  'hiddenPermissions',
  'neces',
  'notifications',
  'recommendations',
  'releaseNotes',
  'roles',
  'schedules',
  'shareDashboards',
  'sso',
  'technologySourceParsers',
  'userGroups',
  'userImpersonation',
  'users',
] as const;
export type PermissionTypes = (typeof permissionTypes)[number];
export const humanReadablePermissionTypes: { [key in PermissionTypes]: string } = {
  actions: 'Actions',
  allAuditLogs: 'All Audit Logs',
  allClients: 'All Clients',
  allDashboards: 'All Dashboards',
  allNeces: 'All NECES',
  allSchedules: 'All Schedules',
  allUsers: 'All Users',
  apiKeys: 'API Keys',
  auditLogs: 'Audit Logs',
  automations: 'Automations',
  blockList: 'Block List',
  capabilities: 'Capabilities',
  caseManagement: 'Case Management',
  chat: 'Nutron Chat',
  clients: 'Clients',
  compliance: 'Compliance',
  connections: 'Connections',
  controls: 'Controls',
  controlScoreChanges: 'Control Score Changes',
  controlScores: 'Control Scores',
  dashboards: 'Dashboards',
  dataSources: 'Data Sources',
  domains: 'Domains',
  domainScoreChanges: 'Domain Score Changes',
  domainScores: 'Domain Scores',
  events: 'Events',
  externalNewsArticles: 'External News Articles',
  externalNewsSources: 'External News Sources',
  externalNewsTags: 'External News Tags',
  featureFlags: 'Feature Flags',
  industries: 'Industries',
  industryControls: 'Industry Controls',
  hiddenPermissions: 'Hidden Permissions',
  neces: 'NECES',
  notifications: 'Notifications',
  recommendations: 'Recommendations',
  releaseNotes: 'Release Notes',
  roles: 'Roles',
  schedules: 'Schedules',
  shareDashboards: 'Share Dashboards',
  sso: 'SSO',
  technologySourceParsers: 'Technology Source Parsers',
  userGroups: 'User Groups',
  userImpersonation: 'User Impersonation',
  users: 'Users',
};

export type IPermissions = {
  [key in PermissionTypes]?: Access;
};

export enum DetailReportType {
  csv = 'csv',
  json = 'json',
  pdf = 'pdf',
}

export type DetailReportArgs = {
  reportType: DetailReportType;
};

export type DetailReportData = {
  base64Content: string;
  filename: string;
  contentType: string;
};

export type UserActionButtonProps = NuButtonProps & {
  disabled?: boolean;
  onSuccess?: (data?: any) => void;
  user: IUser;
};

export interface IAccountContact {
  id: string;
  email?: string;
  name: string;
}
