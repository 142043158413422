import { gql } from '@apollo/client';

export const WORKFLOW_LIST_ITEM_FRAGMENT = gql`
  fragment WorkflowListItemFields on DataSource {
    id
    name
    dataSourceSlug
    enabled
    status
    statusUpdatedAt
    schedule {
      id

      scheduledTasks {
        id
        scheduledTime
      }
    }

    connection {
      id
      name
      connectorSlug
      systemConnector {
        id
        name
      }
    }

    dataSourceDefinition {
      id
      name
      description
      canEnableDisable

      connector {
        id
        name
        slug
      }
    }
  }
`;
